import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Button, Popconfirm, Table } from "antd";
import styles from "./styles/vieweachuser.module.css";
import { useSelector } from "react-redux";
import { getrequest, putrequest } from "../../Middleware/managerequest";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";

import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";

export const ViewEachUser = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dataa = useSelector((state) => state.Data);
  const { user } = dataa;
  const { state } = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menuKey, setMenuKey] = useState("");
  const [openDwa, setOpenDwa] = useState(false);

  const categoryList = [];

  const { maintopics, Diff, groupname, username, id, userid, ind } = state;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "black" : provided.color,
    }),
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (e.key === "dashboard") {
      navigate("/admin");
    } else if (e.key === "createtest") {
      navigate("/createmock");
    } else if (e.key === "Question Bank") {
      navigate("/questions");
    } else {
      navigate("/managegroups");
    }
    setOpenDwa(false);
  };
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <HomeOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createtest",
      <ContainerOutlined />,
      null,
      null
    ),
    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "QuestionBank",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
  ];
  const fetchdata = useCallback(() => {
    setLoading(true);
    getrequest(`/particulardiff/${id}/${userid}/${ind}/${Diff}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [Diff, id, ind, userid]);

  useEffect(() => {
    fetchdata();
  }, [Diff, id, ind, userid, fetchdata]);

  const Total_Time_Spend = (val) => {
    if (val.answer.length === 0) {
      return 0;
    } else {
      const totalMinutes = val.answer.reduce((total, time) => {
        const startDate = new Date(time.starttime);
        const endDate = new Date(time.endtime);

        // Calculate the difference in milliseconds
        const difference = Math.abs(endDate - startDate);
        // Convert milliseconds to minutes
        const minutes = Math.floor(difference / (1000 * 60));

        // Accumulate total minutes
        return total + minutes;
      }, 0);
      return totalMinutes;
    }
  };

  const Status = (val) => {
    if (val.answer.length === 0) {
      return "Not Started";
    } else {
      if (val.answer[val.answer.length - 1].issubmitted) {
        return "Submitted";
      } else {
        return "In Progress";
      }
    }
  };

  const converttonormaldate = (val) => {
    if (val.answer.length > 0) {
      let Dateval = val.answer.slice(-1);

      const date = new Date(Dateval[0].endtime);

      // Extract the day, month, and year components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    } else {
      return "- - -";
    }
  };

  const ColumnsData =
    data.length > 0 &&
    data.map((item) => {
      return {
        question: item.qstn.question,
        timespend: Total_Time_Spend(item),
        Status: Status(item),
        started: item.answer.length > 0,
        key: item.qid,
        givendate: converttonormaldate(item),
      };
    });

  const Columns = [
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      responsive: ["md"],
      render: (text, record) => {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: record.title ?? record.question,
            }}
            style={{ height: "auto", maxHeight: "150px", overflow: "auto" }}
          />
        );
      },
    },
    {
      title: "Date",
      dataIndex: "givendate",
      key: "givendate",
      align: "center",
      width: 150,
      responsive: ["md"],
    },
    {
      title: "Time Spend (Min)",
      dataIndex: "timespend",
      key: "timespend",
      align: "center",
      width: 200,
      responsive: ["md"],
      sorter: (a, b) => a.timespend - b.timespend,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "status",
      align: "center",
      width: 150,
      responsive: ["md"],
      filters: [
        {
          text: "In Progress",
          value: "In Progress",
        },
        {
          text: "Submitted",
          value: "Submitted",
        },

        {
          text: "Not Started",
          value: "Not Started",
        },
      ],
      onFilter: (value, record) => record.Status === value,
    },
    // {
    //   title: "View",
    //   dataIndex: "started",
    //   align: "center",
    //   responsive:["md"],
    //   width: 160,
    //   render: (value, record) => {
    //     return record.started ? <Button>View </Button> : <LockOutlined />;
    //   },
    // },
  ];

  const OnSelectRow = (val) => {
    setSelectedRowKeys(val);
  };

  const ResetSelected = () => {
    putrequest(`/resetquestions/${id}/${userid}`, {
      topicindex: ind,
      Diff: Diff,
      Qid: selectedRowKeys,
    })
      .then((res) => {
        fetchdata();
        setSelectedRowKeys([]);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className={styles.main}>
      <div className={styles.dashboard}>
        <DashboardSlider />
      </div>
      <div style={{ width: "82%" }}>
        <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
        <div
          style={{
            marginTop: "75px",
          }}
        >
          <div className="d-flex justify-content-between">
            <Breadcrumb
              items={[
                {
                  title: (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate("/managegroups")}
                    >
                      Batch
                    </span>
                  ),
                },
                {
                  title: (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/group/${id}`, {
                          state: { groupname: groupname, from: true },
                        })
                      }
                    >
                      {groupname}
                    </span>
                  ),
                },
                {
                  title: (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/group/${id}`, {
                          state: { groupname: groupname, from: true },
                        })
                      }
                    >
                      {username}
                    </span>
                  ),
                },
                {
                  title: (
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/group/${id}`, {
                          state: { groupname: groupname, from: true },
                        })
                      }
                    >
                      {maintopics}
                    </span>
                  ),
                },
                {
                  title: Diff,
                },
              ]}
            />

            {selectedRowKeys.length > 0 && (
              <Popconfirm
                onConfirm={() => ResetSelected()}
                okText="Yes"
                title={"Are you want to reset the Questions?"}
                onCancel={() => setSelectedRowKeys([])}
              >
                <Button type="primary" className="mx-3">
                  Reset Selected
                </Button>
              </Popconfirm>
            )}
          </div>
          <div className="mt-3" style={{ width: "98%" }}>
            <Table
              columns={Columns}
              loading={loading}
              dataSource={ColumnsData}
              bordered
              style={{ width: "100%" }}
              size="small"
              scroll={{
                x: "calc(400px + 30%)",
                y: "65vh",
              }}
              rowSelection={{
                selectedRowKeys,
                onChange: OnSelectRow,
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: record.started
                    ? () =>
                        navigate("/codereview", {
                          state: {
                            questiondata: data[rowIndex],

                            groupname: groupname,
                            username: username,
                            maintopics: maintopics,
                            Diff: Diff,
                            id: id,
                            userid: userid,
                            inde: ind,
                          },
                        })
                    : null, // click row
                };
              }}
            />
          </div>
        </div>
      </div>
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
        customStyles={customStyles}
      />
    </div>
  );
};
