import { useCallback, useEffect, useState } from "react";
import DashboardSlider from "../Navigator_Footer/DashboardSlider";
import styles from "./Categorypage.module.css";
import {
  Card,
  message,
  Modal,
  Radio,
  Skeleton,
  Result,
  Tag,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getrequest, postrequest } from "../Middleware/managerequest";
import { IoInformationCircleOutline, IoTimerOutline } from "react-icons/io5";
import {
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { setCategory } from "../../store/Reducer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileSlider from "../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../Navigator_Footer/LatestNavbar";
const { Meta } = Card;
const Categorypage = () => {
  const [allTest, setAllTest] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [totalpages, setTotalpages] = useState();
  const data = useSelector((state) => state.Data);
  const [showadd, setShowadd] = useState(false);
  const [pageno, setPageno] = useState(1);
  const { user, clientdetails } = data;

  const [isdata, setIsdata] = useState(false);
  const [openDwa, setOpenDwa] = useState(false);
  const [searchParams] = useSearchParams();
  const [Users, setUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [sendingrequest, setSendingrequest] = useState(false);
  const [practiceopen, setpracticeopen] = useState("Tests");
  const [item, setItem] = useState("");
  const [show, setShow] = useState(false);
  const [Startdate, setStartdate] = useState("");
  const [Selecteduser, setSelecteduser] = useState([]);
  const [endDate, setEnddate] = useState("");
  const category = searchParams.get("category");
  //  Get Particular Category
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notificationmessage = useCallback(
    (val, err) => {
      messageApi.open({
        type: err,
        content: val,
      });
    },
    [messageApi]
  );
  useEffect(() => {
    if (!user) {
      return;
    }
    setIsdata(true);
    getrequest(`/getchallcategory/${user?.subdomain}/${category}`)
      .then((res) => {
        setAllTest(res.data?.alldata.reverse());
        dispatch(setCategory(res.data?.allcategory?.Category));
        let length = res.data?.alldata.filter(
          (ele) => !ele?.Settings?.practice_mode ?? false
        ).length;
        setTotalpages(length);
        setIsdata(false);
      })
      .catch((err) => {
        setIsdata(false);
        notificationmessage("server error code-174");
      });
  }, [category, user]);
  // Show skills
  const Skils = ({ val }) => {
    let StoredSkills = [];
    val?.MockData?.map((item) => {
      if (!StoredSkills.includes(item.MainTags)) {
        StoredSkills.push(item.MainTags);
      }
      return true;
    });
    return (
      <>
        {StoredSkills?.map((elee, ind) =>
          // ele?.MainTags.map((elee) =>console.log(elee))
          {
            return (
              <label key={ind}>
                {elee}
                {ind !== StoredSkills?.length - 1 && ","}
              </label>
            );
          }
        )}
      </>
    );
  }; // Input date string
  const convertDateIntoNormal2 = () => {
    // Create a Date object from the input string
    const date = new Date();

    // Extract the day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDate;
  };
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setSelecteduser([]);
  };
  // Check the subscription
  const Subscription = () => {
    toast.warning("Subscription Expired", {
      position: "top-right",
      autoClose: 1500,
      closeOnClick: true,
    });
  };
  // Warning when subscription expire
  const Warning = () => {
    toast.warning(`You can add upto ${clientdetails?.mockcount} users`, {
      position: "top-right",
      autoClose: 1500,
      closeOnClick: true,
    });
  };

  // Fetch Users All Available Users while assigning
  const Allusers = () => {
    setIsdata(true);
    getrequest(`/allusers/${user?.subdomain}/true`)
      .then((res) => {
        setIsdata(false);
        if (res.data.length > 0) {
          setUsers(
            res.data
              .filter((item) => item.name === "group")
              .map((item) => {
                return {
                  value: item.Email,
                  label: item.Name,
                  name: item.name,
                };
              })
          );
        }
      })
      .catch((err) => {
        setIsdata(false);
        notificationmessage("Server error code-836", "error");
      });
  };
  // Edit Test
  const editMock = (item, testType) => {
    navigate("/createmock", {
      state: {
        details: item,
        testtype: testType,
      },
    });
  };
  // Handle Users while Inviting users
  const handleuser = (e) => {
    let find = Users.filter((val) => {
      return e.includes(val.value);
    });
    e.forEach((val) => {
      if (!Users.some((user) => user.value === val)) {
        let obj = {
          label: val.trim(),
          value: val.trim(),
          name: "users",
        };
        find.push(obj);
      }
    });
    setSelecteduser(find);
  };
  // MockDetails
  function MockDetails(props) {
    return (
      <Modal
        maskClosable={false}
        title={item.MockTitle || item.Test_Name}
        onOk={props.onHide}
        open={props.show}
        centered
        width={780}
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
        closable={false}
      >
        <h4>Mock Details</h4>
        <div>
          {item !== "" &&
            item.MockData.map((it, ind) => {
              return (
                <div key={ind}>
                  {ind === 0 && (
                    <div
                      className="d-flex justify-content-around"
                      style={{ fontWeight: "bold", textAlign: "center" }}
                    >
                      <div style={{ width: "150px" }}>Main Topics</div>
                      <div style={{ width: "150px" }}>Topics</div>
                      <div style={{ width: "150px" }}>Types</div>
                      <div style={{ width: "150px" }}>Easy</div>
                      <div style={{ width: "150px" }}>Medium</div>
                      <div style={{ width: "150px" }}>Hard</div>
                    </div>
                  )}

                  <div id={styles.mco_table}>
                    <div className={styles.mock_table_data1}>
                      <span id={styles.mock_table_tag}>
                        <label>{it.MainTags}</label>
                      </span>

                      <span id={styles.mock_table_tag}>
                        {item?.Extra?.TopicsWiseTags &&
                        item?.Extra?.TopicsWiseTags[it.MainTags] ? (
                          item?.Extra?.TopicsWiseTags[it.MainTags].map(
                            (ite, ind) => (
                              <label key={ind}>{`${ite}${
                                ind !==
                                item?.Extra?.TopicsWiseTags[it.MainTags]
                                  .length -
                                  1
                                  ? ","
                                  : ""
                              }`}</label>
                            )
                          )
                        ) : Array.isArray(it.Tags) ? (
                          it.Tags?.map((val, ind) => (
                            <label key={ind}>{`${val}${
                              ind !== it.Tags.length - 1 ? "," : ""
                            }`}</label>
                          ))
                        ) : (
                          <label>{it.Tags}</label>
                        )}

                        {/* */}
                      </span>
                      <span id={styles.mock_table_types}>
                        {(it?.MCQ ?? it?.Mcq) > 0 && <label>MCQ</label>}
                        {it.Coding > 0 && <label>Coding</label>}
                        {(it?.Theory ?? it?.Question) > 0 && (
                          <label>Theoretical</label>
                        )}
                      </span>
                      {["Easy", "Medium", "Hard"].map((val, ind) => {
                        return (
                          <span className={styles.mock_table_diff} key={ind}>
                            {(it?.MCQ || it?.Mcq) > 0 && (
                              <span>
                                <label style={{ width: "30px" }}>
                                  {it?.Global?.MCQ?.[val] !== 0 &&
                                  it?.Global?.Mcq?.[val] !== 0
                                    ? `G/${
                                        it?.Global?.MCQ?.[val] ??
                                        it?.Global?.Mcq?.[val]
                                      }`
                                    : "-"}
                                </label>
                                <label style={{ width: "30px" }}>
                                  {it?.Personal?.MCQ?.[val] !== 0 &&
                                  it?.Personal?.Mcq?.[val] !== 0
                                    ? `P/${
                                        it?.Personal?.Mcq?.[val] ??
                                        it?.Personal?.MCQ?.[val]
                                      }`
                                    : "-"}
                                </label>
                              </span>
                            )}

                            {it.Coding > 0 && (
                              <span style={{ position: "relative" }}>
                                <label style={{ width: "30px" }}>
                                  {it["Global"]["Coding"][val] !== 0
                                    ? `G/${it["Global"]["Coding"][val]}`
                                    : "-"}
                                </label>
                                <label style={{ width: "30px" }}>
                                  {it["Personal"]["Coding"][val] !== 0
                                    ? `P/${it["Personal"]["Coding"][val]}`
                                    : "-"}
                                </label>
                              </span>
                            )}
                            {(it?.Theory || it?.Question) > 0 && (
                              <span>
                                <label style={{ width: "30px" }}>
                                  {it?.Global?.Theory?.[val] !== 0 &&
                                  it?.Global?.Question?.[val] !== 0
                                    ? `G/${
                                        it?.Global?.Theory?.[val] ??
                                        it?.Global?.Question?.[val]
                                      }`
                                    : "-"}
                                </label>
                                <label style={{ width: "30px" }}>
                                  {it?.Personal?.Theory?.[val] !== 0 &&
                                  it?.Personal?.Question?.[val] !== 0
                                    ? `P/${
                                        it?.Personal?.Theory?.[val] ??
                                        it?.Personal?.Question?.[val]
                                      }`
                                    : "-"}
                                </label>
                              </span>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </Modal>
    );
  }
  // Assign Test
  const assignTest = (val) => {
    if (Startdate !== "" && endDate !== "") {
      let obj = {
        startdate: Startdate,
        enddate: endDate,
        Test_Name: item.Test_Name,
      };
      setIsdata(true);
      postrequest(`/assign/${user?.subdomain}/${val}`, obj)
        .then(() => {
          toast.success("Assigned Successfully", {
            position: "top-center",
            autoClose: 1000,
          });
          setIsdata(false);
          notificationmessage("Assigned Successfully", "success");
          setShowadd(false);
          setSelecteduser([]);
          setStartdate("");
          setEnddate("");
        })
        .catch(() => {
          setIsdata(false);
          notificationmessage("Error while assigning Test", "error");
        });
    } else if (Startdate === "" || endDate === "") {
      setIsdata(false);
      notificationmessage("Please select the date", "warning");
    }
  };
  // Add users or Invite to Test
  const Addusers = (item) => {
    const Mockid = practiceopen === "Practice" ? item?.Testid : item?.Mockid;
    if (Selecteduser.length > clientdetails?.mockcount) {
      Warning();
    } else {
      let obj = {
        Selecteduser,
        MockData: item,
        subdomain: clientdetails?.isusers ? user?.subdomain : "main",
      };
      setIsdata(true);
      postrequest(
        `/deleteuser/${Mockid}/${user?.subdomain}/?practice=${
          practiceopen === "Practice"
        }`,
        obj
      )
        .then((res) => {
          handleClose();
          setIsdata(false);
          setSendingrequest(false);
          setSelecteduser([]);
          if (res.status === 200) {
            if (practiceopen === "Practice") {
              notificationmessage("Assigned successfully.", "success");
            } else {
              notificationmessage("User added successfully.", "success");
              navigate("/admin");
            }
          } else if (res.status === 201) {
            notificationmessage("Subscription Expired", "warning");
          }
        })
        .catch((err) => {
          console.log(err);
          setIsdata(false);
          notificationmessage(
            "Server error while adding users. code-858",
            "error"
          );
        });
    }
  };
  return (
    <>
      <div className={styles.adminMock}>
        {/* Left Side Menu */}
        <div className={styles.leftMockTest}>
          <DashboardSlider />
        </div>
        <div className={styles.rightMockTest}>
          <LatestNavbar setOpenDwa={setOpenDwa} openDwa={openDwa} />

          <div className={styles.create}>
            <div className="d-flex">
              {
                <div className="d-flex">
                  <label
                    className={styles.addtosection}
                    onClick={() => navigate(`/createmock/?add=${category}`)}
                    title="Add to this Section"
                  >
                    Create New
                  </label>
                  <div>
                    <span className={styles.filter_label}>Filter : </span>
                    <Radio.Group
                      defaultValue="Tests"
                      buttonStyle="solid"
                      onChange={(val) => {
                        setpracticeopen(val.target.value);
                        setPageno(1);
                        if (val.target.value === "Practice") {
                          let length = allTest.filter(
                            (ele) => ele?.Settings?.practice_mode ?? false
                          ).length;
                          setTotalpages(length);
                        } else {
                          let length = allTest.filter(
                            (ele) => !ele?.Settings?.practice_mode ?? false
                          ).length;
                          setTotalpages(length);
                        }
                      }}
                      size="small"
                    >
                      <Radio.Button value="Tests">Tests</Radio.Button>
                      <Radio.Button value="Practice">Practice</Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              }
            </div>
          </div>
          {contextHolder}
          {/* All Test */}
          <div className={styles.mockItem}>
            {isdata ? (
              <>
                {new Array(8).fill(0).map((_, ind) => {
                  return (
                    <div key={ind} className={styles.testCard}>
                      <div className="d-flex justify-content-between mt-2 mx-1">
                        <Skeleton.Input size="small" active={true} />
                        <Skeleton.Button active={true} size="small" />
                      </div>
                      <div className="mt-4 mx-1 w-100">
                        <Skeleton.Input
                          style={{ width: "200px" }}
                          active={true}
                        />
                      </div>
                      <div className="mt-4 mx-1 w-100">
                        <Skeleton.Input
                          style={{ width: "150px" }}
                          size="small"
                          active={true}
                        />
                      </div>
                      <div className="mx-1 w-100 d-flex justify-content-between align-items-end">
                        <Skeleton.Input
                          style={{ width: "150px" }}
                          size="small"
                          active={true}
                        />

                        <Skeleton.Button
                          style={{ width: "100px" }}
                          className="mx-2"
                          size="large"
                          active={true}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              allTest
                .filter((ele) =>
                  practiceopen === "Practice"
                    ? ele?.Settings?.practice_mode ?? false
                    : !ele?.Settings?.practice_mode ?? false
                )
                .map((ele, index) => {
                  return (
                    <Card
                      key={index}
                      hoverable
                      className={styles.testCard}
                      styles={{ body: { padding: "0 10px 10px 10px" } }}
                      actions={[
                        <InfoCircleOutlined
                          key="ellipsis"
                          title="Details"
                          onClick={(e) => {
                            e.stopPropagation();

                            setEdit(true);
                            setItem(ele);
                          }}
                        />,
                        <EditOutlined
                          key="edit"
                          title="Edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            editMock(ele, "CATupdate");
                            setItem(ele);
                          }}
                        />,

                        <PlusCircleOutlined
                          key="Invite"
                          title="Assign"
                          onClick={(e) => {
                            e.stopPropagation();

                            if (!clientdetails?.issubscribed) {
                              Subscription();
                            } else {
                              if (practiceopen !== "Practice") {
                                setShowadd(true);
                              } else {
                                Allusers();
                                handleShow();
                              }
                              setItem(ele);
                            }
                          }}
                        />,
                      ]}
                      cover={[
                        <div
                          className="d-flex justify-content-end align-items-center"
                          style={{ padding: "10px 10px" }}
                        >
                          {" "}
                          <IoTimerOutline style={{ fontSize: "15px" }} />
                          <label>{ele.Duration} Min</label>
                        </div>,
                      ]}
                    >
                      <Meta title={ele?.Test_Name} key={index} />
                      <hr style={{ width: "100%" }} />
                      <div>
                        <label style={{ width: "80px", marginLeft: "5px" }}>
                          Questions
                        </label>{" "}
                        <label style={{ color: "#87888a" }}>{ele.NoofQ}</label>
                      </div>
                      <div className="d-flex">
                        <label style={{ width: "80px", marginLeft: "5px" }}>
                          Skills
                        </label>
                        <label style={{ color: "#87888a" }}>
                          <Skils val={ele} />
                        </label>
                      </div>
                    </Card>
                  );
                })
            )}

            {!isdata && allTest.length === 0 && (
              <div className={styles.errorPage}>
                <Result
                  style={{ margin: "auto" }}
                  extraMargin="auto"
                  status="403"
                  subTitle={`No result found`}
                  subtitleFontSize={32}
                />
              </div>
            )}
            {/* Assign Test */}
            <Modal
              open={showadd}
              onCancel={() => setShowadd(false)}
              title={item.Test_Name}
              centered
              width={739}
              okText="Submit"
              onOk={() => assignTest(item.Testid)}
              maskClosable={false}
            >
              <label className={styles.headings}>Edit Name :</label>
              <br />
              <input
                className={styles.titlechangeinput}
                onChange={(e) =>
                  setItem({ ...item, Test_Name: e.target.value })
                }
                value={item.Test_Name}
              />

              <br />
              <div className="d-flex">
                <div>
                  <label className={styles.headings}>Start Date :</label>
                  <br />
                  <input
                    type="datetime-local"
                    className={styles.datechangeinput}
                    onChange={(e) => setStartdate(e.target.value)}
                    min={convertDateIntoNormal2()}
                  />
                </div>
                <div className="mx-5">
                  <label className={styles.headings}>End Date :</label>
                  <br />
                  <input
                    type="datetime-local"
                    className={styles.datechangeinput}
                    onChange={(e) => setEnddate(e.target.value)}
                  />
                </div>
              </div>
            </Modal>
            <Modal
              open={show}
              onCancel={() => handleClose()}
              onOk={() => Addusers(item)}
              maskClosable={false}
              title={practiceopen !== "Practice" ? "Add User" : "Select Batch"}
              okText="Submit"
              footer={(_, { OkBtn }) => {
                return !sendingrequest ? (
                  practiceopen !== "Practice" ? (
                    <div className="d-flex justify-content-between">
                      <OkBtn />
                    </div>
                  ) : (
                    <OkBtn />
                  )
                ) : (
                  <span>
                    <LoadingOutlined /> Adding Users... wait
                  </span>
                );
              }}
            >
              <Select
                mode="tags"
                style={{
                  width: "100%",
                  zIndex: "999",
                }}
                onChange={handleuser}
                tokenSeparators={[","]}
                options={Users}
                placeholder={
                  practiceopen !== "Practice"
                    ? "Enter the email with ',' separated"
                    : "Select Batch"
                }
                value={Selecteduser}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <label>
                Press{" "}
                <Tag
                  style={{
                    backgroundColor: "rgba(150,150,150,0.06)",
                    fontWeight: "600",
                  }}
                >
                  ,
                </Tag>
                or{" "}
                <Tag
                  style={{
                    backgroundColor: "rgba(150,150,150,0.06)",
                    fontWeight: "600",
                  }}
                >
                  Tab
                </Tag>
                or{" "}
                <Tag
                  style={{
                    backgroundColor: "rgba(150,150,150,0.06)",
                    fontWeight: "600",
                  }}
                >
                  Enter
                </Tag>{" "}
                to separate.
              </label>
            </Modal>
            <MockDetails show={edit} onHide={() => setEdit(false)} />
          </div>
        </div>
        <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} user={user} />
      </div>
    </>
  );
};
export default Categorypage;
