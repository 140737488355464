import { useState } from "react";
import styles from "./NewHome.module.css";

import { FaLinkedinIn } from "react-icons/fa";
import { AiOutlineCopyright } from "react-icons/ai";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";

import coder from "../icons/coder.png";

import logo from "../icons/logounoprep.png";
import bondsIndia from "../icons/bondsIndia.png";
import company2 from "../icons/company2.png";
import section1 from "../icons/section1image.jpeg";
import section2 from "../icons/section2image.jpeg";
import section3 from "../icons/section3image.jpeg";
import section4 from "../icons/section4image.jpeg";
import company3 from "../icons/company3.png";
import company4 from "../icons/company4.png";
import company5 from "../icons/company5.png";
import company9 from "../icons/company9.png";
import company11 from "../icons/company11.png";
import company12 from "../icons/company12.png";
import company13 from "../icons/company13.png";
import company14 from "../icons/company14.png";
import company16 from "../icons/company16.png";
import company17 from "../icons/company17.png";
import company18 from "../icons/company18.png";
import company19 from "../icons/company19.png";
import isa1 from "../icons/isa1.png";
import isa2 from "../icons/isa2.jpg";
import isa3 from "../icons/isa3.jpg";
import isa4 from "../icons/isa4.jpg";
import isa5 from "../icons/isa5.jpg";
import isa6 from "../icons/isa6.jpg";
import isa7 from "../icons/isa7.png";
import isa8 from "../icons/isa8.jpg";
import medplus from "../icons/medplus.png";
import { useNavigate } from "react-router-dom";
import useInView from "./Services/customHook";
export const HiringPartnerSlider = () => {
  return (
    <section className={styles.company_logo_section}>
      <h2 className={styles.companies_title} id={styles.subHeading}>
        Join 100s of organisations that trust Unoprep to test their students
      </h2>
      <div className={styles.company_logo}>
        <img src={medplus} className={styles.slide1} alt="medplus" />
        <img src={isa5} className={styles.slide1} alt="medplus" />
        <img src={bondsIndia} className={styles.slide1} alt="bondsIndia" />
        <img src={company2} className={styles.slide1} alt="company2" />
        <img src={company3} className={styles.slide1} alt="company3" />
        <img src={isa1} className={styles.slide1} alt="company3" />
        <img src={company4} className={styles.slide1} alt="company4" />
        <img src={company5} className={styles.slide1} alt="company5" />
        <img src={isa2} className={styles.slide1} alt="company5" />
        <img src={company9} className={styles.slide1} alt="company9" />
        <img src={company11} className={styles.slide1} alt="company11" />
        <img src={company12} className={styles.slide1} alt="company12" />
        <img src={isa3} className={styles.slide1} alt="company12" />
        <img src={company13} className={styles.slide1} alt="company13" />
        <img src={company14} className={styles.slide1} alt="company14" />
        <img src={isa4} className={styles.slide1} alt="company16" />
        <img src={company16} className={styles.slide1} alt="company16" />
        <img src={company17} className={styles.slide1} alt="company17" />
        <img src={company18} className={styles.slide1} alt="company18" />
        <img src={company19} className={styles.slide1} alt="company19" />

        <img src={medplus} className={styles.slide1} alt="medplus" />
        <img src={bondsIndia} className={styles.slide1} alt="bondsIndia" />
        <img src={company2} className={styles.slide1} alt="company2" />
        <img src={isa5} className={styles.slide1} alt="company2" />
        <img src={company3} className={styles.slide1} alt="company3" />
        <img src={company4} className={styles.slide1} alt="company4" />
        <img src={isa6} className={styles.slide1} alt="company5" />
        <img src={company5} className={styles.slide1} alt="company5" />
        <img src={company9} className={styles.slide1} alt="company9" />
        <img src={company11} className={styles.slide1} alt="company11" />
        <img src={isa7} className={styles.slide1} alt="company11" />
        <img src={company12} className={styles.slide1} alt="company12" />
        <img src={company13} className={styles.slide1} alt="company13" />
        <img src={company14} className={styles.slide1} alt="company14" />
        <img src={company16} className={styles.slide1} alt="company16" />
        <img src={isa8} className={styles.slide1} alt="company16" />
        <img src={company17} className={styles.slide1} alt="company17" />
        <img src={company18} className={styles.slide1} alt="company18" />
        <img src={company19} className={styles.slide1} alt="company19" />
      </div>
    </section>
  );
};

const Section = ({ children }) => {
  const [ref, inView] = useInView({
    threshold: 0.1, // Trigger the animation when 10% of the section is visible
  });

  return (
    <div ref={ref} className={`section ${inView && styles.animate}}`}>
      {children}
    </div>
  );
};

export default function NewHome() {
  const [FAQToggle, setFAQToggle] = useState(null);
  let FAQData = [
    [
      "How easy is it to create a test on Unoprep?",
      "Creating a test (or a test series) on Unoprep is incredibly easy. The platform allows you to customize every aspect of the test, from time limits to grading criteria, and you can do it all with just a few clicks.You are welcome to take a demo to understand the platform better.",
    ],
    [
      "How are tests scored, and can I customize grading?",
      "For objective questions like MCQs, Unoprep offers automated scoring, giving instant results as soon as the test is completed. For subjective or theory-based questions, you can manually grade and provide personalized feedback. Grading criteria can be customized based on your requirements",
    ],
    [
      "How does Unoprep prevent cheating during online tests?",
      "Unoprep offers a range of anti-cheating nad proctoring tools to maintain test integrity including screen recording, activity restrictions etc. These features help ensure that students take the test under secure conditions.",
    ],
    [
      "Is the content I upload secure and private?",
      "Absolutely. All your academic content is stored securely with encryption. You can control who accesses your content, set permissions for staff, and make your tests and study materials non-downloadable to prevent unauthorized sharing. Your data and content are fully protected at all times..",
    ],
    [
      " Does Unoprep offer test scoring for tests taken on physical answer sheets?",
      "Yes. Unoprep provides a comprehensive set of features for scoring physical answer sheets including OMR answer sheets. It also allows you to give personalized feedback on theory-based answers to students. Students also have a dedicated dashboard where they can review their test results, track progress, and see upcoming tests, ensuring they stay engaged throughout the learning process",
    ],
  ];

  const toggleFaqQues = (i) => {
    if (i === FAQToggle) {
      setFAQToggle(null);
    } else if (i === 0) {
      setFAQToggle(i);
    } else if (FAQToggle == null) {
      setFAQToggle(i);
    } else if (i !== FAQToggle) {
      setFAQToggle(i);
    }
  };
  // console.log(FAQToggle)
  const listFaq = FAQData.map((value, ind) => {
    return (
      <div
        key={ind}
        onClick={() => {
          toggleFaqQues(ind);
        }}
      >
        <div className={styles.faq_item}>
          <div className={styles.faq_question}>{value[0]}</div>
          <span className={styles.faq_toggle}>
            {FAQToggle === ind ? "-" : "+"}
          </span>
        </div>
        <div
          style={{
            width: "95%",
            height: "100%",
            padding: "15px 15px",
            margin: "auto",
            background: "rgba(241, 241, 241, 1)",
            wordSpacing: "5px",
            color: "gray",
            display: FAQToggle === ind ? "block" : "none",
          }}
        >
          {value[1]}
        </div>
      </div>
    );
  });
  const navigate = useNavigate();
  return (
    <main id={styles.newHome_component}>
      <section className={styles.home_main_Section}>
        <div className={styles.home_main}>
          <div className={styles.home_main_content_section}>
            <h1>Launch and manage Tests for your students. Automatically.</h1>
            <h6>
              With Unoprep's all-in-one platform, organisations can create,
              deliver, score and analyze tests for their students effortlessly.
              All backed by superior security & privacy.
            </h6>
            <div className={styles.btn}>
              <button
                className={styles.signup_button}
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/appointments/schedules/AcZssZ01XLRJQ_E11qla0j2_0RSTx7edi2mdQQ3uMV_xgvAbcUOemEmPxLLlFvrIxbfm-Ft0bvxXJODx?gv=true"
                  )
                }
              >
                Schedule a Demo
              </button>
              <button
                className={styles.signup_button}
                onClick={() => navigate("/organization")}
              >
                Free Trial
              </button>
            </div>
            <label>
              RATED ⭐⭐⭐⭐⭐<span>(4.8)</span>
            </label>
          </div>
        </div>
        <div className={styles.home_main_left}>
          <img
            className={styles.home_main_image}
            src={coder}
            loading="lazy"
            alt="software developer"
          />
        </div>
      </section>
      {<HiringPartnerSlider />}

      <section className={styles.mock_dashboard}>
        <div>
          <h2 id={styles.subHeading}>Create & customize tests with ease</h2>
          <h5> Easily build comprehensive tests that match your curriculum.</h5>
        </div>
        <Section>
          <div className={styles.demo_section}>
            <div className={styles.left_side}>
              <p>
                Unoprep lets you quickly create online tests in multiple formats
                like MCQs, subjective or coding related. Whether it's a
                full-length exam or a quick quiz, you can customize every detail
                to suit your student's needs
              </p>
              <ul>
                <li>
                  <h3>Effortlessly build and organize your question bank</h3>
                </li>
                <li>
                  <h3>Bulk upload to get started quickly</h3>
                </li>
                <li>
                  <h3>Customized tags for each selection, filtering </h3>
                </li>
                <li>
                  <h3>
                    Set customized difficulty levels, time limits and scoring
                    criteria
                  </h3>
                </li>
              </ul>
              <p className={styles.try_it}>
                No more manually compiling test papers — just a few clicks, and
                your test is ready.
              </p>
              <button
                id={styles.button}
                className={styles.signup_button}
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/appointments/schedules/AcZssZ01XLRJQ_E11qla0j2_0RSTx7edi2mdQQ3uMV_xgvAbcUOemEmPxLLlFvrIxbfm-Ft0bvxXJODx?gv=true"
                  )
                }
              >
                Schedule a Demo
              </button>
            </div>

            <div className={styles.dashboard_img_section}>
              <img
                className={styles.onlineTraining_img}
                src={section1}
                alt="interviewPractice"
              />
            </div>
          </div>
        </Section>
        <Section>
          <h2 id={styles.subHeading}>Instant delivery to students</h2>
          <h5>
            Send tests to your entire batch or specific groups in seconds.
          </h5>
          <div className={styles.demo_section}>
            <div className={styles.dashboard_img_section}>
              <img
                id={styles.mockToolPractice_img}
                src={section2}
                alt="mockToolPractice"
              />
            </div>
            <div className={styles.left_side}>
              <p>
                With Unoprep, you can share test links directly with enrolled
                students or a select batch.
              </p>
              <ul>
                <li>
                  <h3> Deliver tests with a single click .</h3>
                </li>
                <li>
                  <h3>
                    Allow students to take tests anytime, anywhere, on any
                    device.
                  </h3>
                </li>
                <li>
                  <h3>
                    Students get a dashboard to view their enrolled tests, test
                    schedules, their scoring and results, all in one place.
                  </h3>
                </li>
              </ul>
              <p className={styles.try_it}>
                No more manual coordination, no more back and forth with
                students — let the platform handle it automatically
              </p>
              <button
                id={styles.button}
                className={styles.signup_button}
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/appointments/schedules/AcZssZ01XLRJQ_E11qla0j2_0RSTx7edi2mdQQ3uMV_xgvAbcUOemEmPxLLlFvrIxbfm-Ft0bvxXJODx?gv=true"
                  )
                }
              >
                Schedule a Demo
              </button>
            </div>
          </div>
        </Section>
        <Section>
          <h2 id={styles.subHeading}>Automated scoring & feedback</h2>
          <h5> Save time with instant results and actionable insights.</h5>

          <div className={styles.demo_section}>
            <div className={styles.left_side}>
              <p>
                Unoprep's automated scoring feature gives your students
                immediate results, eliminating the need for manual grading.
                Detailed analytics helps identify topics and areas where your
                students need extra help.
              </p>
              <ul>
                <li>
                  <h3>Real-time scoring and ranking for objective questions</h3>
                </li>
                <li>
                  <h3>
                    Manual grading options for subjective answers, with feedback
                    integration.
                  </h3>
                </li>
                <li>
                  <h3>
                    Comprehensive performance reports for both students and
                    academies
                  </h3>
                </li>
              </ul>
              <p className={styles.try_it}>
                Get instant insights into how your students are performing
                without hours spent grading.
              </p>
              <button
                id={styles.button}
                className={styles.signup_button}
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/appointments/schedules/AcZssZ01XLRJQ_E11qla0j2_0RSTx7edi2mdQQ3uMV_xgvAbcUOemEmPxLLlFvrIxbfm-Ft0bvxXJODx?gv=true"
                  )
                }
              >
                Schedule a Demo
              </button>
            </div>
            <div className={styles.dashboard_img_section}>
              <img
                className={styles.revisingThings_img}
                src={section3}
                alt="revisingThings"
              />
            </div>
          </div>
        </Section>
        <Section>
          <h2 id={styles.subHeading}>Content security & privacy control</h2>
          <h5>
            {" "}
            Protect your academic content with access controls for your content.
          </h5>
          <div className={styles.demo_section}>
            <div className={styles.dashboard_img_section}>
              <img
                className={styles.onlineTraining_img}
                src={section4}
                alt="jobSearch"
              />
            </div>
            <div className={styles.left_side}>
              <p>
                Unoprep ensures that all your proprietary academic
                materials—tests, question banks, or study content—are fully
                protected. Control who can access what within your academy and
                prevent unauthorized sharing or downloading of your intellectual
                property.
              </p>

              <ul>
                <li>
                  <h3>Restrict content access to enrolled students only.</h3>
                </li>
                <li>
                  <h3>
                    Non-downloadable option to prevent unauthorized distribution
                    of your content
                  </h3>
                </li>
                <li>
                  <h3>
                    Role-based access levels and activity logs for your staff
                  </h3>
                </li>
                <li>
                  <h3>
                    Secure storage with encrypted databases to safeguard
                    sensitive student data
                  </h3>
                </li>
              </ul>
              <p className={styles.try_it}>
                Maintain full control over your content and privacy, so your
                academy's resources stay protected at all times.
              </p>
              <button
                id={styles.button}
                className={styles.signup_button}
                onClick={() =>
                  window.open(
                    "https://calendar.google.com/calendar/appointments/schedules/AcZssZ01XLRJQ_E11qla0j2_0RSTx7edi2mdQQ3uMV_xgvAbcUOemEmPxLLlFvrIxbfm-Ft0bvxXJODx?gv=true"
                  )
                }
              >
                Schedule a Demo
              </button>
            </div>
          </div>
        </Section>
      </section>
      <section className={styles.faq_container}>
        <div className={styles.faq_left}>{listFaq}</div>
        <div className={styles.faq_right}>
          <h2>FAQ's</h2>
          <p className={styles.faq_description}>Have more questions?</p>
          <p className={styles.faq_description}>
            Feel free to reach out to our support team, and we'll be happy to
            assist you. Alternatively, you can schedule a Demo to understand the
            feature better.
          </p>
          <button id={styles.button} className={styles.contact_button}>
            Contact Us
          </button>
        </div>
      </section>
      <div>
        <section className={styles.footerMain}>
          <div className={styles.brandLogo}>
            <img src={logo} height={70} alt="logo" />
            <label>
              <strong>Your all-in-one test management tool</strong>
            </label>
            <button
              id={styles.button}
              className={styles.signup_button}
              onClick={() =>
                window.open(
                  "https://calendar.google.com/calendar/appointments/schedules/AcZssZ01XLRJQ_E11qla0j2_0RSTx7edi2mdQQ3uMV_xgvAbcUOemEmPxLLlFvrIxbfm-Ft0bvxXJODx?gv=true"
                )
              }
            >
              Schedule a Demo
            </button>
          </div>
          <div className={styles.privacyPolicy}>
            <label>
              <span>Resources</span>
            </label>
            <label>About Us</label>
            <label>Case Studies</label>
            <label>Cookie Settings</label>
            <label>Privacy Policy</label>
          </div>

          <div className={styles.socialMedia}>
            <label>
              <span>Contact</span>
            </label>
            <label>+919873260670</label>
            <label>+917428038239</label>
            <label>contact@unoprep.com</label>
          </div>
        </section>

        <div className={styles.socialBox}>
          <div className={styles.leftSocial}>
            <AiOutlineCopyright style={{ marginRight: "5px" }} />
            Unoprep, 2024. All rights reserved
          </div>
          <div className={styles.rightSocial}>
            <h6>Connect with us</h6>
            <div>
              <label>
                <span>
                  <FaLinkedinIn />
                </span>
              </label>
              <label>
                <span>
                  <FaTwitter />
                </span>
              </label>
              <label>
                <span>
                  <FaFacebookF />
                </span>
              </label>
              <label>
                <span>
                  <FaYoutube />
                </span>
              </label>
            </div>
          </div>
        </div>
        <p
          style={{
            textAlign: "right",
            margin: "20px 58px 20px 20px",
            fontSize: "12px",
          }}
        >
          Various trademarks held by their respective owners
        </p>
      </div>
    </main>
  );
}
