import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const PracticeChart = ({ data }) => {
  const days = data.map((ele) => ele.dateofattempt.slice(0, 10));
  const options = {
    title: {
      text: "Practice Weekly Report",
      align: "center",
    },
    accessibility: {
      enabled: false // Disable accessibility features
    },
    xAxis: [
      {
        categories: [...days, (days[6] = "today")],
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          format: "{value}",
          style: {
            color: Highcharts.getOptions().colors[1],
          },
        },
        title: {
          text: "Questions",
        },
      },
      {
        title: {
          text: "Time",
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    legend: {
      align: "left",
      x: 80,
      verticalAlign: "top",
      y: 60,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || // theme
        "rgba(255,255,255,0.25)",
    },
    
    series: [
      {
        name: "Time",
        type: "column",
        yAxis: 1,
        data: data.map((ele) => ele.timespend),
        tooltip: {
          valueSuffix: " Min",
        },
      },
      {
        name: "Question",
        type: "spline",
        data: data.map((ele) => ele.questions),
        tooltip: {
          valueSuffix: "",
        },
      },
    ],
  };

  return (
    <div
      style={{
        width: "100%",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default PracticeChart;
