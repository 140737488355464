import React, { useState, useRef, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
// import Modal from "react-bootstrap/Modal";
import { toast, ToastContainer } from "react-toastify";
import styles from "./questions.module.css";
import { PlusOutlined } from "@ant-design/icons";
import Select from "react-select";
import { Modal, Button, Upload, InputNumber } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { postrequest } from "../../Middleware/managerequest";
import { Line } from "@uiw/react-codemirror";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const QuestionWiseTime = {
  Question: 300,
  Mcq: 180,
  Coding: 1800,
  Passage: 6000,
};

const minutesMapping = {
  Coding: {
    Easy: 600,
    Medium: 900,
    Hard: 1200,
  },
  Question: {
    Easy: 50,
    Medium: 60,
    Hard: 100,
  },
  Mcq: {
    Easy: 50,
    Medium: 70,
    Hard: 110,
  },
  Passage: {
    Easy: 3000,
    Medium: 6000,
    Hard: 10000,
  },
};
const EditQuestions = ({
  editdata,
  handleModal,
  topics,
  fetcher,
  maintopics,
  user,
  access,
}) => {
  const [difficulty, setDifficulty] = useState(editdata.level);
  const [time, setTime] = useState(0);
  const [point, setPoints] = useState(editdata?.point ?? 0);
  const [Options, setOptions] = useState({
    opt1: editdata.opt1,
    opt2: editdata.opt2,
    opt3: editdata.opt3,
    opt4: editdata.opt4,
  });

  // Decription Editor
  const [editorHtml, setEditorHtml] = useState("");
  const [answer, setAnswer] = useState(editdata.ans);
  const [question, setQuestion] = useState(editdata.qstn);

  useEffect(() => {
    setEditorHtml(editdata.qstn.question);
    if (editdata?.time < minutesMapping[editdata.type]?.[editdata.level]) {
      setTime(minutesMapping[editdata.type]?.[editdata.level]);
    } else {
      setTime(
        editdata?.time ?? minutesMapping[editdata.type]?.[editdata.level]
      );
    }
  }, [editdata]);

  const [issendrequest, setIssendrequest] = useState(false);

  // Question Attachments
  const [fileList, setFileList] = useState(() => {
    let arr = [];
    editdata?.attachments?.length > 0 &&
      editdata.attachments.map((item, index) => {
        arr.push({
          uid: index,
          name: `attachement${1}.png`,
          status: "done",
          url: item,
        });
        return true;
      });

    return arr;
  });
  const [ansList, setAnsList] = useState(() => {
    let arr = [];
    editdata?.modelans?.length > 0 &&
      editdata.modelans.map((item, index) => {
        arr.push({
          uid: index,
          name: `File${index + 1}`,
          status: "done",
          url: item,
        });
        return true;
      });

    return arr;
  });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpenAns, setPreviewOpenAns] = useState(false);
  const [previewImageAns, setPreviewImageAns] = useState("");
  const [previewTitleAns, setPreviewTitleAns] = useState("");
  const [title, setTitle] = useState(editdata?.title ?? "");
  // Topic
  const [labels, setLabels] = useState(() => {
    let topics = [];
    editdata?.concept?.map((item) => {
      let obj = {
        label: item,
        value: item,
      };
      topics.push(obj);
      return true;
    });
    return topics;
  });
  // Tags
  const [maintopic, setMainTopic] = useState(
    editdata.maintopics !== undefined
      ? { label: editdata.maintopics[0], value: editdata.maintopics[0] }
      : ""
  );

  const handleTopics = (e) => {
    setLabels(e);
  };

  const handleMainTopics = (e) => {
    setMainTopic(e);
  };

  const handleOptions = (e) => {
    setOptions({ ...Options, [e.target.name]: e.target.value });
  };

  // convert images to base64ToBlob
  function base64ToBlob(base64Content) {
    const byteCharacters = atob(base64Content);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: "image/jpeg" }); // Specify the MIME type accordingly
  }

  // Final Submit
  const Submit = () => {
    let Formdata = new FormData();

    const parser = new DOMParser();
    const doc = parser.parseFromString(question.question, "text/html");

    const imgElement = doc.querySelectorAll("img");
    let images = [];
    if (imgElement.length > 0) {
      imgElement.forEach((item, ind) => {
        const srcData = item.getAttribute("src");

        if (!srcData.startsWith("https://")) {
          const blob1 = base64ToBlob(srcData.split(",")[1]);
          item.removeAttribute("src");
          const file = new File([blob1], `questioneditor-${ind}.jpeg`, {
            type: "image/jpeg",
          });

          images.push(file);
        }
      });
    }
    const updatedHTML = doc.documentElement.outerHTML;
    // Append each selected file to the FormData object
    images?.forEach((file, index) => {
      Formdata.append(`questionimg`, file);
    });
    let remaining = [];
    fileList?.map((item) => {
      if (item.originFileObj) {
        Formdata.append("questionimg", item.originFileObj);
      } else {
        remaining.push(item.url);
      }
      return true;
    });
    let remainingup = [];
    ansList?.map((item) => {
      if (item.originFileObj) {
        Formdata.append("quesfile", item.originFileObj);
      } else {
        remainingup.push(item.url);
      }
      return true;
    });

    let arr = [],
      tags = [];
    labels.map((item) => {
      if (item.__isNew__) {
        arr.push(item);
      }
      tags.push(item.value);
      return true;
    });
    let maint = [];

    maint.push(maintopic?.value);

    let obj = {};
    if (editdata.type === "Mcq") {
      obj = {
        newtopics: arr,
        details: {
          type: editdata.type,
          qstn: { ...question, question: updatedHTML },
          point:
            point > 0
              ? point
              : difficulty === "Easy"
              ? 1
              : difficulty === "Medium"
              ? 2
              : 3,
          ...Options,
          ans: answer,
          time: time,
          level: difficulty,
          concept: tags,
          maintopics: maint,
          qid: editdata.qid,
          attachments: remaining ?? [],
          modelans: remainingup ?? [],
          title: title !== "" ? title : undefined,
          lastupdateby: `${user?.FullName} on ${new Date()}`,
          createdby: editdata?.createdby ?? "",
        },
      };
    } else if (editdata.type === "Question") {
      obj = {
        newtopics: arr,
        details: {
          type: editdata.type,
          qstn: { ...question, question: updatedHTML },
          point:
            point > 0
              ? point
              : difficulty === "Easy"
              ? 2
              : difficulty === "Medium"
              ? 3
              : 4,
          level: difficulty,
          concept: tags,
          maintopics: maint,
          time: time,
          qid: editdata.qid,
          attachments: remaining ?? [],
          modelans: remainingup ?? [],
          title: title !== "" ? title : undefined,
          lastupdateby: `${user?.FullName} on ${new Date()}`,
          createdby: editdata?.createdby ?? "",
        },
      };
    } else {
      obj = {
        newtopics: arr,
        details: {
          type: editdata.type,
          qstn: { ...question, question: updatedHTML },
          point:
            point > 0
              ? point
              : difficulty === "Easy"
              ? 3
              : difficulty === "Medium"
              ? 4
              : 5,
          level: difficulty,
          concept: tags,
          TestCase: editdata.TestCase,
          maintopics: maint,
          time: time,
          qid: editdata.qid,
          attachments: remaining ?? [],
          modelans: remainingup ?? [],
          title: title !== "" ? title : undefined,
          lastupdateby: `${user?.FullName} on ${new Date()}`,
          createdby: editdata?.createdby ?? "",
        },
      };
    }
    Formdata.append("questiondata", JSON.stringify(obj));
    setIssendrequest(true);

    postrequest(`/home/edit/${user?.subdomain}`, Formdata)
      .then((res) => {
        setIssendrequest(false);
        fetcher();
        handleModal();
      })
      .catch((err) => {
        setIssendrequest(false);
        toast.warning("server error", {
          position: "top-right",
          autoClose: 1500,
        });
      });
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  const editorRef = useRef(null);

  const setDefaultImageSize = () => {
    const editor = editorRef.current;
    if (!editor || !editor.editor || !editor.editor.root) return;

    const editorRoot = editor.editor.root;
    const images = editorRoot.querySelectorAll("img");
    images.forEach((image) => {
      image.setAttribute("width", "150"); // Default width
      image.setAttribute("height", "100"); // Default height
    });
  };
  useEffect(() => {
    setDefaultImageSize(); // Set default size on initial loading

    const editor = editorRef.current;
    if (!editor || !editor.editor || !editor.editor.root) return;

    const editorRoot = editor.editor.root;

    const handleMutations = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
          setDefaultImageSize(); // Set default size for newly inserted images
        }
      });
    };

    const observer = new MutationObserver(handleMutations);
    observer.observe(editorRoot, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    // setQstn({ ...qstn, question: html });
    setQuestion({
      ...question,
      question: html,
    });
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handlePreviewAns = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImageAns(file.url || file.preview);
    setPreviewOpenAns(true);
    setPreviewTitleAns(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = ({ fileList: newFileList }) => {
    let newfile = [...newFileList];
    if (newfile.length > 0) {
      newfile[0].status = "done";
    }

    setFileList(newFileList);
  };
  const handleAnswer = ({ fileList: newFileList }) => {
    let newfile = [...newFileList];

    if (newfile.length > 0) {
      newfile[0].status = "done";
    }

    setAnsList(newFileList);
  };

  const handleCancel = () => setPreviewOpen(false);
  const handleCancelAns = () => setPreviewOpenAns(false);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <Modal
      open={true}
      onOk={() => Submit()}
      title="Edit Question"
      centered
      onCancel={handleModal}
      maskClosable={false}
      closable={false}
      okText="Submit"
      width={1200}
      style={{ overflowY: "auto", height: "100vh" }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          {!issendrequest ? (
            <OkBtn />
          ) : (
            <Button disabled={issendrequest}>Loading</Button>
          )}
        </>
      )}
    >
      <ToastContainer />

      <div
        className="d-flex justify-content-between mt-5"
        style={{ height: "auto", maxHeight: "80vh" }}
      >
        <div className="d-flex flex-column" style={{ width: "60%" }}>
          {/* Question Title */}
          <div className="d-flex mb-3 mx-3">
            <label className={styles.titles}>Question : Heading</label>{" "}
            <div className={styles.questionheading_main}>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className={styles.questionheading}
              />
            </div>
          </div>
          {/* Question Description */}
          <div className="d-flex mb-3 mx-3">
            <label className={styles.titles}>Question :</label>{" "}
            <div className={styles.editor}>
              <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                style={{ height: "200px" }}
                ref={editorRef}
              />
            </div>
          </div>

          <div className="d-flex ">
            <label className={`mx-3 mt-3`} style={{ width: "100px" }}>
              Model answer (PDF/Video) :{" "}
            </label>
            <Upload
              accept="video/*, .pdf"
              listType="picture-card"
              maxCount={2}
              fileList={ansList}
              onPreview={handlePreviewAns}
              onChange={handleAnswer}
              className="m-2"
            >
              {ansList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpenAns}
              title={previewTitleAns}
              footer={null}
              onCancel={handleCancelAns}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImageAns}
              />
            </Modal>
          </div>
          {/* Question Attachments */}
          <div className="d-flex ">
            <label className={`m-3`} style={{ width: "100px" }}>
              Attachments (PNG/JPEG) :{" "}
            </label>
            <Upload
              accept=".png,.jpeg"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              className="m-2"
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <Modal
              open={previewOpenAns}
              title={previewTitleAns}
              footer={null}
              onCancel={handleCancelAns}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImageAns}
              />
            </Modal>
          </div>
        </div>

        {/* Right Side  */}
        <div className="d-flex flex-column" style={{ width: "40%" }}>
          {" "}
          <div className="d-flex mx-3 mb-3">
            <label className={styles.titles}>Main Topic :</label>
            <Select
              options={maintopics}
              placeholder="Search Concept"
              value={maintopic}
              onChange={handleMainTopics}
              isClearable={true}
              isSearchable={true}
              className={styles.edit_select}
            />
          </div>
          <div className="d-flex mx-3">
            <label className={styles.titles}>Topics :</label>
            {access && access?.role === "Admin" ? (
              <CreatableSelect
                options={topics}
                placeholder="Search Concept"
                value={labels}
                onChange={handleTopics}
                isClearable={true}
                isSearchable={true}
                className={styles.edit_select}
                isMulti
              />
            ) : (
              <Select
                options={topics}
                placeholder="Search Concept"
                value={labels}
                onChange={handleTopics}
                isClearable={true}
                isSearchable={true}
                className={styles.edit_select}
                isMulti
              />
            )}
          </div>
          {/* QUestion Difficulties */}
          <div className="d-flex mt-3 mx-3 align-items-center">
            <label className={styles.titles}>Difficulty :</label>
            <div className={styles.difficultyoptions}>
              <span className="d-flex align-items-center">
                <input
                  type="radio"
                  name="level"
                  className={styles.radiobutton}
                  id="level1"
                  defaultChecked={editdata.level === "Easy"}
                  value={"Easy"}
                  onChange={(e) => setDifficulty(e.target.value)}
                />
                <label
                  className="mx-2"
                  htmlFor="level1"
                  style={{ cursor: "pointer" }}
                >
                  Easy
                </label>
              </span>
              <span className="d-flex align-items-center">
                <input
                  type="radio"
                  name="level"
                  className={styles.radiobutton}
                  id="level2"
                  defaultChecked={editdata.level === "Medium"}
                  value={"Medium"}
                  onChange={(e) => setDifficulty(e.target.value)}
                />
                <label
                  className="mx-2"
                  htmlFor="level2"
                  style={{ cursor: "pointer" }}
                >
                  Medium
                </label>
              </span>
              <span className="d-flex align-items-center">
                <input
                  type="radio"
                  name="level"
                  className={styles.radiobutton}
                  id="level3"
                  defaultChecked={editdata.level === "Hard"}
                  value={"Hard"}
                  onChange={(e) => setDifficulty(e.target.value)}
                />
                <label
                  className="mx-2"
                  htmlFor="level3"
                  style={{ cursor: "pointer" }}
                >
                  Hard
                </label>
              </span>
            </div>
          </div>
          <div className="d-flex mx-3 mt-3">
            <label className={styles.titles}>Question Time:</label>
            <InputNumber
              max={[QuestionWiseTime[editdata.type]]}
              controls={false}
              addonAfter={"/Sec"}
              addonBefore={"Max " + [QuestionWiseTime[editdata.type]]}
              className={styles.edit_select}
              onChange={(val) => setTime(val)}
              value={time}
              min={minutesMapping[editdata.type][editdata.level]}
            />
          </div>
          <div className="d-flex mx-3 mt-3">
            <label className={styles.titles}>Points:</label>
            <InputNumber
              max={10}
              controls={false}
              addonAfter={"Max 10 Points"}
              className={styles.edit_select}
              onChange={(val) => setPoints(val)}
              value={point}
            />
          </div>
          {/* MCQ Options */}
          {editdata.type === "Mcq" && (
            <div className="d-flex mx-3 mt-3">
              <label className={`${styles.titles} mt-3`}>Options :</label>
              <div className={styles.mcqoptions}>
                {[Options.opt1, Options.opt2, Options.opt3, Options.opt4].map(
                  (item, index) => {
                    return (
                      <div
                        key={index}
                        className="mt-3 d-flex align-items-center"
                      >
                        <input
                          type="radio"
                          name="options"
                          value={Options[`opt${index + 1}`]}
                          defaultChecked={item === editdata.ans}
                          className={styles.radiobutton}
                          onChange={(e) => setAnswer(e.target.value)}
                        />
                        <input
                          className="mx-3"
                          defaultValue={item}
                          name={`opt${index + 1}`}
                          placeholder={`option ${index + 1}`}
                          onChange={(e) => handleOptions(e)}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default EditQuestions;
