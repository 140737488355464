import React, { useEffect, useState } from "react";
import {
  Layout,
  Steps,
  Upload,
  Image,
  message,
  InputNumber,
  Select,
  Tag,
} from "antd";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  PlusOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import { useNavigate, useLocation } from "react-router-dom";
import { getrequest, postrequest } from "../../Middleware/managerequest";
import { useSelector } from "react-redux";
import styles from "../AdminHome.module.css";
import styles1 from "./styles/createcourse.module.css";

import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import ReactQuill from "react-quill";
import skills from "./skills.json";

const { Content, Sider } = Layout;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Coursecourse = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const data = useSelector((state) => state.Data);
  const { user, access, clientdetails } = data;
  const [openDwa, setOpenDwa] = useState(false);
  const [courseDetails, setCourseDetails] = useState({
    CourseTitle: "",
    description: "",
    thumbnail: "",
    pricing: {
      free: true,
      price: 0,
    },
    skills: [],
  });

  const [current, setCurrent] = useState(0);
  const onChange = (value) => {
    setCurrent(value);
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  const [fileList, setFileList] = useState([]);
  const handleChange = async ({ fileList: newFileList }) => {
    let newfile = [...newFileList];
    if (newfile.length > 0) {
      newfile[0].status = "done";
      newfile[0].preview = await getBase64(newfile[0].originFileObj);
      setPreviewImage(newfile[0].preview);
    } else {
      setPreviewImage("");
    }
    setFileList(newFileList);
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );

  const props = {
    beforeUpload: (file) => {
      const isPNG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "image/webp" ||
        file.type === "image/svg";
      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],

      ["clean"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  useEffect(() => {
    if (state) {
      getrequest(`/fetchcoursedetails/${state}`)
        .then((res) => {
          const { CourseTitle, description, thumbnail, skills, pricing } =
            res.data;
          setCourseDetails((prev) => ({
            ...prev,
            CourseTitle: CourseTitle,
            description: description,
            pricing: pricing,
            skills: skills,
            thumbnail: thumbnail,
          }));

          setPreviewImage(thumbnail);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [state]);

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
  ];

  const steps = {
    0: () => (
      <>
        <label className={styles1.course_heading}>
          How about the course title?
        </label>
        <input
          className={styles1.title_input}
          onChange={(e) =>
            setCourseDetails((prev) => ({
              ...prev,
              CourseTitle: e.target.value,
            }))
          }
          placeholder="e.g. HTML"
          value={courseDetails?.CourseTitle}
        />
      </>
    ),
    1: () => (
      <>
        <label className={styles1.course_heading}>
          Describe more about the course
        </label>
        <ReactQuill
          theme="snow"
          value={courseDetails?.description}
          onChange={(e) =>
            setCourseDetails((prev) => ({
              ...prev,
              description: e,
            }))
          }
          modules={modules}
          formats={formats}
          className={styles1.description}
        />
      </>
    ),
    2: () => (
      <>
        <label className={styles1.course_heading}>Upload Thumbnail</label>
        {!previewImage ? (
          <Upload
            accept=".png, .svg, .jpg, .jpeg, .webp"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            {...props}
          >
            {fileList.length >= 1 ? null : uploadButton}
          </Upload>
        ) : (
          <>
            <Image width={100} src={previewImage} />
            <label
              style={{ cursor: "pointer" }}
              onClick={() => setPreviewImage("")}
            >
              Delete
            </label>
          </>
        )}

        {previewImage && (
          <Image
            wrapperStyle={{
              display: "none",
            }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              // afterOpenChange: (visible) => !visible && setPreviewImage(""),
            }}
            src={previewImage}
          />
        )}
      </>
    ),
    3: () => (
      <>
        <label className={styles1.course_heading}>
          How it will be available for users?
        </label>
        <span className="d-flex align-items-center ">
          <label className="mx-4" htmlFor="price">
            {" "}
            Is it Free ?
          </label>
          <input
            id="price"
            style={{ width: "18px", height: "18px", cursor: "pointer" }}
            type="checkbox"
            onChange={(e) => {
              setCourseDetails((prev) => ({
                ...prev,
                pricing: { ...prev.pricing, free: e.target.checked },
              }));
            }}
            checked={courseDetails?.pricing?.free}
          />
        </span>
        {!courseDetails.pricing.free && (
          <div className={styles1.pricing}>
            <span className="d-flex align-items-center">
              <InputNumber
                min={0}
                addonBefore={
                  <label style={{ width: "100px" }}>Course Price</label>
                }
                value={courseDetails?.pricing?.price}
                addonAfter={"₹"}
                style={{ width: "300px" }}
                size="small"
                type="number"
                controls={false}
                onChange={(e) =>
                  setCourseDetails((prev) => ({
                    ...prev,
                    pricing: {
                      ...prev.pricing,
                      price: e,
                    },
                  }))
                }
              />
            </span>
          </div>
        )}
      </>
    ),
    4: () => (
      <>
        <label className={styles1.course_heading}>What they will learn ?</label>
        <div className={styles1.preview_main}>
          <div>
            <Select
              style={{ width: "250px" }}
              value={courseDetails?.skills}
              onChange={(e) => {
                setCourseDetails((prev) => ({
                  ...prev,
                  skills: e,
                }));
              }}
              mode="tags"
              options={skills}
              maxTagCount={"responsive"}
              placeholder="Select the skills"
            />
          </div>
        </div>
      </>
    ),

    5: () => (
      <>
        <label className={styles1.course_heading}>Preview</label>
        <div className={styles1.preview_main}>
          {" "}
          <span className={styles1.preview_line}>
            <label style={{ width: "150px" }}>Course Title</label>
            <label>{courseDetails?.CourseTitle}</label>
          </span>
          <span className={styles1.preview_line}>
            <label style={{ width: "150px" }}>Course Description</label>
            <label
              dangerouslySetInnerHTML={{ __html: courseDetails?.description }}
            />
          </span>
          <span className={styles1.preview_line}>
            <label style={{ width: "150px" }}>Course Price</label>
            <label>
              {courseDetails?.pricing?.free ? (
                "Free"
              ) : (
                <>{courseDetails?.pricing?.price} ₹</>
              )}
            </label>
          </span>
          <span className={styles1.preview_line}>
            <label style={{ width: "150px" }}>Skills</label>
            {console.log(courseDetails)}
            <div className="d-flex flex-wrap w-50">
              {courseDetails?.skills?.map((skills, ind) => {
                return <Tag key={ind}>{skills}</Tag>;
              })}
            </div>
          </span>
          <span className={styles1.preview_line}>
            <label style={{ width: "150px" }}>Thumbnail</label>

            <Image
              width={100}
              src={previewImage}
              style={{ border: "1px solid lightgray", padding: "5px" }}
            />
          </span>
        </div>
      </>
    ),
  };

  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "admin",
      <HomeOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),

    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
    clientdetails?.isgroup &&
      getItem(
        <span className={styles.menulabel}>Batches</span>,
        "managegroups",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    access &&
      access?.role === "Admin" &&
      clientdetails?.isusers &&
      getItem(
        <span className={styles.menulabel}>Manage Users</span>,
        "manageusers",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    "main" === user?.subdomain &&
      getItem(
        <span className={styles.menulabel}>Manage Organizations</span>,
        "my_organizations",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    getItem(
      <span className={styles.menulabel}>Courses</span>,
      "course",
      <ReconciliationOutlined
        style={{ fontWeight: "600", fontSize: "17px" }}
      />,
      null,
      null
    ),
  ];

  // Sidebar Navigation
  const handlerClick = (e) => {
    navigate(`/${e.key}`);
  };

  const Submit = () => {
    let body = {
      ...courseDetails,
      createdby: {
        name: user?.FullName,
        email: user?.Email,
        date: new Date(),
      },
    };
    setLoading(true);

    const formdata = new FormData();
    if (fileList.length) {
      formdata.append("thumbnail", fileList[0].originFileObj);
    }

    formdata.append("course", JSON.stringify(body));
    postrequest(
      `/createcourse/${user?.subdomain}?edit=${
        state !== null
      }&courseid=${state}`,
      formdata
    )
      .then((res) => {
        setLoading(false);
        if (state) {
          navigate(`/course`);
          return;
        }

        navigate(`/createcurriculum?id=${res.data}&mode=create`);
      })
      .catch((err) => setLoading(false));
  };

  return (
    <>
      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>
        <Layout>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />
          <Content
            style={{ width: "90%", margin: "65px  auto", overflow: "auto" }}
          >
            <div className={styles1.maincreate}>
              <Steps
                direction="vertical"
                current={current}
                style={{ height: "100%", width: "250px" }}
                onChange={onChange}
                items={[
                  {
                    title: "Course Title",
                  },
                  {
                    title: "Description",
                  },
                  {
                    title: "Thumbnail",
                  },
                  { title: "Pricing" },
                  { title: "Skills" },
                  {
                    title: "Preview",
                  },
                ]}
              />
              <div className={styles1.right_side_container}>
                <div
                  className={styles1.title_main}
                  style={{ marginTop: current === 5 ? "0" : null }}
                >
                  {" "}
                  {steps[current]()}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mx-5">
              {current !== 0 && (
                <button
                  onClick={() => {
                    setCurrent((prev) => prev - 1);
                  }}
                  className={styles1.prev_btn}
                >
                  Previous
                </button>
              )}
              <button
                disabled={loading}
                onClick={() => {
                  if (current === 5) {
                    if (courseDetails?.CourseTitle === "") {
                      setCurrent(0);
                    } else if (courseDetails?.description === "") {
                      setCurrent(1);
                    } else if (
                      courseDetails?.thumbnail === undefined &&
                      fileList.length === 0
                    ) {
                      setCurrent(2);
                    } else if (
                      !courseDetails?.pricing?.free &&
                      courseDetails?.pricing?.price === 0
                    ) {
                      setCurrent(3);
                    } else if (courseDetails?.skills.length === 0) {
                      setCurrent(4);
                    } else {
                      Submit();
                    }
                  } else {
                    setCurrent((prev) => prev + 1);
                  }
                }}
                className={styles1.next_btn}
              >
                {current === 5 ? (state ? "Save" : "Submit") : "Next"}
              </button>
            </div>
          </Content>
        </Layout>
      </Layout>
      <ProfileSlider
        setOpenDwa={setOpenDwa}
        openDwa={openDwa}
       
      />
    </>
  );
};

export default Coursecourse;
