import React from "react";
import Navbar from "react-bootstrap/Navbar";
import styles from "./NewInstruction.module.css";

import { useSelector } from "react-redux";

const NavHead = () => {
  const data = useSelector((state) => state.Data);
  const { isgivingmock, clientdetails } = data;

  return (
    <Navbar
      className={styles.navbar}
      collapseOnSelect
      expand="xl"
      id="mynavbar"
    >
      <Navbar.Brand className={styles.logo} href="/" disabled={isgivingmock}>
        <img
          src={clientdetails?.logo}
          alt="Logo"
          style={{ width: "auto", height: "45px" }}
        />
        {/* <h3>{clientdetails?.companyname}</h3> */}
      </Navbar.Brand>
    </Navbar>
  );
};

export default NavHead;
