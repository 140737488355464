import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {Spin} from "antd"
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { FaRegEyeSlash, FaRegEye, FaLongArrowAltRight } from "react-icons/fa";
import { postrequest } from "../Middleware/managerequest";
import "../../styles/signin.css"

const Resetpass = () => {
  const [flag, setFlag] = useState(false);
  const [isSubmit,setSubmit]=useState(false)
const {state}=useLocation()
  const [flag1, setFlag1] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [validate, setValidate] = useState(false);
  const data = useSelector((state) => state.Data);
  const { reset } = data;
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else if (e.target[0].value === e.target[1].value) {
      let obj = {
        id: reset,
        password: e.target[1].value,
      };
      setSubmit(true)
      postrequest(`/home/recovery`,obj)
        .then((res) => {
          setSubmit(false)
          if (res.status === 201) {
            toast.error("Error While Resetting Password ", {
              position: "top-center",
              autoClose: 1500,
            });
          } else {
            if(state.id!==undefined){
              navigate(`/signin/${state.id}`)
            }
            else{
              navigate("/");
            }
           
          }
        })
        .catch((err) => {
          setSubmit(false)
          toast.error("Server not responding", {
            position: "top-center",
            autoClose: 1500,
          });
        });
    } else {
      toast.error("Please check confirm password", {
        position: "top-center",
        autoClose: 1500,
      });
    }

    setValidate(true);
  };

  const validatePassword = (value) => {
    const errors = [];

    if (value.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }

    if (!/[A-Z]/.test(value)) {
      errors.push('Password must contain at least one uppercase letter.');
    }

    if (!/\d/.test(value)) {
      errors.push('Password must contain at least one number.');
    }

    if (!/[@#$%^&+=]/.test(value)) {
      errors.push('Password must contain at least one special character.');
    }

    if (errors.length > 0 &&value.length!==0) {
      setPasswordError(errors);
    } else {
      setPasswordError('');
    }
  };
  return (
    <Row className="mt-5 pt-5">
      <ToastContainer />
      <Col
        md={{ span: 5, offset: 4 }}
        lg={{ span: 5, offset: 4 }}
        sm={{ span: 8, offset: 4 }}
        xs={{ span: 8, offset: 3 }}
      >
        <h2>Create New Password</h2>
      </Col>
      <Row>
        <Col
          md={{ span: 6, offset: 4 }}
          lg={{ span: 5, offset: 4 }}
          sm={{ span: 6, offset: 4 }}
          xs={{ span: 8, offset: 3 }}
        >
          <Form noValidate validated={validate} onSubmit={handleSubmit}>
            <Form.Group controlId="validationCustom04">
              <FloatingLabel
                controlId="floatingInput5"
                label="Password"
                className="mb-3"
              >
                <Form.Control
                  type={flag ? "text" : "password"}
                  required
                  pattern="(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                  style={{ width: "100%", position: "relative" }}
                  onChange={(e)=>validatePassword(e.target.value)}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "15%",
                    right: "9%",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => setFlag(!flag)}
                >
                  {flag ? <FaRegEye /> : <FaRegEyeSlash />}
                </i>
                {passwordError &&<div  
                  className="password_validatior">
                    <ul>  {passwordError?.map((item,index)=>{
                      return(
                        <li>{item}</li>
                      )
                    })}</ul>

                 

                </div>}
                <Form.Control.Feedback type="invalid">
                  Please enter a valid Password
                </Form.Control.Feedback>
              </FloatingLabel>
             
            </Form.Group>
            <Form.Group controlId="validationCustom05">
              <FloatingLabel
                controlId="floatingInput"
                label="Confirm Password"
                className="mb-3"
              >
                <Form.Control
                  type={flag1 ? "text" : "password"}
                  required
                  style={{ width: "100%", position: "relative" }}
                />
                <i
                  style={{
                    position: "absolute",
                    top: "15%",
                    right: "9%",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => setFlag1(!flag1)}
                >
                  {flag1 ? <FaRegEye /> : <FaRegEyeSlash />}
                </i>
                <Form.Control.Feedback type="invalid">
                  Please enter a valid Password.
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button type="submit" variant="warning" style={{width:"100px"}}>
                {!isSubmit ?  <FaLongArrowAltRight />:<Spin spinning={isSubmit}/>}
              
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Row>
  );
};

export default Resetpass;
