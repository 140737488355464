import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  Select,
  Input,
  Typography,
  Modal,
  message,
  Timeline,
  Image
} from "antd";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import {
  getrequest,
  postrequest,
  putrequest,
} from "../../Middleware/managerequest";
import { useParams } from "react-router-dom";
import {
  BookOutlined,
  EditOutlined,
  ExportOutlined,
  GithubOutlined,
  InfoCircleOutlined,
  LinkedinOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { SiMicrosoftexcel } from "react-icons/si";
import styles from "./styles/overview_details.module.css";
const { Search, TextArea } = Input;
const { Text } = Typography;

const statusOptions = [
  { value: "no_data", label: "No Data" },
  { value: "active", label: "Active" },
  { value: "not_active", label: "Not Active" },
  { value: "willing_to_shift", label: "Willing To Shift" },
  { value: "shifted", label: "Shifted" },
  { value: "dropped", label: "Dropped" },
  { value: "collection", label: "Collection" },
  { value: "paid_termination", label: "Paid Termination" },
  { value: "placement", label: "Placement" },
  { value: "placed", label: "Placed" },
];

const OverviewTable = ({ changeTab }) => {
  const [userDetailsModalVisible, setUserDetailsModalVisible] = useState(false);
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);

  const { id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const { user } = useSelector((state) => state.Data);
  const [searchText, setSearchText] = useState(""); // State to store search text
  const [hidden, setHidden] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [Users, setUsers] = useState([]);
  const [addmember, setAddmember] = useState(false);
  const [isloading, setIsloading] = useState(true);
  const [modalopen, setModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [StatusData, setStatusData] = useState(null);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [openhistory, setOpenHistory] = useState(false);
  const [openChangename, setOpenChageName] = useState(false);
  const [SelectedUser, setSelectedUser] = useState([]);
  const [finaldata, setFinaldata] = useState([]);
  const [Exceluser, setExceluser] = useState([]);
  const [uniqueData, setUniquedata] = useState(0);
  const [alreadypresent, setAlreadyPresent] = useState([]);
  const [emptydata, setEmptydata] = useState([]);
  const [Members,setMembers]=useState([])

  const Fetcher = useCallback(
    (val) => {
      getrequest(`/groupoverview/${id}`)
        .then((res) => {
          setIsloading(false);
          setDataSource(res.data?.overview);
          setMembers(res.data?.Members)
           setIsloading(false); 
        setAddmember(false)

          if (val) {
            messageApi.open({
              key,
              type: "success",
              content: "Changed  Successfully",
            });
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    },
    [id, messageApi]
  );

  useEffect(() => {
    Fetcher();
  }, [Fetcher]);

  const filteredDataSource = dataSource
    .filter((item) =>
      item.Name.toLowerCase().includes(searchText.toLowerCase())
    )
    .map((item) => {
      return {
        key: item.Email,
        ...item,
      };
    });

  const GetGroups = () => {
    getrequest(`/home/getgroups/${user?.subdomain}`)
      .then((res) => {
        let newoptions = res.data.map((item) => {
          return {
            value: item.id,
            label: item.GroupName,
          };
        });

        setOptions(newoptions);
      })
      .catch((err) => console.log(err));
  };

  const HandleStatusSubmit = (val) => {
 
    let obj = {
      status: val.status ?? selectedStatus?.status,
      details: StatusData,
      username: val?.value ?? selectedStatus?.value,
    };

    messageApi.open({
      key,
      type: "loading",
      content: "Changing Status...",
    });
    setDisabled(true);
    putrequest(`/manage_status/${id}/${user?.Email}`, obj)
      .then((res) => {
        setModalOpen(false);
        Fetcher(true);
        setDisabled(false);
        setStatusData(null);
      })
      .catch((err) => {
        setStatusData(null);
        setModalOpen(false);
        setDisabled(false);
        messageApi.open({
          key,
          type: "error",
          content: err?.response?.data ?? "error while changing the status",
        });
      });
  };

  const HandleChangeName = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Changing Name...",
    });
    setDisabled(true);
    putrequest(`/changeusername/${id}/${SelectedUser[0]?.Email}`, StatusData)
      .then((res) => {
        setOpenChageName(false);
        setStatusData(null);
        Fetcher(true);
        setDisabled(false);
      })
      .catch((err) => {
        setDisabled(false);
        setStatusData(null);
        messageApi.open({
          key,
          type: "error",
          content: "error while changing the name",
        });
      });
  };

  //     Handle Status
  const HandleStatus = (status, email) => {
    setSelectedStatus({ value: email, status: status });
    setStatusData(null);
    if (
      [
        "willing_to_shift",
        "collection",
        "paid_termination",
        "shifted",
        "not_active",
        "dropped",
      ].includes(status.value)
    ) {
      setModalOpen(true);
      if (status?.value === "shifted") {
        GetGroups();
      }
    } else {
      HandleStatusSubmit({ value: email, status: status });
    }
  };

  const ConvertToHours = (val) => {
    if (val === 0) {
      return 0;
    } else if (val <= 60) {
      return val + " min(s)";
    } else {
      const hours = Math.floor(val / 60);
      const remainingMinutes = val % 60;
      return `${hours} hr ${remainingMinutes} min(s)`;
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      align: "center",
      width: 160,
      fixed: "left",
      render: (text, record) => (
        <span
          className={styles.fullname}
          onClick={() => {
            setSelectedUserDetails(record?.PersonalDetails);
            setUserDetailsModalVisible(true);
          }}
          title="Click to View"
        >
          {text}
        </span>
      ),
    },

    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      align: "center",
      fixed: "left",
      width: 180,
    },

    {
      title: (
        <span>
          <label className="mx-2">Assignments</label>{" "}
          <ExportOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeTab(4);
            }}
          />
        </span>
      ),
      children: [
        {
          title: (
            <Text ellipsis={{ tooltip: "Total Time Spend" }}>
              Total Time Spend
            </Text>
          ),
          dataIndex: ["Assignments", "Total_Time_Spend"],
          key: "Total_Time_Spend",
          align: "center",
          render: (val, record) => {
            return ConvertToHours(val);
          },
        },
        {
          title: "Attempted",
          dataIndex: ["Assignments", "Attempted"],
          key: "Attempted",
          align: "center",
        },
        {
          title: (
            <Text ellipsis={{ tooltip: "Total Questions" }}>
              Total Questions
            </Text>
          ),
          dataIndex: ["Assignments", "Total_Questions"],
          key: "Total_Questions",
          align: "center",
        },
      ],
    },
    {
      title: (
        <span>
          <label className="mx-2">Attendance</label>{" "}
          <ExportOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              changeTab(2);
            }}
          />
        </span>
      ),
      children: [
        {
          title: (
            <Text ellipsis={{ tooltip: "Total Sessions" }}>Total Sessions</Text>
          ),
          dataIndex: ["Attendance", "Total_Sessions"],
          key: "Total_Sessions",
          align: "center",
        },
        {
          title: (
            <Text ellipsis={{ tooltip: "Present Sessions" }}>
              Present Sessions
            </Text>
          ),
          dataIndex: ["Attendance", "Present_Sessions"],
          key: "Present_Sessions",
          align: "center",
        },
        {
          title: "Percentage",
          dataIndex: ["Attendance", "Percentage"],
          key: "Percentage",
          align: "center",
          render: (percentage) => `${percentage} %`,
        },
      ],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      fixed: "right",
      width: 160,
      hidden: hidden,
      render: (text, record) => {
        return (
          <div className="d-flex">
            <Select
              value={text}
              onChange={(e) => {
                HandleStatus(e, record.key);
              }}
              options={statusOptions}
              style={{ width: "80%" }}
              labelInValue={true}
            />
            {record?.History.length > 0 && (
              <InfoCircleOutlined
                onClick={() => {
                  setOpenHistory(true);
                  setSelectedUser([record]);
                }}
                style={{ fontSize: "18px", cursor: "pointer" }}
                title="Click to view"
                className="mx-2"
              />
            )}
          </div>
        );
      },
      filters: [
        { value: "No Data", text: "No Data" },
        { value: "Active", text: "Active" },
        { value: "Not Active", text: "Not Active" },
        { value: "Willing To Shift", text: "Willing To Shift" },
        { value: "Shifted", text: "Shifted" },
        { value: "Dropped", text: "Dropped" },
        { value: "Collection", text: "Collection" },
        { value: "Paid Termination", text: "Paid Termination" },
        { value: "Placement", text: "Placement" },
        { value: "Placed", text: "Placed" },
      ],

      onFilter: (value, record) => {
        return record.status.indexOf(value) === 0;
      },
    },
    {
      title: "Edit",
      align: "center",
      fixed: "right",
      hidden: hidden,
      width: 80,
      render: (_, record) => (
        <EditOutlined
          onClick={() => {
            setOpenChageName(true);
            setSelectedUser([record]);
          }}
          style={{ cursor: "pointer" }}
          title="Edit Name"
        />
      ),
    },
  ];

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };
  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const DateToNormal = (val) => {
    if (val) {
      const date = new Date(val);
      // Extract the day, month, and year components
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");

      const formattedDate = `${day}-${month}-${year}, ${hours}:${minutes}`;
      return formattedDate;
    }
  };

  const LinkOpener = (url) => {
    if (url !== "" && url) {
      window.open(url, "_blank");
    } else {
      messageApi.info({
        content: "No Link Found",
      });
    }
  };

  const handlechange = (e) => {
    setSelectedUser(e);
  };

  // Fetch Users
  const GetUsers = () => {
    getrequest(`/home/getallusers/${user?.subdomain}/${id}`)
      .then((res) => {
        let arr = [];
        res.data.map((item, index) => {
          if (item?.name !== "group") {
            let obj = {
              value: item.Email,
              label: item.Name,
              name: item.name === undefined ? "users" : item.name,
            };
            arr.push(obj);
          }

          return true;
        });
        setUsers(arr);
        setAddmember(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Add users Submit options
  const Submit = () => {
    let newSelected = SelectedUser.map((item) => {
      let find = Users.find((ite) => item === ite.value) ?? {
        value: item,
        label: item,
        name: "users",
      };
      return find;
    });

    let duplicate = [...newSelected, ...finaldata]
      .filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.value.toLowerCase().trim("") ===
              item.value.toLowerCase().trim("")
          )
      )
      .map((item) => {
        item.value = item.value.toLowerCase().trim("");
        return item;
      });

    if (duplicate.length > 0) {
      let obj = {
        users: duplicate,
        role: "users",
      };
      setIsloading(true);
  messageApi.loading({
          key,
          content:"Adding to this group"
        })
      postrequest(`/home/addusers/${user.subdomain}/${id}`, obj)
        .then(() => {
          Fetcher()
        messageApi.success({
          key,
          content:"Successfully added"
        })
         setUsers([])
        setSelectedUser([])
       
        })
        .catch((err) => { 
          messageApi.error({
            key,
            content:"Error while adding user"
          })
         console.log(err)
          setUsers([])
        setSelectedUser([])
        setIsloading(false); 
        setAddmember(false)
        });
       

    }
  };

   // On Modal Cancel
   const OnCancel = () => {
    setAddmember(false);
    setSelectedUser([]);
    setAlreadyPresent([]);
    setEmptydata([]);
    setUniquedata(0);
    setExceluser([]);
    setFinaldata([]);
    setUsers([])
  };

  // Handle Excel File

  const findUniqueObjects = (arr1) => {
    let updateone = arr1.filter(
      (item, index, self) =>
        item.label !== undefined && item.value !== undefined
    );
    setEmptydata(updateone);

    let duplicate = updateone.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );

    
    let AlreadyNotPresent = [];
    if (addmember) {
      const AlreadyPresent = duplicate.filter((item) =>
        Members.some(
          (it) => it.value.toLowerCase() === item.value.toLowerCase()
        )
      );

      AlreadyNotPresent = duplicate.filter((item) =>
      Members.every(
          (it) => it.value.toLowerCase() !== item.value.toLowerCase()
        )
      );
      setAlreadyPresent(AlreadyPresent);
    } else {
      AlreadyNotPresent = duplicate;
      setFinaldata(duplicate);
    }
    setUniquedata(duplicate);
    setFinaldata(AlreadyNotPresent);
  };

  // Convert sheet to array
  const convertInObject = (array) => {
    let arrayOfObjects = [];
    for (let i = 0; i < array.length; i++) {
      let innerArray = array[i];
      let obj = {
        label: innerArray[0],
        value: innerArray[1],
        phone: innerArray[2],
        name: "users",
      };
      arrayOfObjects.push(obj);
    }
    

    setExceluser(arrayOfObjects);
    findUniqueObjects(arrayOfObjects);
  };

  // Read The File
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        // Assuming the first sheet is the one you want to convert
        const sheetName = workbook.SheetNames[0];
        const excelArray = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { header: 1 }
        );
        let newflt = excelArray.filter((item) => item.length !== 0);
        // Exclude the header row

        convertInObject(newflt.slice(1));
        event.target.value = "";
      };
      reader.readAsBinaryString(file);
    }
  };

  return (
    <>
      {contextHolder}
      <div className="d-flex justify-content-between">
        <Search
          placeholder="Search by name"
          allowClear
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: 200, marginBottom: 16, alignSelf: "end" }}
        />
        <span>
          <label style={{ cursor: "pointer" }} onClick={() => GetUsers()} className="mx-3" ><PlusCircleOutlined/> Add Member</label>
          <label
            style={{ cursor: "pointer" }}
            onClick={() => setHidden(!hidden)}
          >
            <u>{hidden ? "Show hidden" : "Hide"} columns</u>
          </label>
        </span>
      </div>

      <Table
        dataSource={filteredDataSource}
        bordered
        scroll={{
          x: "calc(700px + 50%)",
          y: "55vh",
        }}
        columns={columns}
        responsive={true}
        loading={isloading}
        // rowSelection={rowSelection}
        size="small"
      />

      {/* Handle Status Modal */}

      <Modal
        open={modalopen}
        title={`Change Status`}
        maskClosable={false}
        onCancel={() => {
          setModalOpen(false);
          setStatusData(null);
          setSelectedStatus("");
        }}
        onOk={() => HandleStatusSubmit(selectedStatus)}
        cancelButtonProps={{
          disabled: disabled,
          loading: disabled,
        }}
        okButtonProps={{
          disabled: disabled,
          loading: disabled,
        }}
      >
        {[
          "willing_to_shift",
          "collection",
          "paid_termination",
          "shifted",
          "not_active",
          "dropped",
        ].includes(selectedStatus?.status?.value) && (
          <section>
            {selectedStatus?.status?.value === "shifted" && (
              <div>
                <label>Shifted to batch ? : </label>
                <Select
                  labelInValue
                  className="mx-2"
                  onChange={(val) => {
                    setStatusData((prev) => ({ ...prev, shiftedbatch: val }));
                  }}
                  size="small"
                  style={{ width: "200px" }}
                  options={options}
                />
              </div>
            )}
            {selectedStatus?.status?.value === "not_active" && (
              <div>
                <label>Not active from ?</label>
                <input
                  name="FromDate"
                  onChange={(val) => {
                    setStatusData((prev) => ({
                      ...prev,
                      [val.target.name]: val.target.value,
                    }));
                  }}
                  className={styles.datepicker}
                  type="date"
                />
              </div>
            )}
            {selectedStatus?.status?.value === "dropped" && (
              <div>
                <label>Dropped On ?</label>
                <input
                  name="DroppedOn"
                  onChange={(val) => {
                    setStatusData((prev) => ({
                      ...prev,
                      [val.target.name]: val.target.value,
                    }));
                  }}
                  className={styles.datepicker}
                  type="date"
                />
              </div>
            )}
            <div>
              <label>Kindly mention the reason.</label>
              <br />
              <TextArea
                name="Reason"
                onChange={(val) => {
                  setStatusData((prev) => ({
                    ...prev,
                    [val.target.name]: val.target.value,
                  }));
                }}
                autoSize={{ minRows: 2, maxRows: 5 }}
              ></TextArea>
            </div>
          </section>
        )}
      </Modal>

      {/* History Modal */}
      <Modal
        open={openhistory}
        footer={null}
        title={`History of ${SelectedUser[0]?.Name ?? ""}`}
        width={800}
        onCancel={() => {
          setOpenHistory(false);
          setSelectedUser([]);
        }}
        centered
      >
        <Timeline
          className="pt-4"
          style={{ overflow: "auto", height: "70vh" }}
          items={
            SelectedUser.length > 0 &&SelectedUser[0]?.History?.length>0&&
            SelectedUser[0]?.History.map((item) => {
              return {
                children: (
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-between">
                      <label>{item.operation}</label>
                      <label className="w-25">{DateToNormal(item?.date)}</label>
                    </div>
                    {item?.DroppedOn && (
                      <label>Dropped On {item.DroppedOn}</label>
                    )}
                    {item?.FromDate && (
                      <label>Not active from {item?.FromDate} </label>
                    )}
                    {item?.shiftedbatch && (
                      <label>
                        Shifted to <i>{item.shiftedbatch?.label}</i>{" "}
                      </label>
                    )}
                    {item.from_Name && (
                      <label>
                        Shifted From{" "}
                        <i>
                          <u>{item.from_Name}</u>
                        </i>
                      </label>
                    )}
                    {item?.Reason && <p>{item.Reason}</p>}
                  </div>
                ),
              };
            })
          }
        />
      </Modal>
      <Modal
        open={openChangename}
        onOk={() => HandleChangeName()}
        onCancel={() => setOpenChageName(false)}
        title="Change the Name"
        cancelButtonProps={{
          disabled: disabled,
          loading: disabled,
        }}
        okButtonProps={{
          disabled: disabled,
          loading: disabled,
        }}
      >
        <input
          type="text"
          onChange={(e) => {
            setStatusData({ name: e.target.value });
          }}
          className={styles.namefield}
        />
      </Modal>
      <Modal
        open={userDetailsModalVisible}
        onCancel={() => setUserDetailsModalVisible(false)}
        title="Personal Details"
        footer={null}
        width={800}
      >
        {/* Personal Detail Section */}
        <section className={styles.personal_details}>
          <div>
            {selectedUserDetails?.Personal_Details?.imagesurl ? (
              <Image
                className={styles.profile_photo}
                src={selectedUserDetails?.Personal_Details?.imagesurl ?? ""}
                width={90}
                height={90}
              />
            ) : (
              <label>No Profile Photo</label>
            )}
          </div>
          <div className={styles.rightside_personal}>
            <h5>
              {selectedUserDetails?.Personal_Details?.firstName}{" "}
              {selectedUserDetails?.Personal_Details?.lastName}
            </h5>
            <label>{selectedUserDetails?.Personal_Details?.email}</label>
            <label>{selectedUserDetails?.Personal_Details?.phone}</label>
            <span style={{ fontSize: "20px" }}>
              {" "}
              <LinkedinOutlined
                title={
                  selectedUserDetails?.Personal_Details?.linkedIn
                    ? selectedUserDetails?.Personal_Details?.linkedIn
                    : "No Link found"
                }
                onClick={() =>
                  LinkOpener(selectedUserDetails?.Personal_Details?.linkedIn)
                }
              />
              <GithubOutlined
                className="mx-2"
                title={
                  selectedUserDetails?.Personal_Details?.gitHub
                    ? selectedUserDetails?.Personal_Details?.gitHub
                    : "No Link found"
                }
                onClick={() =>
                  LinkOpener(selectedUserDetails?.Personal_Details?.gitHub)
                }
              />
              <BookOutlined
                className="mx-1"
                title={
                  selectedUserDetails?.Personal_Details?.resumeLink
                    ? selectedUserDetails?.Personal_Details?.resumeLink
                    : "No resume found"
                }
                onClick={() =>
                  LinkOpener(selectedUserDetails?.Personal_Details?.resumeLink)
                }
              />
            </span>
          </div>
        </section>
        <hr style={{ width: "100%" }} />
        <section className="d-flex justify-content-around flex-wrap">
          {/* Address Section */}
          <section style={{ borderRight: "1px solid gray", width: "30%" }}>
            <b>
              <label>Address</label>
            </b>

            <div>
              <label>
                {selectedUserDetails?.Personal_Details?.address?.firstLine}
              </label>
              <label>
                {selectedUserDetails?.Personal_Details?.address?.secondLine}
              </label>
              <br />
              <label>
                {selectedUserDetails?.Personal_Details?.address?.city}
              </label>
              <br />
              <label>
                {selectedUserDetails?.Personal_Details?.address?.state}
              </label>
            </div>
          </section>
          {/* Education Section  */}
          <section style={{ borderRight: "1px solid gray", width: "30%" }}>
            <b>
              <label>Education Details</label>
            </b>
            <ol style={{ paddingLeft: "13px" }}>
              {selectedUserDetails?.Education.map((item, ind) => {
                return (
                  <li key={ind}>
                    <label>
                      {item?.title}, {item?.year}
                    </label>
                  </li>
                );
              })}
            </ol>
          </section>
          {/* Experience Section */}
          <section className="d-flex flex-column">
            <b>
              <label>Exprerience Details</label>
            </b>
            <ol style={{ paddingLeft: "13px" }}>
              {selectedUserDetails?.Experience.map((item, ind) => {
                return (
                  <li>
                    <label>
                      {item?.title}, {item?.year}
                    </label>
                  </li>
                );
              })}
            </ol>
          </section>
        </section>
      </Modal>

      <Modal
        title="Add Member"
        open={addmember}
        onCancel={() => {
        OnCancel()
        }}
        onOk={() => Submit()}
        okButtonProps={{ loading: isloading, disabled: isloading }}
        cancelButtonProps={{ loading: isloading, disabled: isloading }}
      >
        <>
          <div className="d-flex justify-content-between align-items-center">
            {" "}
            <Select
              mode="tags"
              style={{
                width: "90%",
                zIndex: "999",
              }}
              onChange={handlechange}
              tokenSeparators={[","]}
              options={Users}
              placeholder="Enter the email with ',' separated"
              value={SelectedUser}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
            <input
              type="file"
              id="fileInput"
              style={{ display: "none" }}
              onChange={handleFileUpload}
              className="pt-2"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ></input>
            <label
              htmlFor="fileInput"
              style={{ cursor: "pointer" }}
              className={styles.uploadfile}
              title="Upload Excel"
            >
              <SiMicrosoftexcel />
            </label>
          </div>
          <div className="d-flex justify-content-between">
            <label>Total Excel Records: {Exceluser.length}</label>
            <label className="mx-5 px-5">
              Final Records: {[...finaldata, ...SelectedUser].length}
            </label>
          </div>
          <div className="d-flex flex-column">
            {alreadypresent.length > 0 && (
              <label>
                {alreadypresent.length} user(s) are already present.
              </label>
            )}

            {Exceluser.length - emptydata.length > 0 && (
              <label>
                {Exceluser.length - emptydata.length} document(s) have empty
                fields.
              </label>
            )}

            {emptydata.length - uniqueData.length > 0 && (
              <label>
                {emptydata.length - uniqueData.length} document(s) are
                duplicates.
              </label>
            )}

            {SelectedUser.length > 0 && (
              <label>
                Do you want to add {[...finaldata, ...SelectedUser].length}{" "}
                user(s)?
              </label>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};

export default OverviewTable;
