import React, { useState } from "react";

import { Modal, Button, Form } from "react-bootstrap";
import { postrequest } from "../../../Middleware/managerequest";
import { useSelector, useDispatch } from "react-redux";
import { replaceExperience } from "../../../../store/ExperienceSlice";

function AddExperienceModal({ show, setShow }) {
  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch();
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [expEdit, setExpEdit] = useState({
    title: "",
    description: "",
    start: "",
    end: "",
    currentlyWorking: "",
  });

  const handleClose = () => setShow(false);
  const handleSave = () => {
    // Validate the inputs
    if (!expEdit.title || !expEdit.description) {
      alert("Please fill in all required fields.");
      return;
    }

    // Calculate the experience duration
    let experienceString = "";
    if (expEdit.start && expEdit.end) {
      const start = new Date(expEdit.start);
      const end = expEdit.currentlyWorking ? new Date() : new Date(expEdit.end);
      const years = end.getFullYear() - start.getFullYear();
      const months = end.getMonth() - start.getMonth();
      experienceString = `${years} Years ${months} Months`;
    } else {
      experienceString = "Working";
    }


    // Log the calculated experience
    setExpEdit({ ...expEdit, title: `${expEdit.title} - ${currentlyWorking?"Working":experienceString}` });

    postrequest(
      `/api/v1/user/personal_details/${user?.Email}/?sec=experience&&operation=add`,
      { ...expEdit, title: `${expEdit.title} - ${currentlyWorking?"Working":experienceString}` }
    )
      .then((res) => {
      
        dispatch(replaceExperience(res.data.Experience));
        setExpEdit({
          title: "",
          description: "",
          start: "",
          end: "",
          currentlyWorking: false,
        });
      })
      .catch((err) => {
        console.error(err);
        setExpEdit({
          title: "",
          description: "",
          start: "",
          end: "",
          currentlyWorking: false,
        });
      });
    // Call the onSave function with the experience object
    // onSave(experience);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              value={expEdit.description}
              onChange={(e) =>
                setExpEdit({ ...expEdit, description: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="position">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              value={expEdit.title}
              onChange={(e) =>
                setExpEdit({ ...expEdit, title: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={expEdit.start}
              onChange={(e) => {
                setExpEdit({ ...expEdit, start: e.target.value });
              }}
            />
          </Form.Group>
          <Form.Group controlId="endDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={expEdit.end}
              onChange={(e) => {
                setExpEdit({ ...expEdit, end: e.target.value });
              }}
              disabled={currentlyWorking}
              min={expEdit.start}
            />
          </Form.Group>
          <Form.Group controlId="currentlyWorking">
            <Form.Check
              type="checkbox"
              label="Currently Working Here"
              style={{cursor:"pointer"}}
              checked={expEdit.currentlyWorking}
              onChange={(e) =>{
                setExpEdit({
                  ...expEdit,
                  currentlyWorking: e.target.checked,
                })
                setCurrentlyWorking(e.target.checked)
              }
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddExperienceModal;
