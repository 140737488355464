import React, { useState } from "react";
import styles from "./warning.module.css";
import { IoIosCloudDone } from "react-icons/io";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Rate } from "antd";
import { postrequest } from "../../Middleware/managerequest";
const ThankYou = ({ Settings }) => {
  const data = useSelector((state) => state.Data);
  const [reports, setReports] = useState({ Rating: 5, issue: "" });
  const { user } = data;
  const { id } = useParams();
  const navigate = useNavigate();
  const handleSubmit = () => {
    let obj = {
      email: user?.Email,
      name: user?.FullName,
      rating: reports.Rating,
      issue: reports.issue,
      id: id,
    };
    postrequest(`/user/reportissue/${user?.subdomain}`, obj)
      .then((res) => {
        navigate("/user");
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="d-flex justify-content-center">
      <div className={styles.warning_main}>
        <center>
          <h2 className={styles.heading}>Thank You</h2>
          {/* <label className={styles.greeting}> Hello {user?.FullName}</label> */}
          {/* <br /> */}
          <IoIosCloudDone className={styles.doneicon} />
          <br />

          <label className={styles.greeting}>
            {Settings?.autofeedback ?? false ? (
              <>
                We are saving your work .<br /> You can see the result. after{" "}
                <b>15 Min</b>.
              </>
            ) : (
              <>Your Mock or Test is successfully submitted.</>
            )}
          </label>
          <br />
          <div className="d-flex flex-column mt-2">
            <div className="d-flex flex-column mt-2">
              <label>How is your experience ?(optional)</label>{" "}
              <Rate
                onChange={(val) =>
                  setReports((val1) => ({ ...val1, Rating: val }))
                }
              />
            </div>

            <div className="d-flex flex-column align-items-center mt-2">
              <label>Report Issue Here (if any)</label>
              <textarea
                cols={5}
                rows={4}
                style={{ width: "300px", borderRadius: "5px" }}
                onChange={(val) =>
                  setReports((val1) => ({ ...val1, issue: val.target.value }))
                }
              />
            </div>
          </div>
          <div className="d-flex justify-content-between w-25 align-items-center">
            {" "}
            <label
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => navigate("/user")}
            >
              Home
            </label>
            <Button
              style={{ width: "100px" }}
              onClick={() => handleSubmit()}
              className="mt-3"
            >
              Submit
            </Button>
          </div>
        </center>
      </div>
    </div>
  );
};

export default ThankYou;
