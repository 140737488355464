import React, { useCallback, useEffect, useState } from "react";
import { Drawer, message } from "antd";
import {
  UnlockOutlined,
  UserOutlined,
  ExportOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Menu, Modal, Avatar } from "antd";
import { useMsal } from "@azure/msal-react";
import styles from "./DashboardSlider.module.css";
import {
  ContainerOutlined,
  PieChartOutlined,
  UsergroupAddOutlined,
  HomeOutlined,
  UnorderedListOutlined,
  ReconciliationOutlined,
  SettingFilled,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  setuser,
  setAdmin,
  setIslogin,
  setIssubscribed,
  setIsSuperAdmin,
  setCategory,
} from "../../store/Reducer";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { getrequest, postrequest } from "../Middleware/managerequest";

const ProfileSlider = ({
  setOpenDwa,
  openDwa,
  menuItems,
  setMenuKey,
  handlerClick,
  imageurl,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [categoryList, setCategoryList] = useState([]);
  const [Segment, setSegment] = useState([]);
  const [IsSegment, setIsSegment] = useState(false);
  const { admin, IsSuperAdmin, user, clientdetails, access, category } =
    useSelector((state) => state.Data);

  const handleclearsignout = () => {
    let obj = null,
      isUsers = false,
      isGroup = false,
      ClientName = "Digikull",
      isSubscribed = false,
      MockCount = 0;
    dispatch(setuser(obj));
    dispatch(setAdmin(false));
    dispatch(setIslogin(false));
    dispatch(setIsSuperAdmin(null));
    localStorage.removeItem("FullName");
    localStorage.removeItem("my_profile_image");
    dispatch(
      setIssubscribed({ isUsers, isGroup, ClientName, isSubscribed, MockCount })
    );
    navigate("/");
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.remove("digikull_local")
      : Cookies.remove("unoprep", { domain: ".unoprep.com" });
    sessionStorage.clear();
  };
  const updateCategory = () => {
    getrequest(`/viewmocks/${user?.subdomain}/?pageno=${"1"}`)
      .then((res) => {
        dispatch(setCategory(res.data?.allcategory?.Category));
      })
      .catch((err) => {
        notificationmessage("server error code-174");
      });
  };
  useEffect(() => {
    if (user) {
      updateCategory();
    }
  }, [user]);
  useEffect(() => {
    let list = category.map((ele) => {
      return {
        isDisabled: true,
        label: ele,
        value: ele,
      };
    });
    setCategoryList(list);
  }, []);
  const signout = async () => {
    if (accounts.length > 0) {
      instance.logoutPopup().then(() => {
        handleclearsignout();
      });
    } else if (accounts.length === 0) {
      handleclearsignout();
    }
  };
  const notificationmessage = useCallback(
    (val, err) => {
      messageApi.open({
        type: err,
        content: val,
      });
    },
    [messageApi]
  );
  // Create new segment
  const createNewSegment = () => {
    postrequest(`/createnewcat/${user?.subdomain}`, {
      newcat: Segment?.label,
    })
      .then((res) => {
        setIsSegment(false);
        updateCategory();
        notificationmessage("New Segement created successfully.", "success");
      })
      .catch((err) => {
        setIsSegment(false);
        notificationmessage("Error while creating createNewSegment", "error");
      });
  };
  // Sidebar Navigation
  const handleNavigate = (e) => {
    if (
      [
        "createmock",
        "managegroups",
        "questions",
        "manageusers",
        "my_organizations",
        "course",
      ].includes(e.key)
    ) {
      navigate(`/${e.key}`);
    } else if (e.key === "Create Segment") {
      setIsSegment(true);
    } else if (e.key === "dashboard") {
      navigate("/admin");
    } else {
      setOpenDwa(false);
      navigate(`/predefinetest?category=${e.key}`);
    }
  };
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const menuItemsToDisplay = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "dashboard",
      <HomeOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),
    getItem(
      <span className={styles.menulabel}>Predefined Test</span>,
      "section",
      <PieChartOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      [
        ...category?.slice(1)?.map((ele, index) => {
          return getItem(ele, ele);
        }),
        getItem(
          <span className={styles.menulabel}>Create Segment</span>,
          "Create Segment",
          <PlusCircleOutlined
            style={{ fontWeight: "600", fontSize: "17px" }}
          />,
          null,
          null,
          true
        ),
      ]
    ),

    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
    access &&
      access?.role === "Admin" &&
      getItem(
        <span className={styles.menulabel}>Manage Users</span>,
        "manageusers",
        <SettingFilled style={{ fontWeight: "600", fontSize: "17px" }} />,
        null,
        null
      ),
    (clientdetails?.isgroup ?? false) &&
      getItem(
        <span className={styles.menulabel}>Batch</span>,
        "managegroups",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    "main" === user?.subdomain &&
      getItem(
        <span className={styles.menulabel}>Manage Organizations</span>,
        "my_organizations",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    getItem(
      <span className={styles.menulabel}>Courses</span>,
      "course",
      <ReconciliationOutlined
        style={{ fontWeight: "600", fontSize: "17px" }}
      />,
      null,
      null
    ),
  ];
  // For Menu
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "black" : provided.color,
    }),
  };
  return (
    <div>
      <Drawer
        title="Your Profile"
        placement={window.innerWidth < 550 ? "left" : "right"}
        style={{ backdropFilter: "blur(10px)" }}
        onClose={() => setOpenDwa(false)}
        open={openDwa}
        closeIcon={true}
        width={window.innerWidth < 550 ? "90%" : 400}
      >
        {contextHolder}
        <div className="userProfile">
          <div className="profile1">
            <Avatar
              src={imageurl ?? ""}
              icon={<UserOutlined />}
              style={{ width: "64px", height: "64px" }}
              size={64}
            />
            <label>
              <label>
                <label className="mx-2">{user?.FullName} </label>{" "}
                {!admin && (
                  <ExportOutlined
                    style={{ fontSize: "15px", cursor: "pointer" }}
                    onClick={() => navigate("/profile")}
                  />
                )}{" "}
              </label>
              <span>{user?.Email}</span>
            </label>
          </div>
        </div>
        <div className={styles.menuItem}>
          <Menu
            onClick={(e) => {
              if (setMenuKey) {
                setMenuKey(e.key);
              }
              if (handlerClick) {
                handlerClick(e);
              } else {
                handleNavigate(e);
              }
            }}
            mode="inline"
            theme="light"
            items={menuItems ? menuItems : menuItemsToDisplay}
          />
        </div>
        {!admin && (
          <div className={styles.submenu_user}>
            <span
              onClick={() => navigate("/profile")}
              className={styles.submenu_profile}
            >
              <UserOutlined style={{ fontSize: "20px" }} />{" "}
              <label className={styles.menu_label}>My Profile</label>
            </span>
          </div>
        )}
        {admin && IsSuperAdmin && (
          <div className={styles.submenu_user}>
            <span
              onClick={() => navigate("/settings")}
              className={styles.submenu_profile}
            >
              <SettingOutlined style={{ fontSize: "20px" }} />{" "}
              <label className={styles.menu_label}>Manage Organization</label>
            </span>
          </div>
        )}

        <main
          onClick={() => {
            signout();
            setOpenDwa(false);
          }}
          className="signoutbtn"
        >
          <span>
            <UnlockOutlined />
          </span>

          <label>Sign out</label>
        </main>
        {/* Add Segments */}
        <Modal
          title="Create Segment"
          onCancel={() => setIsSegment(false)}
          open={IsSegment}
          onOk={() => createNewSegment()}
          centered
          width={500}
          maskClosable={false}
        >
          <CreatableSelect
            options={categoryList}
            onChange={(e) => setSegment(e)}
            placeholder="Enter new segment name"
            isClearable={true}
            closeMenuOnSelect={true}
            isSearchable={true}
            isMulti={false}
            className="Add_user"
            styles={customStyles}
          />
        </Modal>
      </Drawer>
    </div>
  );
};

export default ProfileSlider;
