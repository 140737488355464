import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Tag, Modal, Table } from "antd";
import { useSelector } from "react-redux";
import { FaRegUserCircle } from "react-icons/fa";
// import treeDotsMenu from "../../icons/treeDotsMenu.svg";
import { toast, ToastContainer } from "react-toastify";
import styles from "./StudentsGivenMock.module.css";
import Spinner from "react-bootstrap/Spinner";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import restest from "../../icons/retest.svg";
import { useNavigate } from "react-router-dom";
// import Form from "react-bootstrap/Form";
import revaluationPic from "../../icons/revaluation.svg";
import { getrequest } from "../Middleware/managerequest";
import pdfIcon from "../../icons/pdficon.png";
const StudentGivenMock = () => {
  const { id } = useParams();
  const { user } = useSelector((state) => state.Data);
  const [MockData, setMockData] = useState(null);
  const [Answers, setAnswers] = useState(null);
  const [finalMainTag, setFinalMainTag] = useState([]);
  const [finalSubTag, setfinalSubTag] = useState([]);
  const [newData, setNewData] = useState([]);
  const [duration, setDuration] = useState(null);
  const [revaluation, setRevaluation] = useState(false);
  const [IsData, setIaData] = useState(false);
  const [result, setResult] = useState(0);
  const [QNO, setQno] = useState(0);
  const [show, setShow] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const nevigate = useNavigate();
  const [selectedpage, setSelectedPage] = useState("main");
  const [showVideo, setShowVideo] = useState(false); // State to manage video visibility
  const videoRef = useRef(null);

  const handlePlayClick = () => {
    setShowVideo(true); // Show the video player when the button is clicked
    if (videoRef.current) {
      videoRef.current.play(); // Optionally start playing the video immediately
    }
  };
  const handleUserData = useCallback(
    (value) => {
      let find = value.Attempted.find((item) => item.Email === user?.Email);
      deStuchureData(find);
      setAnswers(find);
      const difference = +new Date(find.endtime) - +new Date(find.starttime);
      let timeleft = {};
      if (difference > 0) {
        timeleft = {
          hours:
            Math.floor(difference / (1000 * 60 * 60)) < 10
              ? "0" + Math.floor(difference / (1000 * 60 * 60))
              : Math.floor(difference / (1000 * 60 * 60)),
          minutes:
            Math.floor((difference / 1000 / 60) % 60) < 10
              ? "0" + Math.floor((difference / 1000 / 60) % 60)
              : Math.floor((difference / 1000 / 60) % 60),
          seconds:
            Math.floor((difference / 1000) % 60) < 10
              ? "0" + Math.floor((difference / 1000) % 60)
              : Math.floor((difference / 1000) % 60),
        };
      }
      setDuration(timeleft);

      let total = 0,
        overall = 0;
      find.Answers.map((item) => {
        total += Number(item.mainpoints);
        overall += Number(item.point);
        return true;
      });
      let percentage = (Number(overall) / Number(total)) * 100;
      let res = percentage < 60 ? "Fail" : "Pass";
      setResult({ Total: total, overall: overall, Result: res });
    },
    [user]
  );
  const deStuchureData = (data) => {
    const arr = [];
    const tags = [];
    const subTags = [];
    data?.Answers?.forEach((item, index) => {
      let obj = {
        key: index + 1,
        question: item.title ? item.title : item?.Question.question,
        marks: `${item?.point}/${item?.mainpoints}`,
        maintag: item.language,
        concept: item.concept,
        level: item.level,
        status:
          item?.point === item?.mainpoints
            ? "Correct"
            : item?.point === item?.mainpoints / 2
            ? "Partial Correct"
            : "Incorrect",
      };

      arr.push(obj);
      tags.push(item.language);
      subTags.push(item.concept);
    });
    setNewData(arr);
    const final = new Set(tags);
    const finalsubTag = new Set(subTags);
    setFinalMainTag([...final]);
    setfinalSubTag([...finalsubTag]);
  };
  useEffect(() => {
    if (user) {
      getrequest(`/user/givenmock/${id}`)
        .then((res) => {
          setMockData(res.data);
          handleUserData(res.data);
          setIaData(true);
        })
        .catch((err) =>
          toast.warning("server error", {
            position: "top-right",
            autoClose: 1500,
          })
        );
    }
  }, [id, user, handleUserData]);
  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const convertDateIntoNormal = (inputDateString) => {
    // Create a Date object from the input string
    const date = new Date(inputDateString);

    // Define months array for formatting
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract the day, month, and year components
    const day = date.getDate();
    const month = months[date.getMonth()];
    // Format the date as "DD Mon YYYY"
    const formattedDate = `${day} ${month}`;

    // Output: "22 Sept 2023"
    return formattedDate;
  };

  const Tags = (val) => {
    let StoredSkills = [];
    val?.MockData?.map((item) => {
      if (!StoredSkills.includes(item.MainTags)) {
        StoredSkills.push(item.MainTags);
      }
      return true;
    });
    return (
      <>
        {StoredSkills?.map((elee, ind) =>
          // ele?.MainTags.map((elee) =>console.log(elee))
          {
            return (
              <Tag key={ind}>
                {elee}
                {ind !== StoredSkills?.length - 1 && ","}
              </Tag>
            );
          }
        )}
      </>
    );
  };
  const getColumnSearchProps = (dataIndex) => ({
    render: (text) => (
      <div
        dangerouslySetInnerHTML={{ __html: text }}
        style={{ height: "auto", maxHeight: "200px", overflow: "auto" }}
      />
    ),
  });
  // Ant Design table
  const columns = [
    {
      title: "Q.No",
      dataIndex: "key",
      rowScope: "row",
      width: "6%",
    },
    {
      title: "Subject",
      dataIndex: "maintag",
      width: "8%",
      filters: finalMainTag.map((ele) => ({
        text: ele,
        value: ele,
      })),
      onFilter: (value, record) => record.maintag === value,
    },
    {
      title: "Topic",
      dataIndex: "concept",
      width: "8%",
      filters: finalSubTag.map((ele) => ({
        text: ele,
        value: ele,
      })),
      onFilter: (value, record) => record.concept === value,
    },
    {
      title: "Difficulty",
      dataIndex: "level",
      width: "8%",
      filters: [
        {
          value: "Easy",
          text: "Easy",
        },
        {
          value: "Medium",
          text: "Meduim",
        },
        {
          value: "Hard",
          text: "Hard",
        },
      ],
      onFilter: (value, record) => record.level === value,
    },
    {
      title: "Question",
      dataIndex: "question",
      width: "50%",
      ...getColumnSearchProps("question"),
    },

    {
      title: "Marks",
      dataIndex: "marks",
      width: "7%",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "7%",
      filters: [
        {
          value: "Correct",
          text: "Correct",
        },
        {
          value: "Incorrect",
          text: "Incorrect",
        },
        {
          value: "Partial Correct",
          text: "Partial Correct",
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
  ];
  return (
    <>
      <ToastContainer />
      {IsData ? (
        <div className={styles.main}>
          <label className={styles.navigation}>
            <span
              onClick={() => nevigate("/user")}
              style={{ cursor: "pointer" }}
            >
              Home{" "}
            </span>
            &gt;
            <span>
              {MockData?.MockTitle} &gt;<span> {"Reviewed"}</span>
            </span>
          </label>
          <div className={styles.userDetails}>
            <div>
              <label style={{ fontSize: "70px", marginTop: "-20px" }}>
                <FaRegUserCircle />
              </label>
            </div>
            <div>
              <label>{MockData?.MockTitle}</label>
              <span>
                Valid - {MockData && convertDateIntoNormal(MockData?.MockStart)}{" "}
                {MockData?.Mockend !== " " && "- "}
                {MockData &&
                  MockData.Mockend !== " " &&
                  convertDateIntoNormal(MockData?.Mockend)}
              </span>
              <span>
                Duration - {duration?.hours}:{duration?.minutes}:
                {duration?.seconds}
              </span>
            </div>
            <div>
              <label>Subject</label>
              <span>{MockData && Tags(MockData)}</span>
            </div>
            <div style={{ borderRight: "none" }}>
              <label>Overall Score</label>
              <span>
                {result.overall}/{result.Total}
              </span>
            </div>
          </div>
          <section>
            <div ref={ref}>
              <Overlay
                show={show}
                target={target}
                placement="bottom"
                container={ref}
                containerPadding={20}
              >
                <Popover id="popover-contained">
                  <div className={styles.popup}>
                    <p
                      onClick={() => {
                        setRevaluation(true);
                        handleClick();
                      }}
                    >
                      <img src={revaluationPic} alt="revaluation" />
                      Revaluation
                    </p>
                    <p
                      onClick={() => {
                        // handleShow();
                        handleClick();
                      }}
                    >
                      <img src={restest} alt="retest" />
                      Retest Request
                    </p>
                  </div>
                </Popover>
              </Overlay>
            </div>
          </section>

          <p
            className={revaluation ? styles.cancel : styles.apply}
            onClick={() => {
              setRevaluation(false);
              // handleClose();
            }}
          >
            Cancel
          </p>

          <div className={styles.cards}>
            <Table
              columns={columns}
              dataSource={newData}
              loading={newData.length <= 0}
              style={{
                width: "100%",
                margin: "5px auto auto auto",
                padding: "15px",
              }}
              caption={true}
              scroll={{
                y: 350,
              }}
              size="small"
              onRow={(record, rowIndex) => {
                return {
                  onClick: () => {
                    setShowFeedbackModal(true);
                    setQno(rowIndex);
                  },
                };
              }}
            />

            {MockData && (
              <div className={styles.overAllFedback}>
                <label>Overall Feedback</label>
                <div>
                  <span>{Answers?.OverAllFeedback}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "49vh",
          }}
        >
          <Spinner size="xl" variant="success" />
        </div>
      )}

      <Modal
        open={showFeedbackModal}
        onCancel={() => {
          setShowFeedbackModal(false);
          setSelectedPage("main");
        }}
        centered
        width={Answers?.Answers[QNO].Type === "Coding" ? "100%" : 900}
        onOk={() => {
          setShowFeedbackModal(false);
          setSelectedPage("main");
        }}
        okText="Close"
        footer={(_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        )}
      >
        <select
          onChange={(e) => {
            setQno(e.target.value);
            setSelectedPage("main");
          }}
          value={QNO}
          className={styles.dropDown}
        >
          {Answers &&
            Answers.Answers.map((item, index) => {
              return (
                <option
                  key={index}
                  value={index}
                  style={{
                    color: item.answer === "don't know" ? "white" : "green",
                    backgroundColor:
                      item.answer === "don't know" ? "red" : "white",
                  }}
                >
                  Question {index + 1} :{" "}
                  {item.Type === "Question" ? "Theory" : item.Type}
                </option>
              );
            })}
        </select>

        <div className={styles.feedbackBox}>
          <div className={styles.coding_container}>
            <p
              className={styles.question}
              dangerouslySetInnerHTML={{
                __html: Answers?.Answers[QNO]?.Question.question,
              }}
            />
            {Answers?.Answers[QNO]?.Type === "Coding" && (
              <div className={styles.coding_editor}>
                <span>
                  <label
                    style={{
                      cursor: "pointer",
                      textDecoration:
                        selectedpage === "main" ? "underline" : null,
                    }}
                    onClick={() => setSelectedPage("main")}
                  >
                    index.{Answers?.Answers[QNO]?.extension}
                  </label>
                  {Answers?.Answers[QNO]?.language === "HTML" && (
                    <>
                      <label
                        className="mx-3"
                        style={{
                          cursor: "pointer",
                          textDecoration:
                            selectedpage === "css" ? "underline" : null,
                        }}
                        onClick={() => setSelectedPage("css")}
                      >
                        style.css
                      </label>
                      <label
                        className="mx-3"
                        style={{
                          cursor: "pointer",
                          textDecoration:
                            selectedpage === "js" ? "underline" : null,
                        }}
                        onClick={() => setSelectedPage("js")}
                      >
                        Script.js
                      </label>
                    </>
                  )}
                </span>
                <hr style={{ width: "100%" }} />
                <pre className={styles.code_show}>
                  {selectedpage === "main" && Answers?.Answers[QNO]?.answer}
                  {selectedpage === "css" &&
                    Answers?.Answers[QNO]?.HTMLCode?.csscode}
                  {selectedpage === "js" &&
                    Answers?.Answers[QNO]?.HTMLCode?.scriptcode}
                </pre>
              </div>
            )}
          </div>

          <div
            className="d-flex flex-column mb-3"
            style={{ borderStyle: "none" }}
          >
            <Row gutter={32}>
              <Col span={12}>
                {Answers?.Answers[QNO]?.Type === "Mcq" && (
                  <>
                    <label
                      style={{
                        color:
                          (Answers?.Answers[QNO].Options.opt1 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point > 0
                            ? "green"
                            : "") ||
                          (Answers?.Answers[QNO].Options.opt1 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point === 0
                            ? "red"
                            : ""),
                      }}
                    >
                      1. {Answers?.Answers[QNO].Options.opt1}
                    </label>
                    <label
                      style={{
                        color:
                          (Answers?.Answers[QNO].Options.opt2 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point > 0
                            ? "green"
                            : "") ||
                          (Answers?.Answers[QNO].Options.opt2 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point === 0
                            ? "red"
                            : ""),
                      }}
                    >
                      2. {Answers?.Answers[QNO].Options.opt2}
                    </label>
                    <label
                      style={{
                        color:
                          (Answers?.Answers[QNO].Options.opt3 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point > 0
                            ? "green"
                            : "") ||
                          (Answers?.Answers[QNO].Options.opt3 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point === 0
                            ? "red"
                            : ""),
                      }}
                    >
                      3. {Answers?.Answers[QNO].Options.opt3}
                    </label>
                    <label
                      style={{
                        color:
                          (Answers?.Answers[QNO].Options.opt4 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point > 0
                            ? "green"
                            : "") ||
                          (Answers?.Answers[QNO].Options.opt4 ===
                            Answers?.Answers[QNO].answer &&
                          Answers?.Answers[QNO].point === 0
                            ? "red"
                            : ""),
                      }}
                    >
                      4. {Answers?.Answers[QNO].Options.opt4}
                    </label>
                  </>
                )}
              </Col>
              <Col span={12}>
                {Answers?.Answers[QNO].modelans?.length > 0 && (
                  <div>
                    {Answers?.Answers[QNO].modelans.map((item, index) => (
                      <span>
                        {(item.endsWith(".mp4") || item.endsWith(".avi")) && (
                          <label>
                            {!showVideo ? (
                              <button onClick={handlePlayClick}>
                                Play Explanation Video
                              </button>
                            ) : (
                              <video width="400" controls ref={videoRef}>
                                <source
                                  src={item}
                                  type={`video/${item.split(".").pop()}`}
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                          </label>
                        )}
                        {item.endsWith(".pdf") && (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              margin: "10px",
                            }}
                          >
                            <img
                              src={pdfIcon}
                              alt="Pdf"
                              width={50}
                              height={50}
                            />
                            <a
                              href={item}
                              download={"ModelAnswer.pdf"}
                              style={{
                                textDecoration: "none",
                                color: "#3498db",
                              }}
                            >
                              Download Model Answer
                            </a>
                          </div>
                        )}
                      </span>
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </div>

          <label>Feedback</label>
          <div>
            {Answers?.Answers[QNO]?.feedback ? (
              <div className={styles.feedback_container}>
                {Answers?.Answers[QNO]?.feedback}
              </div>
            ) : (
              <label>
                Uh-oh! It seems there's an issue with the feedback, possibly due
                to some background noise. No worries! You can connect with the
                coordinator for a manual check.
              </label>
            )}
            <label>
              Point{" "}
              <span>
                {Answers?.Answers[QNO]?.point}/
                {Answers?.Answers[QNO]?.mainpoints}
              </span>
            </label>
          </div>
          {Answers?.Answers[QNO]?.explanation &&
            Answers?.Answers[QNO]?.Type === "Coding" && (
              <section className={styles.explanation_main}>
                <label>Explanation</label>
                <div>
                  {Answers?.Answers[QNO]?.feedback ? (
                    <div className={styles.feedback_container}>
                      {Answers?.Answers[QNO]?.explanation}
                    </div>
                  ) : (
                    <label>
                      Uh-oh! It seems there's an issue with the feedback,
                      possibly due to some background noise. No worries! You can
                      connect with the coordinator for a manual check.
                    </label>
                  )}
                </div>
              </section>
            )}
        </div>
      </Modal>
    </>
  );
};

export default StudentGivenMock;
