import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { Tabs, Button, message } from "antd";
import { postrequest } from "../../Middleware/managerequest";
import styles from "./styles/groupdetails.module.css";
import { HiDocumentDuplicate } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
// import OverviewTable from "./OverviewTable";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UploadOutlined,
  ExceptionOutlined,
  UserSwitchOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

import DashboardSlider from "../../Navigator_Footer/DashboardSlider";

import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import AttendanceManager from "./Attendance_Manager";
import { setAttendance, setAttendancePractice } from "../../../store/Reducer";
import OverviewTable from "./OverviewTable";
import Assignments from "./Assignments";
import AttendancePractice from "./PracticeAttendance";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import GroupCurriculum from "./GroupCurriculum";
// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const GroupDetails = () => {
  const [searchparams] = useSearchParams();
  const [uploading, setUploading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [activetab, setActiveTab] = useState(1);
  const { user, Attendance, PracticeAttendance, clientdetails } = useSelector(
    (state) => state.Data
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [menuKey, setMenuKey] = useState("");
  const [openDwa, setOpenDwa] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (searchparams.size > 0) {
      setActiveTab(Number(searchparams.get("activetab")));
    }
  }, [searchparams, location, navigate]);

  useEffect(() => {
    if (state?.from) {
      setActiveTab(4);
    }
  }, [state]);

  const ChangeTab = (val) => {
    setActiveTab(val);
  };

  const handmodal = () => {
    setOpen(false);
  };
  const handmodal1 = () => {
    setOpen1(false);
  };

  let items = [
    {
      label: "Overview",
      key: 1,
      icon: <ExceptionOutlined />,
      children: <OverviewTable changeTab={ChangeTab} />,
      disabled: false,
    },
    {
      label: "Attendance",
      key: 2,
      icon: <UserSwitchOutlined />,
      children: (
        <AttendanceManager
          gid={id}
          open={open}
          user={user}
          handlmodal={handmodal}
        />
      ),
      disabled: false,
    },
    {
      label: "Practice Attendance",
      key: 5,
      icon: <UserSwitchOutlined />,
      children: (
        <AttendancePractice
          gid={id}
          open={open1}
          user={user}
          handlmodal={handmodal1}
        />
      ),
      disabled: false,
    },
    {
      label: "Tests",
      key: 3,
      children: "Tab 3",
      disabled: true,
    },
    {
      label: "Assignments",
      key: 4,
      icon: <HiDocumentDuplicate />,
      children: <Assignments />,
    },
    {
      label: "Course",
      key: 6,
      icon: <ReconciliationOutlined />,
      children: <GroupCurriculum />,
    },
  ];

  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "admin",
      <HomeOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined />
    ),
    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    (clientdetails?.isgroup ?? false) &&
      getItem(
        <span className={styles.menulabel}>Batches</span>,
        "managegroups",
        <UsergroupAddOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
      ),
    getItem(
      <span className={styles.menulabel}>Courses</span>,
      "course",
      <ReconciliationOutlined />
    ),
  ];

  const handlerClick = (e) => {
    setMenuKey(e.key);
    if (
      [
        "dashboard",
        "admin",
        "createmock",
        "questions",
        "managegroups",
        "course",
      ].includes(e.key)
    ) {
      navigate(`/${e.key}`);
    }

    setOpenDwa(false);
  };

  const HandleCSVFiles = (e) => {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type !== "text/csv") {
        messageApi.open({
          type: "warning",
          content: "Please select CSV files only.",
        });
        e.target.value = null;
        return;
      }
    }
    const formdata = new FormData();
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      formdata.append("csvFile", file);
    }
    setUploading(true);
    const key = "updatable";
    messageApi.open({
      key,
      type: "loading",
      content: "Uploading files. Please wait..",
    });
    const type = activetab === 2 ? "sessions" : "practice";
    postrequest(`/uploadattendance/${id}?type=${type}`, formdata)
      .then((res) => {
        setUploading(false);
        messageApi.open({
          key,
          type: "success",
          content: "Successfully uploaded",
          duration: 2,
        });
        e.target.value = null;
        if (type === "practice") {
          dispatch(setAttendancePractice(res.data.data));
        } else {
          dispatch(setAttendance(res.data.data));
        }
      })
      .catch((err) => {
        messageApi.open({
          key,
          type: "error",
          content: "Error While Uploading Files",
        });
        e.target.value = null;
        setUploading(false);
        messageApi.destroy();
        if (Array.isArray(err.response.data) && err.response.data.length > 0) {
          err.response.data.forEach((item) => {
            messageApi.open({
              type: "error",
              content: item,
              duration: 5,
            });
          });
        }
        //  setTimeout(messageApi.destroy(),2500)
      });
  };

  return (
    <div className={styles.main}>
      {/* First Row */}
      {contextHolder}
      <div className={styles.maintable}>
        <div className={styles.dashboard}>
          <DashboardSlider />
        </div>
        <div className={styles.table}>
          <LatestNavbar
            openDwa={openDwa}
            groupname={state.groupname}
            setOpenDwa={setOpenDwa}
          />

          <Tabs
            tabBarExtraContent={
              activetab === 2 || activetab === 5 ? (
                <div className="d-flex align-items-center">
                  {" "}
                  {activetab === 2 && (
                    <Button onClick={() => setOpen(true)} className="mx-2">
                      {Attendance.length > 0 ? "Update" : "Add"} Trainer
                    </Button>
                  )}
                  {activetab === 5 && (
                    <Button onClick={() => setOpen1(true)} className="mx-2">
                      {PracticeAttendance.length > 0 ? "Update" : "Add"} Trainer
                    </Button>
                  )}
                  {activetab === 2 && Attendance.length !== 0 && (
                    <>
                      <input
                        type="file"
                        id="attendance"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => HandleCSVFiles(e)}
                        accept=".csv"
                        disabled={uploading}
                      />

                      <label htmlFor="attendance" className={styles.upload_csv}>
                        <UploadOutlined /> Upload CSV
                      </label>
                    </>
                  )}
                  {activetab === 5 && PracticeAttendance.length !== 0 && (
                    <>
                      <input
                        type="file"
                        id="attendance"
                        style={{ display: "none" }}
                        multiple
                        onChange={(e) => HandleCSVFiles(e)}
                        accept=".csv"
                        disabled={uploading}
                      />

                      <label htmlFor="attendance" className={styles.upload_csv}>
                        <UploadOutlined /> Upload CSV
                      </label>
                    </>
                  )}
                </div>
              ) : null
            }
            type="card"
            items={items}
            activeKey={activetab}
            className={styles.firstrow}
            onChange={(val) => {
              setActiveTab(val);

              const params = new URLSearchParams(location.search);
              params.set("activetab", val);

              navigate(
                { search: params.toString() },
                { replace: true, state: state }
              );
              //  setSearchParams({activetab:1})
            }}
          />
        </div>
      </div>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </div>
  );
};

export default GroupDetails;
