import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  SearchOutlined,
  CloseOutlined,
  RedoOutlined,
  LoadingOutlined,
  InfoCircleOutlined,
  UploadOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";

import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "../../../styles/questions.css";
import * as XLSX from "xlsx";
import {
  Table,
  // Popconfirm,
  Tooltip,
  Button,
  Tag,
  Input,
  Space,
  notification,
  Modal,
  Select,
  InputNumber,
  Popconfirm,
  message,
} from "antd";

import styles from "./AntDesign.module.css";
import CreateQuestions from "./CreateQuestion";
import { FaEdit, FaRegClone } from "react-icons/fa";
import TestCases from "./TestCases";

import { getrequest, postrequest } from "../../Middleware/managerequest";
import EditQuestions from "./EditQuestions";
import Highlighter from "react-highlight-words";
import CopyQuestions from "./CopyToPersonal";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import Sider from "antd/es/layout/Sider";

const EditOptions = [
  {
    value: "Tags",
    label: "Tags",
  },
  {
    value: "Difficulty",
    label: "Difficulty",
  },
  {
    value: "Duration",
    label: "Duration",
  },
  {
    value: "Points",
    label: "Points",
  },
];

const Difficulties = [
  {
    value: "Easy",
    label: "Easy",
  },
  {
    value: "Medium",
    label: "Medium",
  },
  {
    value: "Hard",
    label: "Hard",
  },
];

const Questions = () => {
  const userData = useSelector((state) => state.Data);
  const { user, access } = userData;
  const [openDwa, setOpenDwa] = useState(false);
  const [testedit, setTestcase] = useState(false);
  const [edit, setEdit] = useState(false);
  const [clone, setClone] = useState(false);
  const [global, setGlobaldata] = useState([]);
  const [editdata, seteditdata] = useState(null);
  const [Test, setTest] = useState(null);
  const [flag, setFlag] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [data, setData] = useState([]);
  const [requestsended, setRequestSend] = useState(false);
  const [newData, setNewData] = useState([]);
  const [fltr, setFltr] = useState([]);
  const [globaltopics, setGlobalTopics] = useState([]);
  const [globalmain, setGlobalMainTopics] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isBulkUplaod, setisBulkUplaod] = useState(false);
  const searchInput = useRef(null);
  const [selectedRowKeys, setSelectedRows] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [bulkedit, setbulkEdit] = useState(false);
  const [bulkMCQ, setBulkMCQ] = useState({});
  const [IsMcq, setIsMcq] = useState("Mcq");
  const [edit_selection, setEdit_Selection] = useState("Select Field");
  const [datatoUpdate, setdatatoUpdate] = useState("");
  const [searchParams] = useSearchParams();
  const bankType = searchParams.get("type");
  const openNotification = (placement) => {
    api.info({
      message: `Copied Successfully`,
      duration: 1000,
      placement,
    });
  };
  const CopiedNotification = (placement) => {
    api.info({
      message: `Duplicate Question`,
      description:
        "This Question is already present in Personal question bank.",
      placement,
      duration: 1000,
    });
  };

  const notificationmessage = useCallback(
    (val, err) => {
      api[err]({
        message: val,
      });
    },
    [api]
  );

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  // Convert sheet to array
  const convertInObject = (array) => {
    let result = {
      newtopics: [],
      details: [],
      invalid: [],
    };
    for (let i = 0; i < array.length; i++) {
      let innerArray = array[i];

      const mainTopic = globalmain.filter(
        (ele) => ele?.label === innerArray[2]
      );

      let obj = {
        type: IsMcq === "Mcq" ? "Mcq" : "Question",
        level: innerArray[0],
        qstn: { question: innerArray[4] },
        ...(IsMcq === "Mcq" && {
          opt1: innerArray[5],
          opt2: innerArray[6],
          opt3: innerArray[7],
          opt4: innerArray[8],
          ans: innerArray[9],
        }),
        maintopics:
          mainTopic.length > 0 ? mainTopic.map((ele) => ele.label) : "",
        concept: [innerArray[1]],
        title: innerArray[3],
        point: innerArray[IsMcq === "Mcq" ? 10 : 5],
        createdby: `${user?.FullName} on ${new Date()}`,
        qid: uuidv4(),
      };

      if (
        Object.values(obj).every(
          (value) => value !== null && value !== undefined && value !== ""
        )
      ) {
        const conceptTopic = fltr?.find(
          (element) => element?.label === obj.concept[0]
        );

        result.details.push(obj);
        result.newtopics = conceptTopic
          ? []
          : [{ label: obj.concept[0], value: obj.concept[0] }];
      } else {
        result.invalid.push(obj);
      }
    }
    setBulkMCQ(result);
  };
  // Read The File
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        // Assuming the first sheet is the one you want to convert
        const sheetName = workbook.SheetNames[0];
        const excelArray = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { header: 1 }
        );
        let newflt = excelArray.filter((item) => item.length !== 0);
        // Exclude the header row

        convertInObject(newflt.slice(1));
        event.target.value = "";
      };
      reader.readAsBinaryString(file);
    }
  };

  const fetcher = () => {
    setFlag(false);
    setShowLoader(true);
    getrequest(`/questions/${user?.subdomain}`)
      .then((res) => {
        if (bankType === "global") {
          deStucherData(res.data?.global?.questions);
        } else {
          deStucherData(res.data?.myquestions?.questions);
        }
        if (user?.subdomain !== "main" && res.data.global) {
          setGlobaldata(res.data.global.questions);
          setGlobalTopics(res.data.global.Topics);
        } else {
          setGlobaldata([]);
          setGlobalTopics([]);
        }
        if (res.data.myquestions) {
          setData(res.data.myquestions.questions);
          setFltr(res.data.myquestions.Topics);
        }
        if (user?.subdomain === "main") {
          setGlobalMainTopics(res.data.myquestions.MainTopics);
        } else {
          setGlobalMainTopics(res.data.MainTopics);
        }
        setShowLoader(false);
      })
      .catch((err) => notificationmessage("server error", "warning"));
  };

  const ChangeFlag = () => {
    setFlag(false);
  };
  //Fetching All Questions
  useEffect(() => {
    if (user) {
      fetcher();
    }
  }, [user, bankType]);
  //Delete Question
  const Delete = (val) => {
    postrequest(`/delete/${user?.subdomain}`, { selectedRowKeys })
      .then((res) => {
        if (res.status === 201) {
          fetcher();
        }

        setSelectedRows([]);
      })
      .catch((err) => notificationmessage("server error", "warning"));
  };

  //Edit Questions
  const editQuestions = () => {
    setEdit(false);
  };
  const cloneQuestions = (isclone, val) => {
    if (val === "duplicate") {
      if (isclone) {
        CopiedNotification("topRight");
      }

      setClone(false);
    } else {
      if (isclone) {
        openNotification("topRight");
        fetcher();
      }

      setClone(false);
    }
  };
  const openTestCase = () => {
    setTestcase(true);
  };
  //Add Test Cases
  const closeEdit = (val) => {
    setTestcase(false);
    if (val) {
      let obj = {
        qid: editdata.qid,
        TestCase: val,
      };

      postrequest(`/edittestcase/${user?.subdomain}`, obj)
        .then((res) => {
          if (res.status === 201) {
            fetcher();
            notificationmessage("Test Cases Created Successfully", "success");
          } else {
            notificationmessage("Test Cases not Added", "warning");
          }
        })
        .catch((err) => notificationmessage("server error", "warning"));
    }
  };

  const deStucherData = (data) => {
    let filteredquestion = [];
    let arr = data.map((item) => {
      let obj = {
        key: item.qid,
        question: item?.qstn.question,
        type: item?.type === "Question" ? "Theory" : item?.type,
        maintopics: item?.maintopics?.map((item) => item),
        tags: item?.concept?.map((val) => val),
        level: item?.level,
        title: item.title ?? undefined,
        item,
      };
      let filter = {
        text: item?.qstn.question,
        value: item?.qstn.question,
      };
      filteredquestion.push(filter);
      return obj;
    });

    setNewData(arr);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
          width: "300px",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <div className="d-flex justify-content-end mt-1 mb-1 mx-2">
          <CloseOutlined
            style={{ fontSize: "20px", cursor: "pointer" }}
            onClick={() => close()}
            title="Close"
          />
        </div>
        <Input
          ref={searchInput}
          placeholder={`Search by Name`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space className="d-flex justify-content-end">
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{
              width: 50,
            }}
            title="Search"
            variant="outline-primary"
          >
            <SearchOutlined />
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: false,
              });
            }}
            size="small"
            style={{
              width: 50,
            }}
            title="Reset"
            variant="outline-primary"
          >
            <RedoOutlined />
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text, record) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: record?.title ?? text }}
          style={{ height: "auto", maxHeight: "200px", overflow: "auto" }}
        />
      ),
  });

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      width: bankType === "personal" ? 120 : 140,
      filters: [
        {
          text: "MCQ",
          value: "Mcq",
        },
        {
          text: "Theory",
          value: "Theory",
        },

        {
          text: "Coding",
          value: "Coding",
        },
        {
          text: "Passage",
          value: "Passage",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value) === 0,
      resposnive: ["md"],
    },

    {
      title: "Question",
      dataIndex: "question",
      width: bankType === "personal" ? "45%" : "60%",
      ...getColumnSearchProps("question"),

      resposnive: ["md"],
    },
    {
      title: "Topic",
      dataIndex: "maintopics",
      width: bankType === "personal" ? 120 : 140,
      resposnive: ["md"],
      filters: globalmain?.map((item) => ({
        text: item.value,
        value: item.value,
      })),

      onFilter: (value, record) => record.maintopics.indexOf(value) === 0,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      width: bankType === "personal" ? 140 : 160,
      filterMode: "menu",
      filterSearch: true,
      resposnive: ["md"],
      filters:
        bankType === "personal"
          ? fltr?.map((item) => ({ text: item.value, value: item.value }))
          : globaltopics?.map((item) => ({
              text: item.value,
              value: item.value,
            })),

      onFilter: (value, { tags }) => {
        return tags.includes(value);
      },
      render: (value) => {
        return value.map((ite, ind) => {
          return (
            <Tag key={ind} color="gray">
              {ite}
            </Tag>
          );
        });
      },
    },
    {
      title: "Difficulty",
      dataIndex: "level",
      resposnive: ["md"],
      width: 100,
      filters: [
        {
          text: "Easy",
          value: "Easy",
        },
        {
          text: "Medium",
          value: "Medium",
        },
        {
          text: "Hard",
          value: "Hard",
        },
      ],
      onFilter: (value, record) => record.level.indexOf(value) === 0,
    },
    bankType === "personal"
      ? {
          title: "Action",
          value: "value",
          width: 160,
          render: (value, record) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Tooltip placement="left" title={"Edit Question"}>
                    <Button
                      onClick={() => {
                        setFlag(true);
                        // setEdit(true);
                        seteditdata(record.item);
                        setTest(record.item.TestCase ?? null);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto 10px",
                      }}
                    >
                      <FaEdit />
                    </Button>
                  </Tooltip>

                  {(record?.item?.createdby || record?.item?.lastupdateby) && (
                    <Tooltip
                      placement="left"
                      title={
                        <div>
                          {record?.item?.createdby && (
                            <span>
                              Createdby :{" "}
                              {record?.item?.createdby
                                ?.split(" ")
                                .slice(0, -4)
                                .join(" ")}
                            </span>
                          )}
                          <br />
                          {record?.item?.lastupdateby && (
                            <span>
                              LastModifiedby :{" "}
                              {record?.item?.lastupdateby
                                ?.split(" ")
                                .slice(0, -4)
                                .join(" ")}
                            </span>
                          )}
                        </div>
                      }
                    >
                      <InfoCircleOutlined
                        style={{
                          cursor: "pointer",
                          margin: "auto 10px",
                          fontSize: "20px",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
              </>
            );
          },
        }
      : {
          title: "Action",
          value: "value",
          width: 160,
          render: (value, record) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Tooltip placement="left" title={"Copy to Personal"}>
                    <Button
                      onClick={() => {
                        setClone(true);
                        seteditdata(record.item);
                        setTest(record.item.TestCase ?? null);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto 10px",
                      }}
                    >
                      <FaRegClone />
                    </Button>
                  </Tooltip>
                </div>
              </>
            );
          },
        },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRows(newSelectedRowKeys);
  };

  const FinalBulkSubmit = () => {
    let obj = {
      edit_selection,
      datatoUpdate,
      selectedRowKeys,
    };
    setRequestSend(true);
    postrequest(`/bulkedit/${user?.subdomain}`, obj)
      .then(() => {
        setRequestSend(false);
        setSelectedRows([]);
        setEdit_Selection("Select Field");
        setdatatoUpdate("");
        setbulkEdit(false);
        fetcher();
        Modal.destroyAll();
        notificationmessage("Successfully update", "success");
      })
      .catch((err) => {
        setRequestSend(false);
        notificationmessage("Error while Updating", "error");
      });
  };
  const BulkUplaod = async () => {
    if (bulkMCQ?.details?.length > 0) {
      try {
        const response = await postrequest(
          `/home/bulkquestion/${user?.subdomain}`,
          bulkMCQ
        );
        if (response) {
          setBulkMCQ({});
          setisBulkUplaod(false);
          fetcher();
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      message.warning("Invailid file! choose any other file.");
      setBulkMCQ({});
    }
  };

  const downloadExcel = () => {
    const link = document.createElement("a");
    link.href = IsMcq === "Mcq" ? "/Mcq.xlsx" : "/Theory.xlsx"; // Replace with the correct path to your file
    link.download =
      IsMcq === "Mcq" ? "Mcq Example.xlsx" : "Theory Example.xlsx"; // This is the default file name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        margin: "0px auto auto auto",
        height: "auto",
        width: "100%",
      }}
    >
      {" "}
      <Modal
        title="Bulk Upload"
        maskClosable={false}
        style={{ height: "90vh", width: "80%" }}
        centered
        open={isBulkUplaod}
        onOk={() => BulkUplaod()}
        onCancel={() => {
          setisBulkUplaod(false);
          setBulkMCQ({});
        }}
      >
        <div className={styles.selectBtn}>
          <main>
            <input
              checked={IsMcq === "Mcq"}
              value={IsMcq}
              id="Mcqid"
              onChange={(e) => {
                setIsMcq("Mcq");
                setBulkMCQ({});
              }}
              type="radio"
              name="file"
            />
            <label htmlFor="Mcqid">MCQ Question</label>
          </main>
          <main>
            <input
              id="TheoryId"
              checked={IsMcq === "Theory"}
              onChange={(e) => {
                setIsMcq("Theory");
                setBulkMCQ({});
              }}
              value={IsMcq}
              name="file"
              type="radio"
            />
            <label htmlFor="TheoryId">Theory Question</label>
          </main>
        </div>

        <div>
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileUpload}
            className="pt-2"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ></input>

          <label
            htmlFor="fileInput"
            style={{ cursor: "pointer" }}
            className={styles.uploadfile}
            title="Upload Question Paper (Excel,CSV file)"
          >
            <UploadOutlined size={19} style={{ marginRight: "8px" }} />
            Upload Question Paper (Excel,CSV file)
          </label>
          <label className={styles.uploadfile} onClick={downloadExcel}>
            <ArrowDownOutlined size={19} style={{ marginRight: "8px" }} />{" "}
            Sample {IsMcq} Upload File
          </label>
        </div>
        <div className={styles.count}>
          {bulkMCQ?.invalid?.length > 0 && (
            <label>Invailid Question count : {bulkMCQ?.invalid?.length}</label>
          )}
          {bulkMCQ?.details?.length > 0 && (
            <label style={{ marginLeft: "30px" }}>
              Vailid Question count : {bulkMCQ?.details?.length}
            </label>
          )}
        </div>
        {bulkMCQ?.invalid?.length > 0 && (
          <div className={styles.invalidQues}>
            <label>There is some formatting issue in below questions</label>
            {bulkMCQ?.invalid.map((ele, ind) => {
              return (
                <h6 key={ind}>
                  {"Question name =>"} {ele?.title?.slice(0, 15)}
                </h6>
              );
            })}
          </div>
        )}
      </Modal>
      {contextHolder}
      {flag ? (
        <CreateQuestions
          fetcher={fetcher}
          Topics={fltr}
          MainTopics={globalmain}
          setFlag={ChangeFlag}
          user={user}
          list={user?.subdomain === "main" ? global : data}
          access={access}
          editdata={editdata}
        />
      ) : (
        <>
          {testedit && editdata.type === "Coding" && (
            <TestCases Test={Test} handleModal={closeEdit} edit={testedit} />
          )}
          {edit && (
            <EditQuestions
              handleModal={editQuestions}
              editdata={editdata}
              topics={bankType === "personal" ? fltr : globaltopics}
              maintopics={globalmain}
              fetcher={fetcher}
              openTest={openTestCase}
              user={user}
              access={access}
            />
          )}
          {clone && (
            <CopyQuestions
              handleModal={cloneQuestions}
              editdata={editdata}
              topics={fltr}
              maintopics={globalmain}
              fetcher={fetcher}
              openTest={openTestCase}
            />
          )}
          <div className={styles.navbarBox}>
            <div className={styles.box}>
              <Sider
                className={styles.slider}
                breakpoint="lg"
                collapsedWidth="0"
                width={window.innerWidth < 350 ? 200 : "18%"}
                trigger={null}
              >
                <DashboardSlider />
              </Sider>

              <div className={styles.line}>
                <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />

                <div className="d-flex justify-content-between mt-5 pt-4 px-3">
                  <h4>
                    {bankType === "global" ? "Global " : "Personal "}Question
                    Bank
                  </h4>

                  {bankType === "personal" && (
                    <div>
                      <Button
                        onClick={() => {
                          setFlag(true);
                          seteditdata(null);
                        }}
                      >
                        + Create Question
                      </Button>

                      <Button
                        className="mx-3"
                        onClick={() => setisBulkUplaod(true)}
                      >
                        Bulk Upload
                      </Button>

                      {selectedRowKeys.length > 0 && (
                        <>
                          <Button
                            className="mx-3"
                            onClick={() => setbulkEdit(true)}
                          >
                            Edit
                          </Button>

                          {access && access?.role === "Admin" && (
                            <Popconfirm
                              onConfirm={() => Delete()}
                              title="Are you sure you want to delete the Questions?"
                              onCancel={() => setSelectedRows([])}
                              placement="left"
                              okText="Yes"
                              cancelText="Cancel"
                            >
                              <Button>Delete Questions</Button>
                            </Popconfirm>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>

                <Table
                  columns={columns}
                  dataSource={newData}
                  loading={showLoader}
                  style={{
                    width: "99%",
                    margin: `5px 0px auto 0px`,
                  }}
                  caption={true}
                  scroll={{
                    y: "70vh",
                  }}
                  size="small"
                  rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                    preserveSelectedRowKeys: false,
                  }}
                  expandable={{
                    expandedRowRender: (record) => (
                      <div className={styles.passage}>
                        {console.log(record)}
                        {record?.type === "Passage" ? (
                          <div className={styles.passageInner}>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: record.question, // Assuming `ele.question` contains the question content
                              }}
                            />
                            {record?.item?.qstn?.passageQuestions?.map(
                              (ele, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={styles.detailsPassage}
                                  >
                                    <label>Question : {index + 1}</label>

                                    <label>{ele?.description}</label>
                                    <h4>Options</h4>
                                    <div>
                                      {ele?.options?.map((opt, index) => {
                                        return (
                                          <h5
                                            key={index}
                                            style={
                                              opt === ele.answer
                                                ? {
                                                    color: "green",
                                                  }
                                                : { color: "black" }
                                            }
                                          >
                                            {opt}
                                          </h5>
                                        );
                                      })}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        ) : (
                          <p
                            dangerouslySetInnerHTML={{
                              __html: record.question,
                            }}
                          />
                        )}
                      </div>
                    ),
                    rowExpandable: (record) => record.title ?? false,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
      {/* Bulk Edit Option */}
      <Modal
        open={bulkedit}
        title="Edit Questions"
        footer={(_, { OkBtn }) => (
          <>
            {requestsended ? (
              <Tag icon={<LoadingOutlined />}>Updating</Tag>
            ) : (
              <OkBtn />
            )}
          </>
        )}
        onCancel={() => setbulkEdit(false)}
        onOk={() => FinalBulkSubmit()}
        okText="Update"
      >
        <div className={styles.edit_option_selector}>
          <span className={styles.edit_heading}>Select Field to Update </span>
          <Select
            options={EditOptions}
            placeholder="Select Field"
            onChange={(val) => {
              setEdit_Selection(val);

              setdatatoUpdate("");
            }}
            value={edit_selection}
          />
        </div>
        {/* Tags Select */}
        {edit_selection === "Tags" && (
          <div className={styles.edit_option_selector}>
            <span className={styles.edit_heading}>Select Tags </span>
            <Select
              options={fltr}
              mode="multiple"
              maxTagCount={"responsive"}
              placeholder="Select Tags"
              onChange={(val) => setdatatoUpdate(val)}
            />
          </div>
        )}
        {/* Difficulty Select */}
        {edit_selection === "Difficulty" && (
          <div className={styles.edit_option_selector}>
            <span className={styles.edit_heading}>Select Difficulty </span>
            <Select
              options={Difficulties}
              placeholder="Select Difficulty"
              onChange={(val) => setdatatoUpdate(val)}
            />
          </div>
        )}
        {/* Points */}
        {edit_selection === "Points" && (
          <div className={styles.edit_option_selector}>
            <span className={styles.edit_heading}>Enter Points </span>
            <InputNumber
              max={10}
              addonAfter={"Max 10 Points"}
              onChange={(val) => setdatatoUpdate(val)}
            />
          </div>
        )}
        {/* Duration */}

        {edit_selection === "Duration" && (
          <div className={styles.edit_option_selector}>
            <span className={styles.edit_heading}>Enter Durations </span>
            <InputNumber
              max={300}
              addonAfter={"Max 300 Sec"}
              controls={false}
              addonBefore={"Theory"}
              onChange={(e) =>
                setdatatoUpdate((prev) => ({ ...prev, Theory: e }))
              }
            />
            <br />
            <InputNumber
              max={180}
              addonAfter={"Max 180 Sec"}
              controls={false}
              addonBefore={"MCQ"}
              onChange={(e) => setdatatoUpdate((prev) => ({ ...prev, Mcq: e }))}
            />
            <br />
            <InputNumber
              max={1800}
              addonAfter="Max 1800 Sec"
              controls={false}
              addonBefore="Coding"
              onChange={(e) =>
                setdatatoUpdate((prevData) => ({ ...prevData, Coding: e }))
              }
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Questions;
