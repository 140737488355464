import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Select,
  message,
  Collapse,
  Typography,
  Popconfirm,
} from "antd";
import {
  getrequest,
  postrequest,
  putrequest,
} from "../../Middleware/managerequest";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styles from "./styles/groupcourseview.module.css";
const { Text } = Typography;

const GroupCurriculum = () => {
  const [coursedata, setCourseData] = useState([]);
  const [courselist, setCourseList] = useState([]);
  const [openselection, setOpenSelection] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const { id } = useParams();
  const { user } = useSelector((state) => state.Data);
  const [loading, setLoading] = useState(false);
  const [seletctedcontent, setSelectedcontent] = useState("");
  const [activekey, setActiveKey] = useState("");
  const [sendrequest, setSendRequest] = useState(false);
  const [changeorder, setChangeOrder] = useState(false);

  const FetchPrivateCourse = () => {
    const key = "course";
    message.loading({
      content: "Fetching Course",
      key: key,
    });
    getrequest(`/course/privatecourse/${user.subdomain}/${id}`)
      .then((res) => {
        if (res.data.length > 0) {
          setCourseList(() => {
            return res.data.map((item) => {
              return {
                label: item.CourseTitle,
                value: item.courseid,
              };
            });
          });
          message.destroy(key)
          setOpenSelection(true);
        } else {
          message.warning({
            content: "You don't have any course created",
          });
        }

        message.destroy(key);
      })
      .catch((err) => console.log(err?.message));
  };

  const Fetcher = useCallback(() => {
    const key = "course1";
    message.loading({
      content: "Checking  all courses",
      key: key,
    });
    setLoading(true);
    getrequest(`/attachedcourse/${id}`)
      .then((res) => {
        setCourseData(res.data);
        message.destroy(key);
        setActiveKey([res.data[0]?.sections[0]?.section_id]);
        setLoading(false);
      })
      .catch((err) => {
        message.error({
          content: err?.message,
          key: key,
        });
      });
  }, [id]);

  useEffect(() => {
    Fetcher();
  }, [Fetcher]);

  const AttachGroup = () => {
    setSendRequest(true);
    postrequest(`/attachtogroup/${id}`, selectedCourses)
      .then((res) => {
        setCourseList([]);
        setSelectedCourses([])
        setOpenSelection(false);
        Fetcher();
        setSendRequest(false);
      })
      .catch((err) => {
        setSendRequest(false);
        message.error({
          content: err?.response.data,
        });
      });
  };

  const Skills = (val) => {
    return val.map((item, ind) => {
      return (
        <span key={ind}>
          {item.MainTags}
          {val.length - 1 !== ind && ","}
        </span>
      );
    });
  };

  const ConvertToHours = (val) => {
    if (val === 0) {
      return 0;
    } else if (val <= 60) {
      return val + " min(s)";
    } else {
      const hours = Math.floor(val / 60);
      const remainingMinutes = val % 60;
      if (hours > 0 || remainingMinutes > 0) {
        return `${hours} hr ${remainingMinutes} min(s)`;
      } else {
        return 0;
      }
    }
  };

  const RenderDetails = () => {
    return (
      <div className={styles.details_main}>
        {/* For Live Session and Recorded Sessions */}
        {seletctedcontent && seletctedcontent?.type === "lession" && (
          <>
            <div className={styles.left_sub}>
              <label className={styles.lablename}>Description</label>

              <label>{seletctedcontent?.Description}</label>
            </div>
            {seletctedcontent.type === "lession" && (
              <>
                {seletctedcontent?.attachtype === "link" &&
                  !seletctedcontent?.islive_session && (
                    <div className={styles.left_sub}>
                      <label className={`${styles.lablename}`}>
                        External Link
                      </label>

                      <a
                        href={seletctedcontent?.external_link?.link}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Click Here
                      </a>
                    </div>
                  )}
                {(seletctedcontent?.attachtype === "video" ||
                  seletctedcontent?.attachtype === "pdf") &&
                  !seletctedcontent?.islive_session && (
                    <div className={`${styles.lablename}`}>
                      <label>Attachments</label>
                      <br />

                      {seletctedcontent?.attachtype === "video" ? (
                        <video controls width={300}>
                          <source
                            src={seletctedcontent?.attachments}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <a
                          rel="noreferrer"
                          href={seletctedcontent?.attachments}
                          target="_blank"
                        >
                          View PDF
                        </a>
                      )}
                    </div>
                  )}
              </>
            )}
          </>
        )}

        {seletctedcontent &&
          (seletctedcontent?.type === "test" ||
            seletctedcontent.type === "practice") && (
            <>
              <div className={styles.left_sub}>
                <label className={styles.lablename2}>Category</label>

                <label>{seletctedcontent?.Test_Details?.type}</label>
              </div>
              <div className={styles.left_sub}>
                <label className={styles.lablename2}>Test Name</label>
                <label>{seletctedcontent?.Test_Details?.Test_Name}</label>
              </div>
              {seletctedcontent?.type === "test" && (
                <div className={styles.left_sub}>
                  <label className={styles.lablename2}>Duration</label>

                  <label>
                    {ConvertToHours(seletctedcontent?.Test_Details?.Duration)}
                  </label>
                </div>
              )}

              <div className={styles.left_sub}>
                <label className={styles.lablename2}>No of Questions</label>

                <label>{seletctedcontent?.Test_Details?.NoofQ}</label>
              </div>
              <div className={styles.left_sub}>
                <label className={styles.lablename2}>Topic</label>

                <label>
                  {Skills(seletctedcontent?.Test_Details?.MockData)}
                </label>
              </div>
            </>
          )}
      </div>
    );
  };

  const lblname = (content, arr) => {
    switch (content.type) {
      case "lession":
        return (
          <label className={styles.lablename}>
            {content.islive_session ? "Live Session" : "Lesson"}
          </label>
        );
      case "test":
        return <label className={styles.lablename}>Test </label>;
      case "practice":
        return <label className={styles.lablename}>Assignment </label>;
      default:
        return -1;
    }
  };

  const RenderSections = (curriculum) => {
    return curriculum.map((section, sectionid) => {
      return {
        key: section.section_id,
        label: <b>{section.sectionName}</b>,
        children: section.content.map((content, contentid, arr) => {
          return (
            <div
              key={contentid}
              style={{
                backgroundColor:
                  content.content_id === seletctedcontent?.content_id
                    ? "lightgray"
                    : null,
              }}
              onClick={() => setSelectedcontent(content)}
              className={styles.content_name}
            >
              {lblname(content, arr)}{" "}
              <Text
                style={{ width: "250px" }}
                ellipsis={{ tooltip: content.content_Name }}
              >
                {content.content_Name}
              </Text>
            </div>
          );
        }),
      };
    });
  };

  const DeleteCourse = (courseid) => {
    const key = "delete";
    message.loading({
      content: "Deleting Course",
      key,
    });
    putrequest(`/deletecoursefromgroup/${id}`, { courseid: courseid }).then(
      (res) => {
        message.success({
          content: "Deleted Successfully",
          key,
        });
        Fetcher();
      }
    );
  };

  const ChangeOrder = (direction, courseid) => {
    const key = "order";
    message.loading({
      content: "Changing Order",
      key,
    });
    putrequest(`/changecourseorder/${id}`, {
      direction: direction,
      courseid: courseid,
    }).then((res) => {
      Fetcher();
      message.destroy(key);
    });
  };

  return (
    <>
      <div>
        <center>
          {/* If course added already */}
          {coursedata.length > 0 && (
            <>
              <div className="d-flex justify-content-end align-items-center">
                <label
                  className={styles.addcourse}
                  onClick={() => FetchPrivateCourse()}
                >
                  + Add Course
                </label>
                <UnorderedListOutlined
                  onClick={() => setChangeOrder(true)}
                  style={{ cursor: "pointer", fontSize: "20px" }}
                  className="mx-4"
                />
              </div>

              <div className={styles.curriculum_main}>
                {/* Left Side view */}
                <div className={styles.left_side}>
                  <RenderDetails />
                </div>
                {/* Right Side View */}
                <div className={styles.right_side}>
                  <Collapse
                    bordered={false}
                    accordion
                    onChange={(val) => setActiveKey(val)}
                    expandIconPosition={"end"}
                    style={{ width: "100%", textAlign: "start" }}
                    items={coursedata.map((course, ind) => {
                      return {
                        key: course.courseid,
                        label: course.CourseTitle,
                        extra: (
                          <Popconfirm
                            description="It will delete the user data as well. Are you sure to remove from group?"
                            onConfirm={(e) => {
                              DeleteCourse(course.courseid);
                            }}
                          >
                            <DeleteOutlined
                              onClick={(e) => e.stopPropagation()}
                            />
                          </Popconfirm>
                        ),
                        children: (
                          <Collapse
                            activeKey={activekey}
                            bordered={false}
                            accordion
                            onChange={(val) => setActiveKey(val)}
                            expandIconPosition={"end"}
                            style={{ width: "100%", textAlign: "start" }}
                            items={RenderSections(course.sections)}
                          />
                        ),
                      };
                    })}
                  />
                </div>
              </div>
            </>
          )}
          {loading && (
            <center>
              <LoadingOutlined />
            </center>
          )}
          {!loading && coursedata.length === 0 && (
            <div>
              <label style={{ fontSize: "20px" }}>
                Oops! You don't have any course attached.
              </label>
              <br />
              <br />
              <Button
                type="primary"
                onClick={() => FetchPrivateCourse()}
                icon={<PlusOutlined />}
              >
                Attach Course
              </Button>
            </div>
          )}
        </center>
      </div>

      <Modal
        open={openselection}
        title="Select the course"
        onCancel={() => setOpenSelection(false)}
        maskClosable={false}
        closable={false}
        cancelText="Close"
        onOk={() => AttachGroup()}
        okButtonProps={{
          loading: sendrequest,
          disabled: sendrequest,
        }}
      >
        <Select
          options={courselist}
          value={selectedCourses}
          onChange={(val) => setSelectedCourses(val)}
          style={{ width: "100%" }}
          mode="multiple"
        />
      </Modal>

      {/* Change Order */}
      <Modal
        maskClosable={false}
        closable={false}
        onCancel={() => setChangeOrder(false)}
        open={changeorder}
        title="Change Order"
      >
        {coursedata.map((course, ind, arr) => {
          return (
            <div className={styles.changeorder} key={course.courseid}>
              <label>{course.CourseTitle}</label>
              <span className={styles.changeorder_icon}>
                <span onClick={() => ChangeOrder("up", course.courseid)}>
                  {ind !== 0 && (
                    <ArrowUpOutlined
                      className="mx-3"
                      style={{ cursor: "pointer" }}
                      title="Move Up"
                    />
                  )}
                </span>
                <span
                  onClick={() => ChangeOrder("down", course.courseid)}
                  style={{ width: "50px" }}
                >
                  {ind !== arr.length - 1 && (
                    <ArrowDownOutlined
                      style={{ cursor: "pointer" }}
                      title="Move Down"
                    />
                  )}
                </span>
              </span>
            </div>
          );
        })}
      </Modal>
    </>
  );
};

export default GroupCurriculum;
