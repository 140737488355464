import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const EducationSlice = createSlice({
  name: "education",
  initialState,
  reducers: {
    replaceEducation: (state, action) => {
      return action.payload; // Replace the entire state with the new array
    },
  },
});

export const { replaceEducation } = EducationSlice.actions;

export default EducationSlice.reducer;
