import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Editor from "@monaco-editor/react";
import styles from "./styles/codereview.module.css";
import {
  Breadcrumb,
  Button,
  Tabs,
  Image,
  Pagination,
  Modal,
  Popconfirm,
  Typography,
} from "antd";
import { BsClock } from "react-icons/bs";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { postrequest, putrequest } from "../../Middleware/managerequest";
import { TestCaseData } from "../../User/Mock/TestCaseData";
const { Paragraph } = Typography;
const Codereview = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentkey, setCurrentKey] = useState(1);
   const [loading,setLoading]=useState(false)
  const [CompilerStatus, setCompilerStatus] = useState({
    isrunning: false,
    TesCasePass: null,
    TestCode: "",
    Isinfinite: false,
  });

  const [Output, setOutput] = useState("");
  const [openWebview, setOpenWebView] = useState(false);
  const [pagenumber, setPageNumber] = useState(1);
  const {
    groupname,
    username,
    maintopics,
    Diff,
    id,
    questiondata,
    userid,
    inde,
  } = state;
  const [ind, setInd] = useState(questiondata.answer.length);
  const [code, setCode] = useState(questiondata.answer[ind - 1].code);
  const [feedback, setFeedback] = useState(questiondata?.feedback ?? "");

  const ConvertToNormal = (val) => {
    const date = new Date(val);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const Second = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}  ${hours}:${minutes}:${Second}`;
    return formattedDate;
  };

  const ChangeHistory = (val) => {
    if (val === "next") {
      setInd((val) => val + 1);
    } else {
      setInd((val) => val - 1);
    }

    setCode(questiondata.answer[ind - 1].code);
  };

  useEffect(() => {
    let code = TestCaseData(
      questiondata?.PreInputs,
      questiondata.answer[ind - 1]?.language?.language
    );
    setCompilerStatus((val) => ({ ...val, TestCode: code?.testcode }));
  }, [ind, questiondata]);

  const HandleRun = () => {
    if (questiondata.answer[ind - 1].language.language === "html") {
      // console.log(FilesData)

      const modifiedCSS = code.html.replace(
        /<link\s+[^>]*href="style\.css"[^>]*>/g,
        `<style>\n ${code.css} \n</style>`
      );

      const modifiedScript = modifiedCSS.replace(
        /<script\s+[^>]*src="script\.js"[^>]*>[\s\S]*?<\/script>/g,
        `<script>\n   ${code.javascript}\n</script>`
      );

      setOutput(modifiedScript);
      setOpenWebView(true);
    } else {
      setCompilerStatus((val) => ({
        ...val,
        isrunning: true,
        Isinfinite: false,
      }));
      let data = {
        language: questiondata.answer[ind - 1].language.language,
        stdin: "",
        files: [
          {
            name: `Main.${questiondata.answer[ind - 1].language.extension}`,
            content: questiondata?.PreInputs
              ? code[questiondata.answer[ind - 1].language.language] +
                "\n" +
                CompilerStatus?.TestCode?.trim()
              : code[questiondata.answer[ind - 1].language.language],
          },
        ],
      };

   

      postrequest(
        `/compiler/${questiondata.answer[ind - 1].language.language}`,
        data
      )
        .then((res) => {
          setCompilerStatus((val) => ({ ...val, isrunning: false }));
          setOutput(res.data.data);
          if (questiondata?.Outputstocheck && res?.data?.data?.stdout !== "") {
            let data = res.data.data.stdout;

            if (typeof data === "string") {
              data = data.replace(/'/g, '"').trim();
              function isJSONString(str) {
                try {
                  JSON.parse(str);
                  return true;
                } catch (error) {
                  return false;
                }
              }
              if (isJSONString(data)) {
                try {
                  data = JSON.parse(data);

                  data = JSON.stringify(data).replace(/undefined/g, "null");
                  let isEqual =
                    JSON.stringify(questiondata?.Outputstocheck) ===
                    JSON.stringify(JSON.parse(data));
                  setCompilerStatus((val) => ({
                    ...val,
                    TesCasePass: isEqual,
                  }));
                } catch (error) {
                  alert("Code : 1");
                }
              }
            } else {
              alert("Code : 2");
            }
          } else if (questiondata?.TestCase?.Output) {
            //eslint-disable-next-line
            if (res.data.data.stdout == questiondata.TestCase.Output) {
              setCompilerStatus((val) => ({ ...val, TesCasePass: true }));
            } else {
              setCompilerStatus((val) => ({ ...val, TesCasePass: false }));
            }
          } else {
            setCompilerStatus((val) => ({ ...val, TesCasePass: false }));
          }
          setCurrentKey("output");
        })
        .catch((err) => {
          setCurrentKey("output");
          console.log(err);
          setCompilerStatus((val) => ({
            ...val,
            isrunning: false,
            Isinfinite: true,
          }));
        });
    }
  };

  const Submit = async () => {
    if (questiondata?.feedback && questiondata.feedback !== feedback) {
      setLoading(true)
      postrequest(`/questionfeedback/${id}/${userid}`, {
        topicindex: inde,
        difficulty: Diff,
        qid: questiondata.qid,
        feedback: feedback,
      })
        .then((res) => {
          setLoading(false)
          navigate("/assignments", {
            state: {
              groupname: groupname,
              username: username,
              maintopics: maintopics,
              Diff: Diff,
              id: id,
              userid: userid,
              ind: inde,
            },
          });
        })
        .catch((err) => setLoading(false));
    } else if (!questiondata?.feedback) {
      setLoading(true)
      postrequest(`/questionfeedback/${id}/${userid}`, {
        topicindex: inde,
        difficulty: Diff,
        qid: questiondata.qid,
        feedback: feedback,
      })
        .then((res) => {
          setLoading(false)
          navigate("/assignments", {
            state: {
              groupname: groupname,
              username: username,
              maintopics: maintopics,
              Diff: Diff,
              id: id,
              userid: userid,
              ind: inde,
            },
          });
        })
        .catch((err) => setLoading(false));
    } else {
      setLoading(false)
      navigate("/assignments", {
        state: {
          groupname: groupname,
          username: username,
          maintopics: maintopics,
          Diff: Diff,
          id: id,
          userid: userid,
          ind: inde,
        },
      });
    }
  };

  const Reset = () => {
    setLoading(true)
    putrequest(`/resetquestions/${id}/${userid}`, {
      topicindex: inde,
      Diff: Diff,
      Qid: [questiondata.qid],
    })
      .then((res) => {
        setLoading(false)
        navigate("/assignments", {
          state: {
            groupname: groupname,
            username: username,
            maintopics: maintopics,
            Diff: Diff,
            id: id,
            userid: userid,
            ind: inde,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  function defaultvalues(val, ind, d) {
    if (Array.isArray(val)) {
      // let array = JSON.parse(JSON.stringify(val));
      let str = "";

      val.forEach((item, index) => {
        if (index !== 0) {
          str += ","; // Add a comma separator for elements after the first one
        }
        if (Array.isArray(item)) {
          str += "[" + item.join(",") + "]"; // Join array elements with a comma
        } else if (typeof item === "object" && item !== null) {
          str += JSON.stringify(item); // Stringify objects
        } else {
          str += item.toString(); // Convert non-array elements to strings
        }
      });
      return `TestCase ${ind + 1} : ` + str + "\n";
    } else {
      return val;
    }
  }

  return (
    <div className={styles.main}>
      <Breadcrumb
        items={[
          {
            title: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/managegroups")}
              >
                Batch
              </span>
            ),
          },
          {
            title: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/group/${id}`, { state: { groupname: groupname } })
                }
              >
                {groupname}
              </span>
            ),
          },
          {
            title: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/group/${id}`, { state: { groupname: groupname } })
                }
              >
                {username}
              </span>
            ),
          },
          {
            title: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate(`/group/${id}`, { state: { groupname: groupname } })
                }
              >
                {maintopics}
              </span>
            ),
          },
          {
            title: (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/assignments", {
                    state: {
                      groupname: groupname,
                      username: username,
                      maintopics: maintopics,
                      Diff: Diff,
                      id: id,
                      userid: userid,
                      ind: inde,
                    },
                  })
                }
              >
                {Diff}
              </span>
            ),
          },
          {
            title: "Question",
          },
        ]}
      />
      <div className={styles.submain_container}>
        <div className={styles.submain_left}>
          <Tabs
            tabBarExtraContent={
              <Button type="primary" onClick={() => HandleRun()}>
                Run
              </Button>
            }
            activeKey={currentkey}
            onChange={(val) => setCurrentKey(val)}
            items={[
              {
                label: `index.${
                  questiondata.answer[ind - 1].language.extension
                }`,
                key: 1,
                children: (
                  <Editor
                    language={questiondata.answer[ind - 1].language.language}
                    height={400}
                    value={code[questiondata.answer[ind - 1].language.language]}
                    className={styles.editor}
                    onChange={(e) => {
                      setCode((val) => ({
                        ...val,
                        [questiondata.answer[ind - 1].language.language]: e,
                      }));
                    }}
                  />
                ),
              },
              questiondata.answer[ind - 1].language.language === "html" && {
                label: `style.css`,
                key: 2,
                children: (
                  <Editor
                    language="css"
                    height={400}
                    value={code.css}
                    className={styles.editor}
                    onChange={(e) => {
                      setCode((val) => ({ ...val, css: e }));
                    }}
                  />
                ),
              },
              questiondata.answer[ind - 1].language.language === "html" && {
                label: `script.js`,
                key: 3,
                children: (
                  <Editor
                    language="javascript"
                    height={400}
                    value={code.javascript}
                    className={styles.editor}
                    onChange={(e) => {
                      setCode((val) => ({ ...val, javascript: e }));
                    }}
                  />
                ),
              },
              {
                label: `ReadME.md`,
                key: 4,
                children: (
                  <div
                    className={styles.question_label}
                    dangerouslySetInnerHTML={{
                      __html: questiondata.answer[ind - 1].code.markdown,
                    }}
                  />
                ),
              },
              {
                label: "TestCases",
                key: "testcase",
                children: (
                  <Paragraph>
                    <pre style={{ height: "40vh", overflow: "auto" }}>
                      {questiondata?.TestCase?.TestName}
                      <br />
                      {questiondata?.PreInputs !== "" && (
                        <label>
                          <b>Inputs are </b>{" "}
                        </label>
                      )}
                      <br />
                      {questiondata?.PreInputs === ""
                        ? questiondata?.TestCase?.BaseCode
                        : questiondata?.PreInputs?.map((val, ind) => {
                            return defaultvalues(val, ind, "in");
                          })}

                      <br />

                      <label>
                        <b>Output should be </b>
                      </label>
                      <br />

                      {questiondata?.Outputstocheck === ""
                        ? questiondata?.TestCase?.Output
                        : questiondata?.Outputstocheck?.map((val, ind) => {
                            return defaultvalues(val, ind, "out");
                          })}
                    </pre>
                  </Paragraph>
                ),
              },
              questiondata.answer[ind - 1].language.language !== "html" && {
                label: "Output",
                key: "output",
                children: (
                  <pre className={styles.output}>
                    {/* Output */}
                    {!Output && "Output will be here"}
                    {Output &&
                      questiondata?.Outputstocheck &&
                      ((Output.stdout &&
                        typeof Output.stdout === "string" && // Check if it's a string
                        Output.stdout.trim() !== "" && // Check if it's not an empty string
                        (() => {
                          try {
                            let cleanedData = Output.stdout
                              .replace(/'/g, '"')
                              .trim(); // Replace single quotes with double quotes
                            cleanedData = cleanedData.replace(/\\"/g, '"'); // Remove backslashes before double quotes
                            function isJSONString(str) {
                              try {
                                JSON.parse(str);
                                return true;
                              } catch (error) {
                                return false;
                              }
                            }
                            // Check if the data is valid JSON before parsing
                            if (!isJSONString(cleanedData)) {
                              return <span>{cleanedData}</span>;
                            }

                            const processedData = JSON.parse(
                              cleanedData.replace(/undefined/g, "null")
                            );
                            const parsedData = Array.isArray(processedData)
                              ? processedData
                              : [processedData];

                            return parsedData.map((item, index) => (
                              <React.Fragment key={index}>
                                {Array.isArray(item) ? (
                                  item.map((it, ind) => (
                                    <React.Fragment key={ind}>
                                      <label>{JSON.stringify(it)}</label>
                                      <br />
                                    </React.Fragment>
                                  ))
                                ) : (
                                  <label>{JSON.stringify(item)}</label>
                                )}
                                <br />
                              </React.Fragment>
                            ));
                          } catch (error) {
                            console.error("Error parsing JSON:", error);
                            return null; // Handle the error, return null, or an error message
                          }
                        })()) ||
                        Output.stderr)}

                    {Output &&
                      !questiondata?.Outputstocheck &&
                      (Output.stdout || Output.stderr)}
                    {["mysql", "postgresql", "mongodb"].includes(
                      questiondata.answer[ind - 1].language.language
                    ) &&
                      Output &&
                      (Output.stdout || Output.stderr)}
                    {CompilerStatus.isrunning && "Running"}
                    {CompilerStatus.Isinfinite && "Infinite Loop"}
                  </pre>
                ),
              },
            ]}
            style={{ width: "100%" }}
          />
          <div className={styles.history}>
            <div className={styles.history_sub}>
              <span className={styles.history_label}>
                {" "}
                <BsClock className="mx-1" /> History{" "}
              </span>
              <div className="d-flex mx-3">
                <span className="mx-2">
                  {ind !== 1 && (
                    <LeftOutlined
                      onClick={() => {
                        ChangeHistory("prev");
                      }}
                    />
                  )}
                </span>
                <div>
                  {" "}
                  {ind}/{questiondata.answer.length}
                </div>
                <span className="mx-2">
                  {" "}
                  {ind !== questiondata.answer.length && (
                    <RightOutlined
                      onClick={() => {
                        ChangeHistory("next");
                      }}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className={styles.timing}>
              {ConvertToNormal(questiondata.answer[ind - 1].starttime)}{" "}
              <strong className="mx-3"> To </strong>{" "}
              {ConvertToNormal(questiondata.answer[ind - 1].endtime)}
            </div>
          </div>
        </div>

        <div className={styles.submain_right}>
          <Tabs
            onChange={() => setPageNumber(1)}
            tabBarExtraContent={
              <Popconfirm
                placement="left"
                title="Warning"
                description="Are you sure to reset the question? It will delete this question data."
                okText="Yes"
                cancelText="No"
                onConfirm={() => Reset()}
              >
                <u style={{ cursor: "pointer" }}>Reset</u>
              </Popconfirm>
            }
            items={[
              {
                label: "ScreenShots",
                key: "1",
                children: (
                  <>
                    <div className={styles.screenshots}>
                      <Image.PreviewGroup>
                        {questiondata?.screenRecord?.length > 0
                          ? questiondata.screenRecord
                              .slice((pagenumber - 1) * 12, pagenumber * 12)
                              .reverse()
                              .map((item, index) => {
                                return (
                                  <Image
                                    src={item}
                                    key={index}
                                    width={100}
                                    preview={true}
                                  />
                                );
                              })
                          : "No ScreenShots"}
                      </Image.PreviewGroup>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      <Pagination
                        current={pagenumber}
                        onChange={(val) => setPageNumber(val)}
                        pageSize={12}
                        total={questiondata?.screenRecord?.length}
                      />
                    </div>
                  </>
                ),
              },
              {
                label: "CameraShots",
                key: "2",
                children: (
                  <>
                    <div className={styles.screenshots}>
                      {" "}
                      <Image.PreviewGroup>
                        {questiondata?.cameraRecord?.length > 0
                          ? questiondata.cameraRecord
                              .slice((pagenumber - 1) * 12, pagenumber * 12)
                              .reverse()
                              .map((item, index) => {
                                return (
                                  <Image
                                    src={item}
                                    key={index}
                                    width={100}
                                    preview={true}
                                  />
                                );
                              })
                          : "No CameraShots"}
                      </Image.PreviewGroup>
                    </div>
                    <div className="d-flex justify-content-center mt-2">
                      <Pagination
                        current={pagenumber}
                        onChange={(val) => setPageNumber(val)}
                        total={questiondata?.cameraRecord?.length}
                      />
                    </div>
                  </>
                ),
              },
            ]}
          />
          <div className="d-flex flex-column mt-2">
            <span>Feedback (optional)</span>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              rows="4"
              style={{ height: "auto", maxHeight: "100px" }}
            />
            <div className="d-flex justify-content-end mt-2">
              <Button ghost type="primary" loading={loading} disabled={loading} onClick={() => Submit()}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openWebview}
        onCancel={() => setOpenWebView(false)}
        width={"100vw"}
        title="Output"
        centered
        style={{ height: "100vh" }}
        // closable={false}
        maskClosable={false}
        footer={null}
      >
        <iframe
          title="HTML"
          srcDoc={Output}
          width="100%"
          style={{ height: "88vh" }}
        />
      </Modal>
    </div>
  );
};

export default Codereview;
