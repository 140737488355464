import styles from "../User/Mock/NewInstruction.module.css";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Switch } from "antd";
import { useSelector } from "react-redux";
import { ExportOutlined } from "@ant-design/icons";
import {
  // StartCamera,
  startRecording,
  stopScreenSharing,
  StartTakingScreenshots,
  // StartScreenShots,
  // stopScreenshots,
  // StopCamera,
  StopTakingScreenshots,
  StartSendingScreenshots,
  StopSendingScreenshots,
} from "../Services/RecordingCam";
// import Webcam from "react-webcam";

const PracticeInstruction = () => {
  // const [cameraallowed, setCameraallowed] = useState(false);
  const [screenallowed, setScreenallowed] = useState(false);
  // const [openmodal, setOpenModal] = useState(false);
  // const [error, setError] = useState("");
  const { user,clientdetails,access } = useSelector((state) => state.Data);
  const { qid, gid, ind } = useParams();
  const [redirected, setRedirected] = useState(false);
  // const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const sectionRefs = [useRef(null), useRef(null), useRef(null)];
  const scrollToSection = (index) => {
    if (sectionRefs[index] && sectionRefs[index].current) {
      sectionRefs[index].current.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {}, [qid, gid, ind]);

  // const videoConstraints = {
  //   width: 1300,
  //   height: 800,
  //   facingMode: "user",
  // };

  const AllowScreen = () => {
    startRecording()
      .then((res) => {
        if (res) {
          setScreenallowed(true);
        } else {
          let confirm = window.confirm(
            "Please Select Full Screen or Entire Screen"
          );
          if (confirm) {
            window.location.reload(true);
          }
        }
      })
      .catch((err) => {
        let confirm = window.confirm(
          "Please Select Full Screen or Entire Screen"
        );
        if (confirm) {
          window.location.reload(true);
        }
      });
  };

  // const AllowCamera = () => {
  //   setLoading(true);
  //   StartCamera()
  //     .then((res) => {
  //       if (res) {
  //         setOpenModal(false);
  //         setCameraallowed(true);
  //         setLoading(false);
  //       } else {
  //         setOpenModal(true);
  //         setLoading(false);
  //         setError(
  //           "Kindly Allow Camera and Microphone.Give the permission for camera or check if camera is working fine or not."
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setOpenModal(true);

  //       setError(err.message ?? "Kindly Allow Camera and Microphone");
  //     });
  // };

  useEffect(() => {
    if (
      localStorage.getItem("redirectionOrigin") ===
      `/practice/${qid}/${gid}/${ind}`
    ) {
      // stopScreenshots();
      StopTakingScreenshots();
      stopScreenSharing();
      // StopCamera();
      StopSendingScreenshots();
      localStorage.removeItem("redirectionOrigin");
      localStorage.removeItem("isshared");
      localStorage.removeItem("qno");
      navigate("/user");
    }
  }, [gid, ind, qid, navigate]);

  const disableRightClick = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (
     access&&access?.role!=="Admin"
    ) {
      document.addEventListener("contextmenu", disableRightClick);
      return () => {
        document.removeEventListener("contextmenu", disableRightClick);
      };
    }
  }, [access]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (
        localStorage.getItem("redirectionOrigin") !==
          `/practice/${qid}/${gid}/${ind}` &&
       
        screenallowed
      ) {
        event.preventDefault();
        event.returnValue = "";
        localStorage.removeItem("isshared");
      } else {
        localStorage.removeItem("isshared");
        localStorage.removeItem("redirectionOrigin");
        localStorage.removeItem("isshared");
        localStorage.removeItem("qno");
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, [ screenallowed, gid, qid, ind]);
  return (
    <div className={styles.main}>
      <Modal
        maskClosable={false}
        closable={false}
        centered
        footer={null}
        title="Redirected"
        open={redirected}
      >
        <div>
          <h5>
            You have been redirected to a new page. <ExportOutlined />
          </h5>
          <p>
            <strong>
              Don't refresh or close this page before saving your progress.
            </strong>
          </p>
        </div>
      </Modal>
     

      <div className={styles.leftSide}>
        <section>
          <div
            onClick={() => {
              navigate("/user");
            }}
          >
            <img src={clientdetails?.logo} alt="logo" />
         
          </div>
        </section>
        <main>
          <div>
            <label>Hey! {user?.FullName}</label>
          </div>
          <div>
            <p>
              Welcome to <span> Practice Section</span>
            </p>
          </div>
          <section>
            <main className={styles.practiceMode}>
              <label>Test Duration</label>
              <p>There is no time limit for practice mode.</p>
            </main>
          </section>
        </main>
      </div>
      <div className={styles.rightSide}>
        <section className={styles.fristSection} ref={sectionRefs[0]}>
          <div>
            <h3>Instructions</h3>
           <ol>  <li>
              Quiet and controlled space, suitable seating arrangements, and
              adequate lighting.
            </li>
            <li>Good Internet Connection.</li>
            <li> Don't refresh the page while doing practice.</li></ol>
          

            <button
              onClick={() => {
                scrollToSection(1);
                navigate(`/access/${qid}/${gid}/${ind}`);
              }}
            >
              Next
            </button>
          </div>
        </section>
        <section className={styles.secondSection} ref={sectionRefs[1]}>
          <div className={styles.instruction_container}>
            <div className={styles.headings}>
              <h2 className={styles.ins}>Device Access</h2>
            </div>
            <div className={styles.headings2}>
              <label className={styles.instructions}>
                Please check and allow the screen access to continue.
              </label>
            </div>
            <div className={styles.accesscontrol}>
             
              <div
                className={`${styles.acceeslblswitch} ${styles.windowwidth}`}
              >
                <label className={styles.accesslabel}>Screen Recording</label>

                <Switch
                  onChange={(e) => (e ? AllowScreen() : null)}
                  disabled={screenallowed}
                  checked={screenallowed}
                  className="mx-3"
                  
                />
              </div>
            </div>

            <div className={styles.container}>
              {/* {cameraallowed && (
                <div className={styles.camera}>
                  <Webcam
                    audio={false}
                    height={"100%"}
                    width={"100%"}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    className={styles.cam}
                    mirrored={true}
                  />
                </div>
              )} */}

              <div className={styles.buttons}>
                { screenallowed && (
                  <div>
                    <button
                      className={styles.next_btn}
                      onClick={() => {
                        // StartScreenShots(60000);
                        StartTakingScreenshots(60000);
                        StartSendingScreenshots(210000);
                        window.open(`/practice/${qid}/${gid}/${ind}`, "_blank");
                        setRedirected(true);
                      }}
                    >
                      Start
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PracticeInstruction;
