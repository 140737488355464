import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import styles from "./practicedetails.module.css";
import { Space, Table, Tag, Modal } from "antd";

const PracticeDetails = ({ data, groupid, index }) => {
  const [difficultyFilter, setDifficultyFilter] = useState([]);
  const [tagsfilter, setTagfilter] = useState([]);
  const [sortAscending, setSortAscending] = useState(true); // State to track sorting order
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");

  const navigate = useNavigate();
  const concepts = [...data.Easy, ...data.Medium, ...data.Hard]
    .flatMap((item) => item.concept)
    .filter((value, index, self) => self.indexOf(value) === index);
  // Table Column
  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      align: "left",
      key: "question",
      width: "40%",
      responsive: ["md", "sm"],

      render: (_, record) => (
        <Space size="middle">
          <div
            style={{ maxWidth: "500px", maxHeight: "110px", overflow: "auto" }}
          >
            <p
              style={{ margin: 0 }}
              dangerouslySetInnerHTML={{ __html: record?.question }}
            />
          </div>
        </Space>
      ),
    },
    {
      title: "Concepts",
      dataIndex: "tags",
      align: "center",
      key: "tags",
      responsive: ["md", "sm"],
      filters: (concepts || []).map((item) => ({
        text: item,
        value: item,
      })),
      onFilter: (value, { tags }) => {
        return tags.includes(value);
      },
      filteredValue: tagsfilter.length > 0 ? tagsfilter : null,
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return (
              tag !== "Practice" && <Tag key={tag}>{tag.toUpperCase()}</Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Time Spend",
      key: "time_spend",
      dataIndex: "time_spend",
      align: "center",
      responsive: ["md", "sm"],
      sorter: (a, b) => a.time_spend - b.time_spend,
      render: (_, record) => {
        if (record?.time_spend === 0) {
          return 0;
        } else if (record?.time_spend <= 60) {
          return record?.time_spend + " min(s)";
        } else {
          const hours = Math.floor(record?.time_spend / 60);
          const remainingMinutes = record?.time_spend % 60;
          return `${hours} hr ${remainingMinutes} min(s)`;
        }
      },
    },
    {
      title: "Difficulty",
      dataIndex: "difficulty",
      align: "center",
      key: "difficulty",
      responsive: ["md", "sm"],
      filters: [
        { text: "Easy", value: "Easy" },
        { text: "Medium", value: "Medium" },
        { text: "Hard", value: "Hard" },
      ],
      filteredValue: difficultyFilter.length > 0 ? difficultyFilter : null,
      onFilter: (value, record) => {
        if (difficultyFilter.length === 0) return true;
        return difficultyFilter.includes(record.difficulty);
      },
      sorter: (a, b) => {
        if (sortAscending) {
          return compareDifficultyAscending(a.difficulty, b.difficulty);
        } else {
          return compareDifficultyDescending(a.difficulty, b.difficulty);
        }
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      responsive: ["md", "sm"],
      render: (_, record) => (
        <Space size="middle">
          {_?.answer[_.answer.length - 1]?.issubmitted ? (
            <CheckCircleOutlined style={{ fontSize: "20px", color: "green" }} />
          ) : (
            <Button
              onClick={() =>
                navigate(`/instructions/${_.qid}/${groupid}/${index}`)
              }
            >
              {_?.answer.length === 0 ? "Start" : "Continue"}
            </Button>
          )}
        </Space>
      ),
    },
    {
      title: "Feedback",
      key: "feedback",
      align: "center",
      responsive: ["md", "sm"],
      render: (_, record) => {
        if (record?.feedback) {
          return (
            <label
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpen(true);
                setFeedback(record);
              }}
            >
              View
            </label>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const CalculateTotalTime = (val) => {
    if (val.answer.length === 0) {
      return 0;
    } else {
      const totalMinutes = val.answer.reduce((total, time) => {
        const startDate = new Date(time.starttime);
        const endDate = new Date(time.endtime);

        // Calculate the difference in milliseconds
        const difference = Math.abs(endDate - startDate);
        // Convert milliseconds to minutes
        const minutes = Math.round(difference / (1000 * 60));

        // Accumulate total minutes
        return total + minutes;
      }, 0);

      return totalMinutes;
    }
  };

  // Table Data
  function transformMergedObject(mergedObject) {
    return mergedObject.map((item) => ({
      key: item.qid,
      qid: item.qid,
      difficulty: item.level,
      question: item.title ?? item.qstn.question,
      tags: item.concept,
      answer: item.answer,
      title: item?.title,
      que: item.qstn.question,
      time_spend: CalculateTotalTime(item),
      feedback: item?.feedback ?? "",
      explanation:item?.explanation??""
    }));
  }

  const transformedArray = transformMergedObject([
    ...data.Easy,
    ...data.Medium,
    ...data.Hard,
  ]);

  // Custom comparator functions for sorting
  const compareDifficultyAscending = (a, b) => {
    if (a === "Easy") return -1;
    if (b === "Easy") return 1;
    if (a === "Medium" && b === "Hard") return -1;
    if (a === "Hard" && b === "Medium") return 1;
    return 0; // If both are Medium or both are Hard, maintain current order
  };

  const compareDifficultyDescending = (a, b) => {
    if (a === "Hard") return -1;
    if (b === "Hard") return 1;
    if (a === "Medium" && b === "Easy") return -1;
    if (a === "Easy" && b === "Medium") return 1;
    return 0; // If both are Medium or both are Easy, maintain current order
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (filters && filters.tags) {
      setTagfilter(filters.tags);
    } else {
      setTagfilter([]);
    }

    if (sorter && sorter.field === "difficulty") {
      setSortAscending(sorter.order === "ascend");
    }
    if (filters && filters.difficulty) {
      setDifficultyFilter(filters.difficulty);
    } else {
      setDifficultyFilter([]);
    }
  };



  return (
    <>
      <div className={styles.main}>
        <h5 className="mt-3 mb-3">{data.maintopics}</h5>
        <Table
          columns={columns}
          dataSource={transformedArray}
          onChange={handleTableChange}
          bordered={false}
          size="small"
        />
      </div>
      <Modal
        open={open}
        title="Feedback"
        footer={null}
        onCancel={() => {
          setOpen(false);
          setFeedback("");
        }}
      >
        <div>
          <p>{feedback?.feedback}</p>
        </div>

        {feedback?.explanation && (
          <div>
            <label><b>Explanation</b></label>
            <p>{feedback?.explanation}</p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PracticeDetails;
