import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { postrequest } from "../../../Middleware/managerequest";
import { Modal, Button, Form } from "react-bootstrap";
import { replaceExperience } from "../../../../store/ExperienceSlice";

function EditExpModal({ show, setShow, editExp, setEditExp }) {
  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch();
  const handleClose = () => setShow(false);
  const handleSave = () => {
    // Calculate the experience duration
    let experienceString = "";
    if (editExp?.start && editExp?.end) {
      const start = new Date(editExp?.start);
      const end = editExp?.currentlyWorking
        ? new Date()
        : new Date(editExp?.end);
      const years = end.getFullYear() - start.getFullYear();
      const months = end.getMonth() - start.getMonth();
      experienceString = `${years} Years ${months} Months`;
    } else {
      experienceString = "Working";
    }
    postrequest(
      `/api/v1/user/personal_details/${user?.Email}/?sec=experience&&operation=edit&&ind=${editExp?.id}`,
      {
        title: `${editExp.title}`,
        experience:experienceString,
        description: editExp.description,
        start: editExp.start,
        end: editExp.end,
        currentlyWorking: editExp.currentlyWorking,
      }
    )
      .then((res) => {
        dispatch(replaceExperience(res.data.Experience));
      })
      .catch((err) => console.log(err));

    // Call the onSave function with the experience object
    // onSave(experience);
    setEditExp({ ...editExp, total: experienceString });
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Experience</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="companyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              value={editExp.description}
              onChange={(e) =>
                setEditExp({ ...editExp, description: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="position">
            <Form.Label>Position</Form.Label>
            <Form.Control
              type="text"
              value={editExp.title}
              onChange={(e) =>
                setEditExp({ ...editExp, title: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="startDate">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={editExp?.start}
              onChange={(e) =>
                setEditExp({ ...editExp, start: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group controlId="endDate">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={editExp.end}
              onChange={(e) => setEditExp({ ...editExp, end: e.target.value })}
              disabled={editExp?.currentlyWorking}
              min={editExp.start}
            />
          </Form.Group>
          <Form.Group controlId="currentlyWorking">
            <Form.Check
              type="checkbox"
              label="Currently Working Here"
              checked={editExp?.currentlyWorking}
              onChange={(e) =>
                setEditExp({
                  ...editExp,
                  currentlyWorking: !editExp.currentlyWorking,
                })
              }
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditExpModal;
