import React from 'react'
import styles from "./latestnav.module.css"
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';

const LatestNavbar = ({setOpenDwa,openDwa,groupname}) => {
      const navigate=useNavigate()
      const {Data,StudentProfile}=useSelector(state=>state)
      const {clientdetails,admin}=Data
      const {imagesurl}=StudentProfile
  return (
      <div className={styles.navbar}>
      <div className={styles.leftNavbar}> 
        <span >
            <Avatar icon={<HomeOutlined/>} shape='square' src={clientdetails?.logo}  onClick={()=>navigate(admin?"/admin":"/user")} alt="logo"  style={{cursor:"pointer",marginBottom:"10px",width:"auto",height:"45px"}}/>
          {/* <label onClick={()=>navigate(admin?"/admin":"/user")} className={styles.clientname}>{clientdetails.companyname}</label> */}
        </span>
      </div>
      <div>
        {groupname}
      </div>

      <Avatar icon={<UserOutlined/>} src={imagesurl??""} style={{cursor:"pointer"}} onClick={()=>setOpenDwa(!openDwa)}/>
        
    </div>
  )
}

export default LatestNavbar