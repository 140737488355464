import React, { useCallback, useEffect, useState } from "react";
import { Image, Input, Layout, Modal, message } from "antd";
import {
  HomeOutlined,
  ContainerOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  EditOutlined,
  MailOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import DashboardSlider from "../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../Navigator_Footer/ProfileSlider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./AdminHome.module.css";
import styles1 from "./ManageSettings.module.css";
import LatestNavbar from "../Navigator_Footer/LatestNavbar";
import { useDispatch } from "react-redux";
import { setIssubscribed } from "../../store/Reducer";
import { getrequest, putrequest } from "../Middleware/managerequest";
import { FaLinkedin, FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";

// Menu
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const { Content, Sider } = Layout;
const ManageSettings = () => {
  const [messageApi, contentHolder] = message.useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.Data);
  const { user, clientdetails } = data;
  const [openDwa, setOpenDwa] = useState(false);
  const [clients, setClients] = useState();
  const [edit, setEdit] = useState(false);
  const [editdata, setEditdata] = useState({
    Mobile: "",
    Email: "",
    Address: "",
    linkedIn: "",
    website: "",
  });
  // const [userData, setUserdata] = useState(null);
  // const [AdminData, setAdmindata] = useState(null);
  // Menu Items
  const menuItems = [
    getItem(
      <span className={styles.menulabel}>Dashboard</span>,
      "admin",
      <HomeOutlined style={{ fontWeight: "600", fontSize: "17px" }} />
    ),
    getItem(
      <span className={styles.menulabel}>Create One Time Test</span>,
      "createmock",
      <ContainerOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null,
      true
    ),

    getItem(
      <span className={styles.menulabel}>Question Bank</span>,
      "questions",
      <UnorderedListOutlined style={{ fontWeight: "600", fontSize: "17px" }} />,
      null,
      null
    ),
    (clientdetails?.isgroup ?? false) &&
      getItem(
        <span className={styles.menulabel}>Batches</span>,
        "managegroups",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
    "main" === user?.subdomain &&
      getItem(
        <span className={styles.menulabel}>Manage Organizations</span>,
        "my_organizations",
        <UsergroupAddOutlined
          style={{ fontWeight: "600", fontSize: "17px" }}
        />,
        null,
        null
      ),
  ];

  // Sidebar Navigation
  const handlerClick = (e) => {
    if (["admin", "managegroups", "questions", "createmock"].includes(e.key)) {
      navigate(`/${e.key}`);
    }
  };

  const fetcher = useCallback(() => {
    getrequest(`/fetchclient_details/${user?.subdomain}`)
      .then((res) => setClients(res.data))
      .catch((err) => console.log(err));
  }, [user]);

  useEffect(() => {
    if (user) {
      fetcher();
    }
  }, [user, fetcher]);

  const HandleFileSubmit = (val) => {
    const formval = new FormData();
    Object.values(val).forEach((val1) => {
      formval.append("mylogo", val1);
    });
    putrequest(`/editclient/${user?.subdomain}?type=logo`, formval)
      .then((res) => {
        dispatch(setIssubscribed(res.data));
      })
      .catch((err) => console.log(err));
  };

  const HandleSubmit = () => {
    const { Mobile, linkedIn, website, Address } = editdata;
    if (Mobile.toString().length !== 10 && Mobile.length !== 10) {
      messageApi.warning({
        content: "Mobile should be validate. 🥺",
      });
      return;
    } else if (Address === "" || Address === undefined) {
      messageApi.warning({
        content: "Kindly fill the address. 🏠",
      });
      return;
    } else if (linkedIn === "" || linkedIn === undefined) {
      messageApi.warning({
        content: "Kindly Add LinkedIn. 🙄",
      });
      return;
    } else if (website === "" || website === undefined) {
      messageApi.warning({
        content: "Kindly Add Website Link",
      });
      return;
    }
    const obj = {
      Mobile,
      Address,
      linkedIn,
      website,
    };
    const key = "edit";
    messageApi.loading({
      content: "Saving details .😳",
      key,
    });
    putrequest(`/updateclient_contact/${user?.subdomain}`, obj)
      .then(() => {
        messageApi.success({
          content: "Saved Succefully. 😁",
          key,
        });
        fetcher();
        setEdit(false);
      })
      .catch((err) => {
        messageApi.error({
          content: err.response.data,
        });
      });
  };

  return (
    <>
      {contentHolder}
      <Modal
        maskClosable={false}
        open={edit}
        onOk={() => HandleSubmit()}
        onCancel={() => setEdit(false)}
        closable={false}
        title="Edit"
      >
        <div>
          <label className={styles1.titles}>Mobile</label>
          <Input
            type="number"
            onChange={(e) =>
              setEditdata((prev) => ({ ...prev, Mobile: e.target.value }))
            }
            value={editdata?.Mobile}
            maxLength={10}
            minLength={10}
          />
        </div>
        <div>
          <label className={styles1.titles}>Address</label>
          <Input.TextArea
            onChange={(e) =>
              setEditdata((prev) => ({ ...prev, Address: e.target.value }))
            }
            value={editdata.Address}
          />
        </div>
        <div>
          <label className={styles1.titles}>Official LinkedIn</label>
          <Input
            onChange={(e) =>
              setEditdata((prev) => ({ ...prev, linkedIn: e.target.value }))
            }
            value={editdata.linkedIn}
          />
        </div>
        <div>
          <label className={styles1.titles}>Official Website</label>
          <Input
            onChange={(e) =>
              setEditdata((prev) => ({ ...prev, website: e.target.value }))
            }
            value={editdata.website}
          />
        </div>
      </Modal>

      <Layout>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>
        <Layout>
          <LatestNavbar setOpenDwa={setOpenDwa} openDwa={openDwa} />
          <Content style={{ marginTop: "65px" }}>
            <div className={styles1.clientdetails}>
              <div className="d-flex flex-column align-items-center">
                <div
                  className={styles.profilePic}
                  style={{
                    position: "relative",
                    padding: clientdetails?.logo !== undefined ? "0px" : "15px",
                  }}
                >
                  {clientdetails?.logo !== undefined ? (
                    <Image
                      src={clientdetails?.logo}
                      style={{
                        height: "90px",
                        width: "90px",

                        objectFit: "cover",
                      }}
                      alt="Profile Photo"
                      preview={{
                        onPreview: (e) => {
                          e.stopPropagation(); // Stop propagation here
                        },
                      }}
                    />
                  ) : (
                    <UserOutlined />
                  )}
                  <label htmlFor="logo">
                    <div
                      style={{
                        position: "absolute",
                        fontSize: "16px",
                        backgroundColor: "aliceblue",
                        color: "black",
                        paddingInline: "4px",
                        borderRadius: "55%",
                        bottom: "2px",
                        right: "0px",
                        border: "1px solid black",
                        cursor: "pointer",
                      }}
                    >
                      <EditOutlined />
                    </div>
                  </label>
                </div>
                <input
                  onChange={(e) => {
                    HandleFileSubmit(e.target.files);
                    e.target.value = "";
                  }}
                  type="file"
                  accept=".svg,.png,.jpeg"
                  style={{ display: "none" }}
                  id="logo"
                />
              </div>
              <div className="mx-3 d-flex  flex-column">
                <h2>{clientdetails?.companyname}</h2>{" "}
                <div className={styles1.social}>
                  <span
                    onClick={() => {
                      window.open(clients?.linkedIn, "_blank");
                    }}
                    className="mx-3"
                    style={{ cursor: "pointer" }}
                  >
                    <FaLinkedin title={clients?.linkedIn ?? "LinkedIn"} />
                  </span>
                  <span
                    onClick={() => {
                      window.open(clients?.website, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <GlobalOutlined title={clients?.website ?? "website"} />
                  </span>
                </div>
              </div>
            </div>
            {clients && (
              <div className={styles1.contact_info}>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 style={{ fontFamily: "Poppins", fontWeight: "500" }}>
                    Contact Information
                  </h5>
                  <EditOutlined
                    onClick={() => {
                      setEdit(true);
                      setEditdata(clients);
                    }}
                    style={{ fontSize: "18px", cursor: "pointer" }}
                  />
                </div>

                <div className={styles1.contactsDiv}>
                  <div className={styles1.AddEle}>
                    <div className={styles1.icon}>
                      <MailOutlined
                        style={{ color: "black", fontSize: "15px" }}
                      />
                    </div>
                    <div className={styles1.innerContact}>
                      <h6>Email</h6>
                      <p>{clients?.Email}</p>
                    </div>
                  </div>
                  <div className={styles1.AddEle}>
                    <div className={styles1.icon}>
                      <FaMapMarkerAlt
                        style={{ color: "black", fontSize: "15px" }}
                      />
                    </div>
                    <div className={styles1.innerContact}>
                      <h6>Address</h6>
                      <p>{clients?.Address}</p>
                    </div>
                  </div>
                  <div className={styles1.AddEle}>
                    <div className={styles1.icon}>
                      <FaPhoneAlt
                        style={{ color: "black", fontSize: "15px" }}
                      />
                    </div>
                    <div className={styles1.innerContact}>
                      <h6>Phone</h6>
                      <p>{clients?.Mobile}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </>
  );
};

export default ManageSettings;
