import Modal from "react-bootstrap/Modal";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { useCallback, useState } from "react";
import {toast,ToastContainer} from "react-toastify"
import "../../../styles/TestCase.css";
import { FaQuestionCircle } from "react-icons/fa";
import { FloatingLabel, Form, Button } from "react-bootstrap";
import test from "../../../utils/Test Case.pdf";
import { postrequest } from "../../Middleware/managerequest";
const languages = [
  { language: "javascript", extension: "js" },
  { language: "python", extension: "py" },
  {language:"java",extension:"java"} 
];

function TestCases(props) {
  const [language, setLanguage] = useState("javascript");
  const [codeinput, setInput] = useState(props.Test ? props.Test.BaseCode : "");
  const [isinfinite, setInfinite] = useState(false);
  const [output, setOutput] = useState({stdout:props?.Test?.Output});
  const [finaloutput, setFinaloutput] = useState(
    props.Test ? props.Test.Output : ""
  );
  const [compilerstatus, setCompilerStatus] = useState(false);
  const [codeerror, setCodeerror] = useState("");
  const [TestName, setTestName] = useState(
    props.Test ? props.Test.TestName : ""
  );

  const OnSubmit = useCallback(() => {
    setCodeerror("");
    if (TestName === "") {
      setCodeerror("Please Enter Your Test Name ");
    } else if (codeinput === "") {
      setCodeerror("Please Write Your Base Code!");
    } else if (
      finaloutput === "" ||
      finaloutput.split("\n").includes("undefined") === true
    ) {
      setCodeerror("Please Test Your Code First for Saving right Output!");
    } else if (finaloutput !== "" || codeinput !== "") {
      let confirm = window.confirm(
        "Make Sure You Test You Own Code and The Output is Right! \n Remove Your Own Testing Code Before Submit. Only Write Base Code."
      );
    
      if (confirm) {
        let obj = {
          TestName: TestName,
          BaseCode: codeinput,
          Output: finaloutput,
        };
  
  props.handleModal(obj);
      }
    }
  }, [codeinput, finaloutput, TestName, props]);

  const onChange = (e) => {
    setInput(e);
    setCompilerStatus(false);
    setInfinite(false);
    setCodeerror("");
  };

  const Test = useCallback(() => {
    const find = languages.find((item) => item.language === language);
    setCodeerror("");
    const data = {
      files: [
        {
          name: `Main.${find.extension}`,
          content: codeinput,
        },
      ],
    };

    if (codeinput.length !== 0) {
      setCompilerStatus(true);
      postrequest(`/compiler/${language}`,data)
        .then((res) => {
          setOutput(res.data.data);
          setFinaloutput(res.data.data.stdout);
          setCompilerStatus(false);
          setInfinite(false);
        })
        .catch((err) => {
        
          if (err.response.status === 500) {
            setInfinite(true);
            setCompilerStatus(false);
          }
          else if(err.response.status===408){
            toast.warning("server error",{position:"top-right",autoClose:1500})
          }
        });
    }
  }, [codeinput, language]);

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.handleModal}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Test Cases</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <FloatingLabel label="Test Name">
            <Form.Control
              type="text"
              placeholder="Test Name"
              onChange={(e) => setTestName(e.target.value)}
              value={TestName}
            />
          </FloatingLabel>
        </Form>
        <div className="d-flex justify-content-between mx-3 mt-3">
          <div>
            <label>Select Language :</label>{" "}
            <select onChange={(e) => setLanguage(e.target.value)}>
              {languages.map((item, index) => {
                return (
                  <option key={index} value={item.language}>
                    {item.language}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <a href={test} style={{ cursor: "pointer" }} download>
              <FaQuestionCircle title="Instructions" />
              <u className="mx-1">Instructions</u>
            </a>
          </div>
        </div>
<ToastContainer/>
        <div className="mt-3" style={{ width: "100%" }}>
          {" "}
          <div>
            <label style={{ width: "67%", textAlign: "center" }}>
              Write Your Base Code Here!
            </label>
            <label style={{ width: "25%" }}>Output Should Be</label>
          </div>
          <div
            style={{ width: "100%" }}
            className="mt-2 d-flex justify-content-between"
          >
            <div style={{ width: "65%" }}>
              <CodeMirror
                value={codeinput}
                width="100%"
                height="275px"
                theme="dark"
                extensions={[
                  language === "Javascript"
                    ? javascript({ jsx: true })
                    : python(),
                ]}
                onChange={onChange}
                style={{ borderStyle: "none" }}
              />
              <label style={{ color: "red" }}>{codeerror}</label>
              <br />
            </div>
            <div style={{ width: "34%" }}>
              <div
                style={{
                  border: "1px solid",
                  height: "200px",
                  cursor: "pointer",
                  overflow: "auto",
                }}
              >
                <pre>
                  {output && (output.stdout || output.stderr )}
                  {compilerstatus && "Running"}
                  {isinfinite && "Infinite Loop"}
                </pre>
              </div>

              <br />

              <div
                style={{ width: "100%" }}
                className="d-flex flex-column justify-content-center align-items-center"
              >
                <Button
                  onClick={() => Test()}
                  style={{
                    borderRadius: "5px",
                    width: "100px",
                    fontWeight: "600",
                  }}
                  variant="outline-success"
                >
                  Test
                </Button>
                {output && (
                  <>
                    {output?.stdout &&
                    (   <label style={{ color: "green" }}>Test Pass</label>
                      )}
                    {(output.stderr ||output?.stdout?.length===0)  && (
                      <label style={{ color: "red" }}>Test Fail</label>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => OnSubmit()} variant="outline-primary">
          Confirm & Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default TestCases;
