import { createSlice } from "@reduxjs/toolkit";
const Data = {
  user: null,
  admin: false,
  islogin: false,
  Temp: null,
  resetpass: null,
  isgivingmock: false,
  issubscribed: false,
  access: null,
  IsSuperAdmin: null,
  mockcount: 0,
  mockdata: [],
  Reviewed: [],
  Attempted: [],
  Pending: [],
  Expired: [],
  Available: [],
  Upcoming: [],
  Attendance: [],
  category: [],
  PracticeAttendance: [],
  clientdetails: {
    companyname: "Digikull",
    issubscribed: false,
    mockcount: 0,
    isgroup: false,
    isusers: false,
    logo: "",
  },
};

const QuizData = createSlice({
  name: "quiz",
  initialState: Data,
  reducers: {
    setuser(state, action) {
      state.user = action.payload;
    },
    setName(state, action) {
      let newuser = { ...state.user };
      const { firstName, lastName } = action.payload;
      newuser["FullName"] = firstName + " " + lastName;

      localStorage.setItem("FullName", firstName + " " + lastName);
      // console.log(newuser,action.payload)
      state.user = newuser;
    },
    setIslogin(state, action) {
      state.islogin = action.payload;
    },
    setTemp(state, action) {
      state.Temp = action.payload;
    },
    reset(state, action) {
      state.resetpass = action.payload;
    },
    setAdmin(state, action) {
      state.admin = action.payload;
    },
    setCheckRunningMock(state, action) {
      state.isgivingmock = action.payload;
    },
    setIssubscribed(state, action) {
      const { isUsers, isGroup, MockCount, isSubscribed, logo } =
        action.payload;
      state.clientdetails = {
        companyname: action.payload.ClientName
          ? action.payload.ClientName.trim()
          : "Digikull",
        issubscribed: isSubscribed,
        mockcount: MockCount,
        isgroup: isGroup,
        isusers: isUsers,
        logo: logo,
      };
    },
    setDiffculties(state, action) {
      state.selectdiff = action.payload;
    },
    setcheckedDiff(state, action) {
      state.checkeddiff = action.payload;
    },
    setMockdata(state, action) {
      state.mockdata = action.payload;
    },

    setAttempted(state, action) {
      const { submittedItems, feedbackItems, newexpired, available, upcoming } =
        action.payload;
      state.Attempted = submittedItems;
      state.Expired = newexpired;
      state.Available = available;
      state.Reviewed = feedbackItems;
      state.Upcoming = upcoming;
    },

    setAccess(state, action) {
      state.access = action.payload;
    },
    setAttendance(state, action) {
      state.Attendance = action.payload;
    },
    setAttendancePractice(state, action) {
      state.PracticeAttendance = action.payload;
    },
    setIsSuperAdmin(state, action) {
      state.IsSuperAdmin = action.payload;
    },
    setCategory(state, action) {
      state.category = action.payload;
    },
  },
});
export const {
  setuser,
  setIslogin,
  setTemp,
  reset,
  setAdmin,
  setCheckRunningMock,
  setIssubscribed,
  setDiffculties,
  setcheckedDiff,
  setMockdata,
  setAttempted,
  setAccess,
  setAttendance,
  setName,
  setAttendancePractice,
  setIsSuperAdmin,
  setCategory
} = QuizData.actions;
export default QuizData.reducer;
