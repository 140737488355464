import axios from "axios";
import Cookies from "js-cookie";
const url = process.env.REACT_APP_BACKEND_SERVER;

async function getrequest(suburl) {
  let token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");
  let res = await axios.get(url + suburl, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });

  return res;
}

async function postrequest(suburl, data) {
  let token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");
  let res = await axios.post(url + suburl, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

async function putrequest(suburl, data) {
  let token =
    process.env.REACT_APP_ENVIRONMENT === "local"
      ? Cookies.get("digikull_local")
      : Cookies.get("unoprep");
  let res = await axios.put(url + suburl, data, {
    withCredentials: true,
    headers: { Authorization: `Bearer ${token}` },
  });
  return res;
}

export { getrequest, postrequest, putrequest };
