import { createSlice } from "@reduxjs/toolkit";

const initialState = {
      UpcomingSessions:[],
      ExpiredSessions:[]
}

const SessionsSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    replacesessions: (state, action) => {
      const {upcomingSessions,expiredSessions}=action.payload
      state.UpcomingSessions=upcomingSessions
      state.ExpiredSessions=expiredSessions
    },
  },
});

export const { replacesessions } = SessionsSlice.actions;

export default SessionsSlice.reducer;
