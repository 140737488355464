import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  
];

const ExperienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    replaceExperience: (state, action) => {
      return action.payload; // Replace the entire state with the new array
    },
  },
});

export const { replaceExperience } = ExperienceSlice.actions;

export default ExperienceSlice.reducer;
