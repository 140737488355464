import React, { useCallback, useEffect, useState } from "react";

import "../../styles/manageclients.css";
import Spinner from "react-bootstrap/esm/Spinner";
import Form from "react-bootstrap/Form";
import { AiFillSetting } from "react-icons/ai";
import { getrequest, putrequest } from "../Middleware/managerequest";
import { useSelector } from "react-redux";

const Manageorganization = () => {
  const [clientdata, setClientData] = useState(null);
  const {user}=useSelector(state=>state.Data)

  const fetcher=useCallback(()=>{
    getrequest(`/manageclients/${user?.subdomain}`)
      .then((res) => setClientData(res.data))
      .catch((err) => console.log(err));
  },[user])

  useEffect(() => {
    if(user){
      fetcher()
    }
  
  },[fetcher,user]);

  const SetClassName = (val) => {
      if (val === "Free") return "freeaccount accounttype";
      else return "paidaccount accounttype";
    };

  if (clientdata===null) {
    return (
      <div className="d-flex justify-content-center align-items-center h-50">
        <Spinner
          animation="border"
          size="lg"
          variant="warning"
          className="spinner"
        />
      </div>
    );
  }
 
  if (clientdata!==null && clientdata.length===0) {
    return (
      <div className="d-flex justify-content-center align-items-center h-50">
        <h3>No Organizations Registered</h3>
      </div>
    );
  }
 

  

  const changesubscription = (item, val) => {
      
 

    putrequest(`/changesubsciptions/${item.ClientId}/${val}`,"hello")
      .then(()=>fetcher())
      .catch((err) => console.log(err));
  };
 
  return (
    <div className="mt-5 pt-5">
     
      <div className="d-flex flex-column  align-items-center">
        <div className="d-flex justify-content-start" style={{ width: "80%" }}>
          <h3 className="mx-5">Manage Organizations</h3>
        </div>
        <div className="manage_main">
          {clientdata &&
            clientdata.map((item, index) => {
              return (
                <div className="manage_sub" key={index}>
                  <div className="manage_accounts mx-3 mt-2">
                    <label className={SetClassName(item.PlanType)}>
                      {item.PlanType}
                    </label>
                    <Form.Check
                      reverse
                      type="switch"
                      id="custom-switch"
                      label="Active"
                      checked={item.isSubscribed}
                      value={item.isSubscribed}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => changesubscription(item, e.target.value)}
                    ></Form.Check>
                  </div>
                  <div className="d-flex justify-content-start mx-3 align-items-center mt-2">
                    <img
                      src="https://personallogo.s3.ap-south-1.amazonaws.com/logo.png"
                      alt="clientlogo"
                      width={50}
                    />
                    <h3 className="mx-2">{item.ClientName}</h3>
                  </div>
                 
                  <div className="d-flex justify-content-end">
                    <label
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      className="mx-2"
                    >
                      <AiFillSetting title="Manage Account" />
                    </label>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Manageorganization;
