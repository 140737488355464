import { ScheduleOutlined } from "@ant-design/icons";
import { Table, Tag } from "antd";
import { useEffect, useState } from "react";
import { getrequest } from "../../Middleware/managerequest";

const UserAttendance = ({ username, gid }) => {
  const [attendance, setAttendance] = useState([]);
  const [loading,setLoading]=useState(true)

  useEffect(() => {
   
    getrequest(`/api/v1/user/my_attendance/${username}/${gid}`)
      .then((res) => {
        setLoading(false)
        if (!Array.isArray(res.data)) {
            let newattandance=res.data.Attendance.sort((a, b) => {
                // Convert Session_Start values to Date objects for comparison
                const dateA = new Date(a.Session_Start);
                const dateB = new Date(b.Session_Start);
              
                // Compare the Date objects
                return dateB - dateA;
              });
          setAttendance(newattandance);
        }
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error fetching attendance data:", error);
      });
  }, [username, gid]);

  const convertDateIntoNormal2 = (val) => {
    // Create a Date object from the input string
    const date = new Date(val);

    // Extract the day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

//   console.log(attendance)

  const columns = [
    {
      title: "Session Name",
      dataIndex: "Title",
      key: "Title",
      align: "left",
      render: (text) => <label>{text || "-"}</label>,
      width: 450,
      responsive:["sm"]
    },
    {
      title: "Session Date",
      dataIndex: "Session_Start",
      key: "Session_Start",
      align: "center",
      sorter:(a,b)=>{
        const dateA = new Date(a.Session_Start);
        const dateB = new Date(b.Session_Start);
        return dateA - dateB;
      },
      render: (text) => {
        if (text) {
          let splitted = text.split(",");
          return (
            <span>
              <label>{convertDateIntoNormal2(splitted[0])}</label>
              <br />
              <label>{splitted[1]}</label>
            </span>
          );
        } else {
          return "-";
        }
      },
      width: 150,
    },
    {
      title: "Session Duration",
      dataIndex: "Session_Duration",
      key: "Session_Duration",
      align: "center",
      render: (text) => <label>{text || "-"}</label>,
      width:150,
      responsive:["lg","md"]
    },
    {
      title: "My Duration",
      dataIndex: "Duration",
      key: "Duration",
      align: "center",
      render: (text) =><label>{text || "-"}</label> ,
      width:150,
      responsive:["lg","md"]
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      align: "center",
      width:150,
      filters:(attendance || [])
      .map((item) => ({
        text: item?.Status ,
        value: item?.Status ,
      }))
      .filter(
        (obj, index, self) =>
          index ===
          self.findIndex(
            (o) => o.label === obj.label && o.value === obj.value
          )
      ),
      onFilter:(val,record)=>{
        return record.Status.indexOf(val)===0
      },
      render: (status) => (
        <Tag  color={status === "Present" ? "green" : "red"}>
          <label>{status}</label>
        </Tag>
      ),
    },
  ];

  return (
    <>
     
      <div className="mx-3 px-3 d-flex justify-content-between flex-wrap" style={{ marginTop: "90px" }}>
       <h4 >
        <span style={{ marginRight: "14px" }}>
          <ScheduleOutlined />
        </span>
        Attendance Details
      </h4>
          
                    <div style={{ textAlign: 'center', color: '#666' }}>
                      
                            <b>Note: Sessions with a duration less than 50% are considered as absent.</b>
                       
                    </div>
            
            </div>
      <Table
        columns={columns}
        dataSource={
          attendance.length > 0 &&
          attendance.map((item, index) => ({
            ...item,
            key: index,
          }))
        }
        pagination={{
          position: ["bottomCenter"],
        }}
        scroll={{
          // x: "calc(700px + 50%)",
          y: "60vh",
        }}
        size="medium"
        loading={loading}
        
      />
    </>
  );
};

export default UserAttendance;
