import React, { useState, useEffect, useCallback } from "react";
import Spinner from "react-bootstrap/esm/Spinner";
import {
  Dropdown,
  Modal,
  Select,
  message,
  Result,
  Button,
  Pagination,
  Card,
  Typography,
  Input,
} from "antd";
import { SiMicrosoftexcel } from "react-icons/si";
import {
  UsergroupAddOutlined,
  MoreOutlined,
  UserAddOutlined,
  CaretRightOutlined,
  PlusCircleOutlined,
  UserSwitchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setAttendance, setAttendancePractice } from "../../../store/Reducer";

import {
  getrequest,
  postrequest,
  putrequest,
} from "../../Middleware/managerequest";
import styles from "./styles/ManageGroups.module.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import * as XLSX from "xlsx";
import DashboardSlider from "../../Navigator_Footer/DashboardSlider";
import ProfileSlider from "../../Navigator_Footer/ProfileSlider";
import LatestNavbar from "../../Navigator_Footer/LatestNavbar";
import Sider from "antd/es/layout/Sider";
const { Text } = Typography;

const ManageGroups = () => {
  const [pagenumber, setPagenumber] = useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [groupsdata, setGroupData] = useState([]);
  const [filtereddata, setFilteredData] = useState([]);
  const [ind, setInd] = useState(0);
  const [openDwa, setOpenDwa] = useState(false);
  const [addmember, setAddmember] = useState(false);
  const [Users, setUsers] = useState([]);
  const [Selecteduser, setSelecteduser] = useState([]);
  const [Exceluser, setExceluser] = useState([]);
  const [uniqueData, setUniquedata] = useState(0);
  const [search_query, setSearchQuery] = useState("");
  const [alreadypresent, setAlreadyPresent] = useState([]);
  const [emptydata, setEmptydata] = useState([]);
  const [assign, setAssign] = useState(false);
  const [update, setUpdate] = useState(false);
  const [menuKey, setMenuKey] = useState("");
  const [create, setCreate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [manageadmin, setManageAdmin] = useState(false);
  const [newAdmin, setNewAdmin] = useState([]);
  // const [Dropdown1, setDropdown1] = useState(false);
  const [finaldata, setFinaldata] = useState([]);
  const { user } = useSelector((state) => state.Data);
  const dispatch = useDispatch();
  let creategroupdata = {
    groupname: "",
    course: "",
    createdby: user?.FullName,
    members: [],
    admins: user?.Email,
    groupemail: "",
  };
  const [CreateGroup, setCreateGroup] = useState(creategroupdata);
  const [UpdateData, setUpdatedata] = useState("");

  const navigate = useNavigate();
  const fetcher = useCallback(() => {
    setLoading(true);
    if (user) {
      getrequest(`/home/getgroups/${user?.subdomain}`)
        .then((res) => {
          setGroupData(res.data.reverse());
          setFilteredData(res.data);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  }, [user]);

  useEffect(() => {
    fetcher();
    dispatch(setAttendance([]));
    dispatch(setAttendancePractice([]));
  }, [fetcher, dispatch]);

  // Search Query
  useEffect(() => {
    setPagenumber(1);

    let searcheddata = groupsdata
      .filter(
        (val) =>
          val.GroupName.toLowerCase().includes(search_query.toLowerCase()) ||
          val.Members.some(
            (user) =>
              user.label.toLowerCase().includes(search_query.toLowerCase()) ||
              user.value.toLowerCase().includes(search_query.toLowerCase())
          ) ||
          val.admins.some(
            (user) =>
              user.label.toLowerCase().includes(search_query.toLowerCase()) ||
              user.value.toLowerCase().includes(search_query.toLowerCase())
          )
      )
      .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));

    setFilteredData(searcheddata);
  }, [search_query, groupsdata]);

  // Warning Message
  const error = (val) => {
    messageApi.open({
      type: "warning",
      content: val,
    });
  };

  // Fetch Users
  const GetUsers = () => {
    getrequest(`/home/getallusers/${user?.subdomain}/${filtereddata[ind].id}`)
      .then((res) => {
        let arr = [];
        res.data.map((item, index) => {
          if (item?.name !== "group") {
            let obj = {
              value: item.Email,
              label: item.Name,
              name: item.name === undefined ? "users" : item.name,
            };
            arr.push(obj);
          }

          return true;
        });
        setUsers(arr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch Users for while create groups
  const FetchUsers = () => {
    if (user) {
      getrequest(`/allusers/${user?.subdomain}/false`)
        .then((res) => {
          let arr = [];
          res.data.map((item, index) => {
            let obj = {
              value: item.Email,
              label: item.Name,
              name: "users",
            };
            arr.push(obj);
            return true;
          });
          setUsers(arr);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Handle user change
  const handlechange = (e) => {
    setSelecteduser(e);
  };

  // Handle Excel File

  const findUniqueObjects = (arr1) => {
    let updateone = arr1.filter(
      (item, index, self) =>
        item.label !== undefined && item.value !== undefined
    );
    setEmptydata(updateone);

    let duplicate = updateone.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.value === item.value)
    );
    let AlreadyNotPresent = [];
    if (addmember) {
      const AlreadyPresent = duplicate.filter((item) =>
        filtereddata[ind].Members.some(
          (it) => it.value.toLowerCase() === item.value.toLowerCase()
        )
      );

      AlreadyNotPresent = duplicate.filter((item) =>
        filtereddata[ind].Members.every(
          (it) => it.value.toLowerCase() !== item.value.toLowerCase()
        )
      );
      setAlreadyPresent(AlreadyPresent);
    } else {
      AlreadyNotPresent = duplicate;
      setFinaldata(duplicate);
    }
    setUniquedata(duplicate);
    setFinaldata(AlreadyNotPresent);
  };
  // Convert sheet to array
  const convertInObject = (array) => {
    let arrayOfObjects = [];
    for (let i = 0; i < array.length; i++) {
      let innerArray = array[i];
      let obj = {
        label: innerArray[0],
        value: innerArray[1],
        phone: innerArray[2],
        name: "users",
      };
      arrayOfObjects.push(obj);
    }

    setExceluser(arrayOfObjects);
    findUniqueObjects(arrayOfObjects);
  };
  // Read The File
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        // Assuming the first sheet is the one you want to convert
        const sheetName = workbook.SheetNames[0];
        const excelArray = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetName],
          { header: 1 }
        );
        let newflt = excelArray.filter((item) => item.length !== 0);
        // Exclude the header row

        convertInObject(newflt.slice(1));
        event.target.value = "";
      };
      reader.readAsBinaryString(file);
    }
  };

  // Create Group
  const CreateGroupSubmit = () => {
    let newSelected = Selecteduser.map((item) => {
      let find = Users.find((ite) => item.trim("") === ite?.value) ?? {
        value: item,
        label: item,
        name: "users",
      };
      return find;
    });

    let duplicate = [...newSelected, ...finaldata]
      .filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.value.toLowerCase().trim("") ===
              item.value.toLowerCase().trim("")
          )
      )
      .map((item) => {
        item.value = item.value.toLowerCase().trim("");
        return item;
      });

    CreateGroup.members = duplicate;
    CreateGroup.createdby = user?.FullName;
    CreateGroup.admins = user?.Email;
    if (CreateGroup.name === "") {
      error("Please enter Batch Name");
    } else if (CreateGroup.course === "") {
      error("Please Select Course Name");
    } else if (CreateGroup.members.length === 0) {
      error("Please add at least one student");
    } else {
      setLoading(true);
      postrequest(`/home/creategroup/${user?.subdomain}`, CreateGroup)
        .then(() => {
          setLoading(false);
          fetcher();
          setCreate(false);
          setAlreadyPresent([]);
          setEmptydata([]);
          setUniquedata(0);

          setExceluser([]);
          setFinaldata([]);
          setSelecteduser([]);
          let creategroupdata = {
            groupname: "",
            course: "",
            createdby: user?.FullName,
            members: [],
            admins: user?.Email,
          };
          setCreateGroup(creategroupdata);
        })
        .catch((err) => setLoading(false));
    }
  };

  // Final Submit for add members
  const Submit = () => {
    let newSelected = Selecteduser.map((item) => {
      let find = Users.find((ite) => item === ite.value) ?? {
        value: item,
        label: item,
        name: "users",
      };
      return find;
    });

    let duplicate = [...newSelected, ...finaldata]
      .filter(
        (item, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.value.toLowerCase().trim("") ===
              item.value.toLowerCase().trim("")
          )
      )
      .map((item) => {
        item.value = item.value.toLowerCase().trim("");
        return item;
      });

    if (duplicate.length > 0) {
      let obj = {
        users: duplicate,
        role: "users",
      };
      setLoading(true);
      postrequest(
        `/home/addusers/${user.subdomain}/${filtereddata[ind].id}`,
        obj
      )
        .then(() => {
          fetcher();
          OnCancel();
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  };

  // On Modal Cancel
  const OnCancel = () => {
    setAddmember(false);
    setSelecteduser([]);
    setAlreadyPresent([]);
    setEmptydata([]);
    setUniquedata(0);

    setExceluser([]);
    setFinaldata([]);
    setAssign(false);
  };

  const StartNow = () => {
    getrequest(
      `/home/startgroup/${user?.subdomain}/${filtereddata[ind].id}?email=${user?.Email}`
    )
      .then(() => {
        fetcher();
      })
      .catch((err) => console.log(err));
  };

  const AssignMentor_Submit = () => {
    let mentor = Users.find((item) => item.value === Selecteduser);
    setLoading(true);
    putrequest(
      `/assignmentor/${filtereddata[ind].id}?username=${user?.Email}`,
      mentor
    )
      .then((res) => {
        setAssign(false);
        setSelecteduser([]);
        fetcher();
        setLoading(false);
        messageApi.success({
          content: res.data,
        });
      })
      .catch(() => {
        setAssign(false);
        setSelecteduser([]);
        setLoading(false);
        messageApi.info({
          content: "Error while assigning the mentor.",
        });
      });
  };

  const GetMentorsDetails = () => {
    getrequest(`/home/getmentors/${user?.subdomain}`).then((res) => {
      let arr = [];
      res.data.map((item, index) => {
        if (item?.name !== "group") {
          let obj = {
            value: item.Email,
            label: item.Name,
            name: item.name === undefined ? "users" : item.name,
          };
          arr.push(obj);
        }

        return true;
      });
      setUsers(arr);
    });
  };

  // Menu Items
  const items = [
    {
      key: 1,
      label: (
        <div
          className="d-flex align-item-center"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            GetUsers();
            setAddmember(true);
          }}
        >
          <UserAddOutlined style={{ cursor: "pointer" }} />
          <label style={{ cursor: "pointer" }} className="mx-3">
            Add Member
          </label>
        </div>
      ),
    },
    {
      key: 2,
      label: (
        <div
          className="d-flex align-item-center"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            setUpdate(true);
          }}
        >
          <MdOutlineAlternateEmail
            style={{ cursor: "pointer" }}
            className="mt-1"
          />

          <label className="mx-3" style={{ cursor: "pointer" }}>
            {filtereddata && filtereddata[ind]?.groupemail.length > 0
              ? "Update GroupId"
              : "Add GroupId"}
          </label>
        </div>
      ),
    },

    !filtereddata[ind]?.startdate && {
      key: 3,
      label: (
        <div
          className="d-flex align-item-center"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            StartNow();
          }}
        >
          <CaretRightOutlined style={{ cursor: "pointer" }} />
          <label className="mx-3" style={{ cursor: "pointer" }}>
            Start Now
          </label>
        </div>
      ),
    },
    {
      key: 4,
      label: (
        <div
          className="d-flex align-item-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            GetMentorsDetails();
            setAssign(true);
          }}
        >
          <PlusCircleOutlined style={{ cursor: "pointer" }} />
          <label style={{ cursor: "pointer" }} className="mx-3">
            Assign To
          </label>
        </div>
      ),
    },
    {
      key: 7,
      label: (
        <div
          className="d-flex align-item-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            GetMentorsDetails();
            setManageAdmin(true);
          }}
        >
          <UserSwitchOutlined style={{ cursor: "pointer" }} />
          <label style={{ cursor: "pointer" }} className="mx-3">
            Manage Admin
          </label>
        </div>
      ),
    },
    // {
    //   key: "4",
    //   label: (
    //     <>
    //       {filtereddata.length > 0 &&
    //         user &&
    //         (user.Email === filtereddata[ind].createdby ||
    //           access &&access.role==="Admiin"&& (
    //           <div
    //             className="d-flex align-item-center pt-2"
    //             style={{ borderTop: "1px solid" }}
    //             onClick={(e) => {
    //               DeleteGroup(filtereddata[ind].id);
    //               e.stopPropagation();
    //               e.preventDefault();
    //             }}
    //           >
    //             <DeleteOutlined />
    //             <label className="mx-3">Delete Team</label>
    //           </div>
    //         )}
    //     </>
    //   ),
    // },
  ];

  const UpdateOperation = () => {
    if (
      filtereddata[ind]?.groupemail.length > 0 &&
      filtereddata[ind]?.groupemail.includes(UpdateData?.groupemail)
    ) {
      messageApi.info({
        content: "Email already present",
      });
    } else {
      setLoading(true);
      putrequest(
        `/updategroupdetails/${filtereddata[ind].id}/?op=groupid`,
        UpdateData
      )
        .then((res) => {
          fetcher();
          setLoading(false);
          setUpdate(false);
          setUpdatedata("");
        })
        .catch((err) => {
          setLoading(false);
          setUpdate(false);
          setUpdatedata("");
        });
    }
  };

  const AddAdmins = () => {
    setLoading(true);
    const final = newAdmin.map((item) => {
      return {
        label: Users.find((val) => val.value === item).label,
        value: item,
      };
    });
    const key = "addadmin";
    messageApi.loading({
      content: "Adding New Admins",
      key,
    });

    putrequest(
      `/addadmin/${filtereddata[ind].id}/?user=${user.FullName}&email=${user.Email}`,
      final
    )
      .then(() => {
        fetcher();
        setLoading(false);
        setManageAdmin(false);
        setNewAdmin([]);
        messageApi.success({
          content: "Added Admin Successfully",
          duration: 2,
          key,
        });
      })
      .catch((err) => {
        setLoading(false);
        setManageAdmin(false);
        setNewAdmin([]);

        messageApi.error({
          content: err.response.data,
          key,
        });
      });
  };

  const RemoveAdmin = (val) => {
    const key = "addadmin";
    messageApi.loading({
      content: "Removing Admin",
      key,
    });

    getrequest(
      `/removeadmin/${filtereddata[ind].id}/?name=${user.FullName}&email=${user.Email}&user=${val}`
    )
      .then((res) => {
        fetcher();
        setNewAdmin([]);
        messageApi.success({
          content: "Removed Admin Successfully",
          duration: 2,
          key,
        });
      })
      .catch((err) => {
        setManageAdmin(false);
        setNewAdmin([]);

        messageApi.error({
          content: err.response.data,
          key,
        });
      });
  };

  return (
    <div className={styles.main}>
      <Modal
        footer={null}
        onCancel={() => {
          setManageAdmin(false);
          setNewAdmin([]);
        }}
        width={700}
        open={manageadmin}
        title="Manage Admin"
      >
        <div>
          <Select
            maxTagCount={"responsive"}
            style={{ width: "300px" }}
            value={newAdmin}
            onChange={(val) => setNewAdmin(val)}
            mode="multiple"
            options={
              filtereddata[ind]?.admins?.length === 0
                ? Users
                : Users.filter((val) =>
                    filtereddata[ind].admins.some(
                      (val1) => val1.value !== val.value
                    )
                  )
            }
          />{" "}
          {newAdmin.length > 0 && (
            <Button
              type="primary"
              onClick={() => AddAdmins()}
              loading={loading}
              disabled={loading}
            >
              + Add
            </Button>
          )}
        </div>
        {/* {console.log(newAdmin,filtereddata[ind].admins)} */}
        <label className={styles.admins_label}>Admins</label>
        <hr style={{ width: "100%" }} />
        {filtereddata[ind] &&
          filtereddata[ind].admins.map((admin, ind) => {
            return (
              <div className="d-flex justify-content-between" key={ind}>
                <label>{admin.label}</label>
                <DeleteOutlined
                  onClick={() => RemoveAdmin(admin.value)}
                  className="mx-3"
                />
              </div>
            );
          })}
      </Modal>

      {/* Create Batches */}

      {contextHolder}
      <Modal
        open={create}
        onCancel={() => {
          setCreate(false);
          OnCancel();
        }}
        title="Create Batch"
        width={600}
        closable={false}
        okText="Submit"
        onOk={() => CreateGroupSubmit()}
        maskClosable={false}
        okButtonProps={{
          loading: loading,
          disabled: loading,
        }}
        cancelButtonProps={{
          loading: loading,
          disabled: loading,
        }}
      >
        <div className={styles.groupname_main}>
          <span className={styles.titles}>Batch Name</span>
          <input
            onChange={(e) => {
              setCreateGroup((val) => ({
                ...val,
                groupname: e.target.value,
              }));
            }}
            className={styles.groupname_input}
            type="text"
            required
            value={CreateGroup.groupname}
          />
        </div>
        <div className={styles.groupname_main}>
          <span className={styles.titles}>Group Email (Optional)</span>
          <input
            onChange={(e) => {
              setCreateGroup((val) => ({
                ...val,
                groupemail: e.target.value,
              }));
            }}
            className={styles.groupname_input}
            type="email"
            required
            value={CreateGroup.groupemail}
          />
        </div>
        <div className={styles.groupname_main}>
          <span className={styles.titles}>Course</span>
          <Select
            onChange={(e) => {
              setCreateGroup((val) => ({
                ...val,
                course: e,
              }));
            }}
            options={[
              "Frontend Development (MERN)",
              "Backend Development (MERN)",
              "Data Science",
              "Data Analytics",
            ].map((item, ind) => {
              return {
                label: item,
                value: item,
              };
            })}
            value={CreateGroup.course}
          ></Select>
        </div>

        <span className={`${styles.titles} mt-3`}>Members</span>
        <br />
        <label className={styles.notice}>
          *Excel file: <b>First column = FullName, Second column = Email</b>.
        </label>
        <div className="d-flex justify-content-between align-items-center">
          <Select
            mode="tags"
            style={{
              width: "90%",
              zIndex: "999",
            }}
            onChange={handlechange}
            tokenSeparators={[","]}
            options={Users}
            placeholder="Enter the email with ',' separated"
            value={Selecteduser}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
          <input
            type="file"
            id="fileInput"
            style={{ display: "none" }}
            onChange={handleFileUpload}
            className="pt-2"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          ></input>
          <label
            htmlFor="fileInput"
            style={{ cursor: "pointer" }}
            className={styles.uploadfile}
            title="Upload Excel"
          >
            <SiMicrosoftexcel />
          </label>
        </div>
        <div className="d-flex justify-content-between">
          <label>Total Excel Records: {Exceluser.length}</label>
          <label className="mx-5 px-5">
            Final Records: {[...finaldata, ...Selecteduser].length}
          </label>
        </div>
        <div className="d-flex flex-column">
          {alreadypresent.length > 0 && (
            <label>{alreadypresent.length} user(s) are already present.</label>
          )}

          {Exceluser.length - emptydata.length > 0 && (
            <label>
              {Exceluser.length - emptydata.length} document(s) have empty
              fields.
            </label>
          )}

          {emptydata.length - uniqueData.length > 0 && (
            <label>
              {emptydata.length - uniqueData.length} document(s) are duplicates.
            </label>
          )}

          {[...finaldata, ...Selecteduser].length > 0 && (
            <label>
              Do you want to add {[...finaldata, ...Selecteduser].length}{" "}
              user(s)?
            </label>
          )}
        </div>
      </Modal>

      {/* Add Member */}
      <Modal
        title="Add Members"
        open={addmember || assign}
        onCancel={() => OnCancel()}
        width={700}
        okText="Submit"
        closable={false}
        onOk={() => {
          if (addmember) {
            Submit();
          }
          if (assign) {
            AssignMentor_Submit();
          }
        }}
        maskClosable={false}
        okButtonProps={{
          loading: loading,
          disabled: loading,
        }}
        cancelButtonProps={{
          loading: loading,
          disabled: loading,
        }}
      >
        {addmember && (
          <>
            <label className={styles.notice}>
              *Excel file: <b>First column = FullName, Second column = Email</b>
              .
            </label>
            <div className="d-flex justify-content-between align-items-center">
              {" "}
              <Select
                mode="tags"
                style={{
                  width: "90%",
                  zIndex: "999",
                }}
                onChange={handlechange}
                tokenSeparators={[","]}
                options={Users}
                placeholder="Enter the email with ',' separated"
                value={Selecteduser}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0 ||
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <input
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                onChange={handleFileUpload}
                className="pt-2"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ></input>
              <label
                htmlFor="fileInput"
                style={{ cursor: "pointer" }}
                className={styles.uploadfile}
                title="Upload Excel"
              >
                <SiMicrosoftexcel />
              </label>
            </div>
            <div className="d-flex justify-content-between">
              <label>Total Excel Records: {Exceluser.length}</label>
              <label className="mx-5 px-5">
                Final Records: {[...finaldata, ...Selecteduser].length}
              </label>
            </div>
            <div className="d-flex flex-column">
              {alreadypresent.length > 0 && (
                <label>
                  {alreadypresent.length} user(s) are already present.
                </label>
              )}

              {Exceluser.length - emptydata.length > 0 && (
                <label>
                  {Exceluser.length - emptydata.length} document(s) have empty
                  fields.
                </label>
              )}

              {emptydata.length - uniqueData.length > 0 && (
                <label>
                  {emptydata.length - uniqueData.length} document(s) are
                  duplicates.
                </label>
              )}

              {Selecteduser.length > 0 && (
                <label>
                  Do you want to add {[...finaldata, ...Selecteduser].length}{" "}
                  user(s)?
                </label>
              )}
            </div>
          </>
        )}
        {assign && (
          <Select
            style={{
              width: "90%",
              zIndex: "999",
            }}
            onChange={handlechange}
            options={Users}
            placeholder="Select Mentor Name"
            value={Selecteduser}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
        )}
      </Modal>

      {/* Update Operation */}
      <Modal
        open={update}
        title={"Update"}
        onOk={() => UpdateOperation()}
        onCancel={() => {
          setUpdate(false);
          setUpdatedata("");
        }}
        okButtonProps={{
          loading: loading,
          disabled: loading,
        }}
        cancelButtonProps={{
          loading: loading,

          disabled: loading,
        }}
        maskClosable={false}
      >
        <div className={styles.groupname_main}>
          <span className={styles.titles}>Group Email</span>
          {filtereddata[ind]?.groupemail.length > 0 && (
            <>
              <label>Added groupids</label>
              <ol>
                {filtereddata[ind]?.groupemail.map((item, ind) => (
                  <li key={ind}>{item}</li>
                ))}
              </ol>
            </>
          )}

          <input
            onChange={(e) => {
              setUpdatedata((val) => ({
                ...val,
                groupemail: e.target.value,
              }));
            }}
            className={styles.groupname_input}
            type="email"
            required
            value={UpdateData?.groupemail}
          />
        </div>
      </Modal>
      <div className={styles.sliderBox}>
        <Sider
          className={styles.slider}
          breakpoint="lg"
          collapsedWidth="0"
          width={window.innerWidth < 350 ? 200 : "18%"}
          trigger={null}
        >
          <DashboardSlider />
        </Sider>

        <div className={styles.right}>
          <LatestNavbar openDwa={openDwa} setOpenDwa={setOpenDwa} />

          {loading ? (
            <div className="d-flex justify-content-center align-items-center h-50">
              <Spinner
                animation="border"
                size="lg"
                variant="warning"
                className="spinner"
              />
            </div>
          ) : (
            <>
              <div className={styles.searchbar}>
                <div className="d-flex align-items-center">
                  <Input
                    className={styles.search_input}
                    placeholder="Search by batch name or user name"
                    onChange={(val) => setSearchQuery(val.target.value)}
                  />
                </div>
                <div className="d-flex align-items-center flex-wrap">
                  <span className="mx-4 d-flex align-items-center ">
                    <input
                      type="checkbox"
                      id="my_groups"
                      style={{
                        cursor: "pointer",
                        width: "15px",
                        height: "15px",
                      }}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSearchQuery(user?.Email);
                        } else {
                          setSearchQuery("");
                        }
                      }}
                      className="mx-2"
                    />{" "}
                    <label style={{ cursor: "pointer" }} htmlFor="my_groups">
                      My Batches
                    </label>
                  </span>
                  <Button
                    icon={<UsergroupAddOutlined />}
                    onClick={() => {
                      setCreate(true);
                      FetchUsers();
                      setMenuKey("");
                    }}
                  >
                    Create New
                  </Button>
                </div>
              </div>
              <div className={styles.groupmain}>
                {filtereddata?.length > 0 &&
                  filtereddata
                    .slice((pagenumber - 1) * 8, pagenumber * 8)
                    .map((item, ind) => {
                      return (
                        <Card
                          className={styles.cardmain}
                          hoverable
                          styles={{ body: { padding: "0 10px 10px 10px" } }}
                          key={ind}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            navigate(`/group/${item.id}`, {
                              state: { groupname: item.GroupName },
                            });
                          }}
                          title={item.GroupName}
                          extra={
                            <Dropdown
                              menu={{
                                items,
                              }}
                              placement="bottomLeft"
                              arrow
                              trigger={["click"]}
                              className={styles.menu}
                              overlayStyle={{ width: "200px" }}
                            >
                              <span
                                style={{ fontSize: "20px", fontWeight: "600" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setInd(ind + (pagenumber - 1) * 8);
                                }}
                              >
                                <MoreOutlined />
                              </span>
                            </Dropdown>
                          }
                        >
                          <div className={styles.groupdetails}>
                            <div className={styles.leftlabels}>
                              <span>Members</span>
                              <span>Start Date</span>
                              <span>Current Phase</span>
                              <span>Assign To</span>
                            </div>
                            <div className={styles.rightdetails}>
                              <span>{item.Members.length}</span>
                              <span>
                                <Text
                                  className={styles.group_labels}
                                  ellipsis={{
                                    tooltip: item?.startdate
                                      ?.toString()
                                      .split("T")[0],
                                  }}
                                >
                                  {" "}
                                  {item?.startdate?.toString().split("T")[0] ??
                                    "Not Started"}
                                </Text>
                              </span>
                              <span>
                                <Text
                                  className={styles.group_labels}
                                  ellipsis={{ tooltip: item.currentphase }}
                                >
                                  {item.currentphase ?? "Not Started"}
                                </Text>
                              </span>
                              <span>
                                <Text
                                  className={styles.group_labels}
                                  ellipsis={{ tooltip: item.Mentor }}
                                >
                                  {item.Mentor ?? "Not Assigned"}
                                </Text>
                              </span>
                            </div>
                          </div>
                        </Card>
                      );
                    })}
              </div>
            </>
          )}
          {!loading && filtereddata.length === 0 && (
            <Result
              title="Sorry , You don't have any batch."
              className="mt-5"
              extra={
                <Button
                  type="primary"
                  key="console"
                  onClick={() => {
                    setCreate(true);
                    FetchUsers();
                  }}
                >
                  Create Batch
                </Button>
              }
            />
          )}
          <center>
            <Pagination
              size="small"
              onChange={(val) => setPagenumber(val)}
              current={pagenumber}
              total={filtereddata?.length}
              pageSize={8}
            />
          </center>
        </div>
      </div>
      <ProfileSlider setOpenDwa={setOpenDwa} openDwa={openDwa} />
    </div>
  );
};

export default ManageGroups;
