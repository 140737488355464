import React from 'react'
import styles from "./warning.module.css"
import {IoIosTimer} from "react-icons/io"
import {useSelector} from "react-redux"
import { useNavigate } from 'react-router-dom'
const IsMockStarted = ({Mockdate}) => {
      const data=useSelector(state=>state.Data)
      const navigate=useNavigate()
      const { user } = data;
  return (
    <div className='d-flex justify-content-center'>
<div className={styles.warning_main}>
 <center><h2 className={styles.heading}>Upcoming Test</h2>
<label className={styles.greeting}> Hello {user?.FullName}</label><br/>
 <IoIosTimer className={styles.doneicon}/><br/>

 <label className={styles.greeting}>Your Mock or Test will start at {`${new Date(Mockdate).getDate()}-${new Date(Mockdate).getMonth()+1}-${new Date(Mockdate).getFullYear()}  ${new Date(Mockdate).getHours()}:${new Date(Mockdate).getMinutes()}`}.</label><br/>
 <label style={{color:"blue",cursor:"pointer"}} onClick={()=>navigate("/user")}>Home</label>
 </center>
</div>
    </div>
  )
}

export default IsMockStarted