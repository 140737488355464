
import axios from "axios";


export async  function GetMySessions(accessToken) {

    try{
if (!accessToken) {
        // Handle error or return a default value
        return null;
    }
    // const today = new Date().toISOString();
    // const startDate = `${today.split("T")[0]}T00:00:00`;
    // const endDate = `${today.split("T")[0]}T23:59:59`;
  
    // Use the access token to fetch the photo or perform other tasks
    const response = await axios.get(
        'https://graph.microsoft.com/v1.0/me/events', {
            headers: {
                Authorization: `Bearer ${accessToken}`
            },
            params: {
                $select: 'subject,organizer,attendees,start,end,onlineMeeting',
                // startDateTime: now.toISOString(),
                // endDateTime: tenDaysFromNow.toISOString(),
                // $orderby: 'start/dateTime',
            }
        }
    );
    
if(response.status===200){
    return SessionHelper(response?.data?.value)
}

        
    }
    
 
    catch(err){
        return err
    }


}


// function GetRecordings(id){
//        const decodedurl= decodeURIComponent(id);
   
//        const url = new URL(decodedurl);
//        const contextParam = JSON.parse(url.searchParams.get('context'));
// const Oid=contextParam.Oid
// const MeetingId=decodedurl.split("/")[5]

// const generatedurl=`1*${Oid}*0**${MeetingId}`

// return window.btoa(generatedurl)
// }

const SessionDuration=(start,end)=>{
    let startNew=new Date(start)
    let endNew=new Date(end)
    let timeDiff = Math.abs(startNew - endNew);

    // Calculate hours and minutes
    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    timeDiff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(timeDiff / (1000 * 60));

    if (hours > 0 && minutes > 0) {
        return hours + " hours " + minutes + "min";
    } else if (minutes > 0) {
        return minutes + " mins";
    } else {
        return hours + " hours";
    }
}


const TimeToLocalZone=(val)=>{

// Create a Date object from the UTC date string
const utcDate = new Date(val);
utcDate.setHours(utcDate.getHours() + 5); 
utcDate.setMinutes(utcDate.getMinutes() + 30); 

const istDateStr = new Date(utcDate.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));

return istDateStr
}

function SessionHelper(data){
    let newSessionsData=[]
    if(data &&data.length>0){
         newSessionsData = data.map(item => {
           
            return {
                subject: item.subject,
                start: TimeToLocalZone(item.start?.dateTime).toISOString(),
                end: TimeToLocalZone(item.end?.dateTime).toISOString(),
                Duration: SessionDuration(item.start?.dateTime, item.end?.dateTime),
                join_link: item.onlineMeeting?.joinUrl,
                status: false,
                recording:false
                // recording: item.organizer.emailAddress.address.toLowerCase()===process.env.REACT_APP_OPERATIONS_ID
                // id:GetRecordings(item.onlineMeeting?.joinUrl)
            };
        })
    }




const currentDate = new Date(); 

const upcomingSessions = newSessionsData.filter(item => { 
const sessionStartDate = new Date(item.end);
    return sessionStartDate > currentDate; 
}).sort((a, b) => {
    const dateA = new Date(a.start);
    const dateB = new Date(b.start); 
    return dateA - dateB;
});

const expiredSessions = newSessionsData.filter(item => {
const sessionEndDate = new Date(item.end);

    return sessionEndDate <= currentDate &&item.subject.split(":")[0]!=="Canceled"; 
}).sort((a, b) => {
    const dateA = new Date(a.start);
    const dateB = new Date(b.start); 
    return dateA - dateB;
}).reverse()



return {upcomingSessions,expiredSessions}
}
